/*
 * @Author: your name
 * @Date: 2020-01-06 17:12:54
 * @LastEditTime : 2020-01-06 17:28:17
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\overview\projectConstruction\ProjectConstruction.js
 */
import '../../../global.js';
import React from 'react';
import './project-construction.less';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Show from '../components/show/Show'

class ProjectConstruction extends React.Component {

    handleClick(address) {
        this.props.history.push('/' + address);
    }


    render() {
        return (
            <div>
                <Head />
                <div className="project-construction">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"工程总承包企业项目管理信息化解决方案"} link={this.handleClick.bind(this, "epc")}
                                        img={global.config.url + "/newgrand/picture/img/new-cases/19/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"施工总承包企业信息化解决方案"} link={this.handleClick.bind(this, "constructionContract")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E5%BB%BA%E9%80%A0/%E6%96%BD%E5%B7%A5%E6%80%BB%E6%89%BF%E5%8C%85%E4%BC%81%E4%B8%9A%E4%BF%A1%E6%81%AF%E5%8C%96/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"专业承包企业信息化解决方案"} link={this.handleClick.bind(this, "professionalContract")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E5%BB%BA%E9%80%A0/%E4%B8%93%E4%B8%9A%E6%89%BF%E5%8C%85%E4%BC%81%E4%B8%9A%E4%BF%A1%E6%81%AF%E5%8C%96/banner.png"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default ProjectConstruction;