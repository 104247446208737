import React from "react";
import RenderWithTitle from "@/high-orders/RenderWithTitle";
import ImgTitleItem from "@/new-components/ImgTitleItem";
import './index.less';

function ImgTitlePanel(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [];

  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <ImgTitleItem 
        className="item" 
        key={currItemConfig.key} 
        title={currItemConfig.title}
        describe={currItemConfig.describe} 
        iconStyle={{
          backgroundImage: config.default.backgroundImage,
          backgroundPosition: `calc(${-15 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-15 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
        }} />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="img-title-panel"> {inner}</div>
}

export default RenderWithTitle(ImgTitlePanel, '方案价值');