/*
 * @Author: your name
 * @Date: 2019-12-16 10:00:54
 * @LastEditTime : 2020-01-09 09:26:33
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\partner\system-introduction\SystemIntroduction.js
 */
import React from 'react';
//import './index.css';
import './system-introduction.less';
import $ from 'jquery';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-img"> </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"体系介绍"} />
                <div className="second-page-card">
                    <div className="second-page-card-img"></div>
                    <div className="second-page-card-textcard">
                        <div className="second-page-card-textcard-text">
                            新中大一直以来致力于为企业单位提供专业的项目管理、财务软件及ERP系统应用解决方案，为建筑企业提供专业的项目管理、财税管理、数字工地等解决方案，公司以优秀的理念与产品为基石，构建了合作伙伴战略体系。新中大秉承“客户为中心、伙伴为主体”的经营模式，与合作伙伴一起，共同营造创新、共赢的市场商机。
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
//网页主模块
class SystemIntroduction extends React.Component {
    //----从此处开始
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this)
    }
    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }
    //获取相关id的高度
    getHight(id, showposition, classname) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }
    //初始化添加动效的类名
    initclass(classname, addclassname) {
        let classlist = $("." + classname);
        for (let i = 0; i < classlist.length; i++) {
            $(classlist[i]).addClass(addclassname);
        }
    }
    //监控所有指定class的目标
    getClassHight(myclass, showposition, classname) {
        let classlist = $("." + myclass);
        for (let i = 0; i < classlist.length; i++) {
            let height = $(classlist[i]).offset().top - $(window).scrollTop() - $(window).height();
            if (height < showposition) {
                $(classlist[i]).addClass(classname);
            }
        }
    }
    //触发本组件的动画
    getOtherHight(otherid, id, showposition, classname) {
        let height = $("#" + otherid).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }

    initAnimation() {
        this.initclass("second-page-card-img", "moveright100-center-init");
        this.initclass("second-page-card-textcard", "moveleft100-center-init");

    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        this.getClassHight("second-page-card-img", -300, "moveright100-center-notime");
        this.getClassHight("second-page-card-textcard", -300, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="system-introduction">
                    <FirstPage />
                    <SecondPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default SystemIntroduction;