/*
 * @Author: your name
 * @Date: 2019-12-16 10:19:54
 * @LastEditTime : 2020-01-09 13:51:59
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\partner\join-apply\JoinApply.js
 */
import React from 'react';
//import './index.css';
import './join-apply.less';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-img"> </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <div className="second-page-left">
                    <div className="second-page-text">渠道支持申请流程:提交申请—>信息审核—>培训及认证—>签署合作协议/发放资质证书<br />
                        申请加盟：请您下载填写加盟信息，以便我们能及时与您联系！</div>
                    <div className="second-page-card">
                        <div className="second-page-card-icon"></div>
                        <div className="second-page-card-title">联系方式</div>
                    </div>
                    <div className="second-page-information">
                        地 址：杭州市祥园路88号中国（杭州）智慧信息产业园N座9F(310011)<br />
                        电子邮件：place@newgrand.cn<br />
                        电 话：86-0571-88270588<br />
                        传 真：86-0571-88270566
                    </div>
                    <div className="interval-270"></div>
                </div>
                <div className="second-page-right">
                    <a rel="noopener noreferrer" href="http://proa53c6d15.pic17.websiteonline.cn/upload/jmsqb.docx" target="_blank">
                        <div className="second-page-img"></div>
                    </a>
                </div>
            </div>
        );
    }
}
//网页主模块
class JoinApply extends React.Component {
    render() {
        return (
            <div>
                <Head />
                <div className="join-apply">
                    <FirstPage />
                    <SecondPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default JoinApply;