/*
 * @Author: your name
 * @Date: 2019-12-17 10:56:01
 * @LastEditTime : 2019-12-24 17:07:24
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\projectInvestment\components\card-a\CardA.js
 */
import React from 'react';
//import './index.css';
import './card-a.less'


/**
 * @author chenyutan
 * @date 2019-12-06
 * @class Card
 * @description 卡片组件 第一行为一张图片 第二行为粗体字一行 第三行为非粗体字一行 
 *              需要提供参数为 <图片地址><第二行字><第三行字>
 */
class CardA extends React.Component {
    render() {
        return (
            <div className="project-investment">
                <div className="card-a">
                    <div className="interval-37"></div>
                    <div className="card-picture-a">
                        <img alt="" src={this.props.img}></img>
                    </div>
                    <div className="card-first-text-a">{this.props.firstText}</div>
                    <div className="card-second-text-a">{this.props.secondText}</div>
                </div>
            </div>
        );
    }
}

export default CardA;