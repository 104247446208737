import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/15/`;
export default {
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "建筑企业项目施工成本中材料成本管理至关重要，而控制材料成本除了采购环节，另外一个重要环节在现场物料管理，工地每天都有大量的钢筋混凝土物料运输车辆进出，现场人工手动记录登记，不仅耗时，而且误差较率大，还容易滋生人为的舞弊行为，使得材料在入场时就存在虚耗的现象。新中大现场物料管理解决方案根据不同的应用场景分为房建版、市政路桥版、离线版等版本，主要采用智能地磅收发料控制，从集团企业级角度解决现场物料监管难及虚耗成本大的问题。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "现场物料管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '地磅集成'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '视频监控'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '车牌识别'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '红外对射'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '车辆进出'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '皮重监测'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        describe: '原始单据'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 0 },
        describe: '多种场景'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 4,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        title: '软硬结合，现场验收环节全方位管控',
        describe: '智能地磅系统通过软硬件结合、借助互联网手段实现物料现场验收环节全方位管控，规避材料进场就亏、不了解废料处理情况、称重环节作弊等现象的发生。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        title: '无人值守，自动车牌识别称重取数',
        describe: '车牌自动识别录入系统，与地磅称重仪表集成，避免人为干预。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        title: '离线应用',
        describe: '避免现场网络不稳定导致的使用问题。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        title: '收发料全监管，满足市政公路搅拌站业务',
        describe: '提供房建收料，搅拌站原材料收料，成品发料、沥青摊铺机组收货等多种行业不同业务场景的解决方案。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        title: '预警提醒、偏差分析',
        describe: '系统提供预警监测材料是否超计划、超合同过磅收料，偏差情况分析报表根据物料类别统计的负差超限和正差超限情况、以及根据供应商统计的负差超限和正差超限情况。'
      }
    ]
  },
  sessionE: {
    title: '内蒙古兴泰建设集团有限公司',
    describe: '内蒙古兴泰建设集团有限公司，是内蒙古自治区拥有建筑工程施工总承包特级资质及建筑行业甲级设计资质，跨地区多元化经营的综合性建设集团。可承接公路、铁路、市政公用、港口与航道、水利水电各类别工程的施工总承包、工程总承包和项目管理业务。公司在深耕自治区内业务的同时，市场区域已经延伸至全国十多个省、市、自治区，雄安、华中、华东、华北、东南、西南、西北、东北等各区域公司相继成立、快速发展。旗下有24家分（子）公司，业务范围包括建筑施工、设计、机电安装、桥梁隧道、轨道交通、铁路工程、高速公路、市政道路、消防工程、电子与智能化工程、通信工程、装饰装修、钢结构、金属门窗、建筑幕墙、水利水电、商砼、检测、园林绿化、劳务派遣、装配式建筑、BIM技术服务、职业教育等。',
    backgroundImage: `url(${baseUrl}anli.png)`
  },
  sessionF: {
    src: global.config.url + '/newgrand/picture/img/数字工地/可视化工地管理/解决方案-智慧物资/新中大地磅宣传片成片20190625.mp4',
  },
}