/**
 * 全名： 专业承包企业管理
 * 功能描述： 财税共享
 * 创建日期：--
 * 作者：hzw
 */

import React from "react";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import ContactBottom from '../../../new-components/ContactBottom';
import ArchitectureImage from '../privateComponents/ArchitectureImage';
import PointPanel from "../privateComponents/FinancePointPanel";
import ValuePanel from '../privateComponents/FinanceValuePanel';
import RealCase from '../privateComponents/RealCase';
import RenderWithTitle from '../../../high-orders/RenderWithTitle';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn';


import config from "./config"
import './index.less';

export default class extends React.Component {
  render() {
    const RealCaseWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(RealCase, '更多案例 >', {}, () => {
      this.props.history.push('/caseof23/项目建造/业务领域');
    }), '用户案例')
    return (
      <div className="professional-contract">
        <Head />
        <Abstract {...config.sessionA} />
        <PointPanel {...config.sessionB} />
        <ArchitectureImage  {...config.sessionC} />
        <ValuePanel  {...config.sessionD} />
        {/* <RealCaseWithTitleAndMoreBtn {...config.sessionE} /> */}
        <ContactBottom />
        <Bottom />
      </div>
    )
  }
}