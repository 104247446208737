import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/7/`;
export default {
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "新中大厂房新建搬迁管理解决方案充分考虑新建厂房与技术改造工程项目要实现网络化信息化管理。方案深入项目管理的各个范畴，包括计划进度管理、合同管理、设备资源管理、物流管理、安全管理、质量管理、综合查询领导决策、项目文档管理、协同办公、成本管理、工程业务管理等，为公司领导决策层、经营管理层、项目管理层提供科学的决策依据。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "厂房新建(搬迁)管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '源头控制项目立项，规范项目决策'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '项目投资全过程管控'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '业务协同，多方参与'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '实时归档，资料留痕'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '规范项目验收、竣工管理'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 5, y: 0 },
        // title: '互联互通，共享共治',
        describe: '实现项目的整体投资过程管理，控制项目投资过程的风险；实现企业层与项目层之间、各部门之间、外部协作单位之间无法建立有效的沟通；将项目管理层、决策层置身于信息化的应用之中，实现信息化的全员参与。'
      },
      {
        key: 2,
        iconPosition: { x: 6, y: 0 },
        // title: '制度流程化，流程信息化',
        describe: '实现项目的预警机制，预测企业与项目的潜在风险；实现企业的标准化建设。规范化管理与项目的实施整合。'
      },
      {
        key: 3,
        iconPosition: { x: 7, y: 0 },
        // title: '随时随地，移动办公',
        describe: '加强总部对项目部职能线精细化管理，消除企业内容信息孤岛现象。'
      },
      {
        key: 4,
        iconPosition: { x: 8, y: 0 },
        // title: '知识管理，创造价值',
        describe: '规范成本管理，科学、精确、实时进行项目的成本预测；解决成本数据汇总的数据量庞大，传统手工计算的问题；及时掌握、控制其下属项目部的成本消耗情况。'
      }
    ]
  },
  sessionE: {
    title: '青岛碱业发展有限公司',
    describe: '青岛碱业发展有限公司是以生产经营纯碱、化肥、热电及相关精细化工产品为主的大型综合性化工企业，公司位于青岛市北部胶州湾东岸，凭借着便捷交通地理、工业科技实力以及丰富人力资源等优越环境条件，与国内外建立了广泛的经贸关系。公司现有1个分公司和8个子公司。公司总资产29亿元；年产纯碱80万吨，化肥50万吨，氯化钙15万吨，自发电5.8亿kWh,蒸汽产品700万吨，是山东省、青岛市首批信誉等级AAA企业，被授予"全国AAA级信用企业"。',
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}