import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/i6/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E4%BA%A7%E5%93%81/i6s/banner.png`,
    describe: "新中大i6企业管理软件是是面向先进制造与现代服务型品牌企业为核心的联盟体或大型企业集团，以联盟体资源计划（URP）理论和项目管理的思想为基础，解决以价值链为驱动的联盟体资源优化配置的电子商务需求的软件产品，产品涉及联盟体核心企业的集团管控、供应链管理、生产管理、制造执行、客户关系管理、合同管理、项目管理、成本管理、质量管理、企业绩效管理、人力资源管理、协同工作管理、知识库管理和商业智能等功能模块，致力打造互联网时代品牌企业基于产业联盟的管理平台。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "i6企业管理软件",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 4,
    rowCount: 1,
    default: {
      backgroundImage: `url(${baseUrl}i6.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 0 },
        describe: '支撑企业发展战略的集团管控平台'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 0 },
        describe: '满足大型项目制造企业管理需求'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 0 },
        describe: '以合同管理为核心的全程项目管控平台'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 0 },
        describe: '基于柔性化统一平台的异构系统无缝集成'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}i6.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '柔性化统一平台',
        describe: '新中大柔性化统一平台SUP以项目管理为核心，基于企业服务总线ESB的大型企业管理信息化系统建设和管理软件开发为目的，提供可配置、可重构的基础应用中间件平台与关键支撑的工具集。应用于信息化项目实施团队快速、低成本、高可靠地开发和部署项目服务型企业信息化管理系统。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '协同工作平台',
        describe: '协同工作平台是一套基于联盟体资源计划URP管理理念的工作事务处理系统，它主要建立起整个企业协同运作的工作平台，帮助企业建立一种有效的日常工作处理模式。各个业务功能点作为工作台上的一个插件，可由系统应用人员自行调整，并在统一的协同工作平台上实现跨组织和跨业务的文档、资源、项目、工作流的业务处理。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '人力资源管理',
        describe: '人力资源管理系统，以员工能力素质模型为基础，重新构建企业与员工的工作关系，推动企业战略目标与员工个人职业目标的实现，为经济资源联盟体提供的人力资源解决方案。系统致力于提高企业内人力资源的共享和配置优化，帮助企业提高人力资源竞争力。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '企业管控中心',
        describe: '企业管控中心是针对品牌企业建立基于效益管理、价值管理、战略成本管理、电子商务的商务运作模式，实时监控联盟体计划、预算的执行情况，对联盟体的运营情况进行分析，并提供相应的结果报告，为企业管理决策提供数据支撑。企管中心还能够给予web的报告生成警报，便于企业做出重要的决策。'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '集团财务管理',
        describe: '集团财务管理系统是基于URP管理理念的、符合中西方会计准则的联盟体财务管理信息系统，系统帮助经济资源联盟体建立以预算管理为核心的集团财务控制体系。系统应用分为三层：基础核心层包括总账、现金中心、客户中心、供应商中心、报表中心、合同管理等系统，构成企业会计核算基础；专项业务管理层包括成本管理、固定资产管理、结算中心，企业可根据实际业务需要选择以建立企业财务管理与控制体系；决策管理层包括预算管理、经营分析、集团管理等系统，满足企业高层财务管理的需要。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '客户关系管理',
        describe: '客户关系管理系统是一种旨在改善企业与客户之间关系的新型互动管理平台，客户关系管理套件旨在选择和管理有价值客户及其关系，通过以客户为中心的企业文化来支持有效的市场营销、销售与服务流程。'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 1 },
        title: '供应链管理',
        describe: '供应链管理系统是以供应链管理为主线，对企业的订单接收、采购、库存管理、生产、分销、销售等环节进行控制和管理，最终实现企业与供应商、分销商、终端客户的协同。'
      },
      {
        key: 8,
        iconPosition: { x: 7, y: 1 },
        title: '企业合同管理',
        describe: '合同管理提供了对企业各种类型合同进行有效管理的功能，涉及合同要素、合同附件、合同付款计划、合同结算明细、合同变更信息、合同执行情况等管理要素。'
      },
      {
        key: 9,
        iconPosition: { x: 8, y: 1 },
        title: '生产管理​​​',
        describe: '生产管理系统PM是i6软件针对全球化制造特点，根据现代生产管理理论和方法，在多年调研和案例实践的基础上开发的系统模块。PM系统不仅支持传统制造业企业生产模式，而且支持现代制造业企业新型生产模式，包括大规模定制、协同生产、项目型制造/专项生产等模式。'
      },
      {
        key: 10,
        iconPosition: { x: 9, y: 1 },
        title: '项目管理​​',
        describe: '项目管理主要履行项目的基础事务管理，侧重点在于实现项目的工作结构分解与进度管理，为项目进度管理提供了支持工具。项目管理提供从进度计划制定开始，一直到进度完成结束时的全过程管理。'
      },
      {
        key: 11,
        iconPosition: { x: 0, y: 2 },
        title: '成本管理​​​',
        describe: '成本管理是遵循企业成本控制的业务流程，参考管理会计思想而设计研发的帮助企业实时掌握产品、服务等成本中心的动态成本管理模块。用户可以定义产品、服务二种不同的成本对象和相关的成本项目，在计算机内按费用中心记录各项费用的台账，并及时将有关凭证转到总账系统。成本管理可实现标准成本和作业成本的管理和分析。'
      },
      {
        key: 12,
        iconPosition: { x: 1, y: 2 },
        title: '技术管理',
        describe: '技术管理是URP系统中的应用套件，系统以实现“客户化定制”为目标，依据“大规模定制”和“参数化配置模型”构建智能化应用套件。技术管理的主要服务对象是销售人员在“非标产品”的销售过程中，可以快速销售询单、产品报价和快速应标。同时将个性化的销售订单通过系统参数模型进行快速分解，为生产计划人员提供较为准确的材料消耗清单。'
      },
      {
        key: 13,
        iconPosition: { x: 0, y: 2 },
        title: '项目管控中心',
        describe: '项目监管人员可通过项目管控中心，快速获取项目的材料物资、人力资源(项目)、进度风险、预警事件、合同、监理情况、项目整改等信息。'
      },
      {
        key: 14,
        iconPosition: { x: 1, y: 2 },
        title: '制造执行系统',
        describe: '系统是为车间主任、生产计划调度、统计会计员、班组长、操作工配置专门的生产管理工作平台。通过系统使生产操作管人员可以获得计划详细排程和派工的工具，及时准确掌握计划延期和调整情况，及时准确掌握人、机、料、法、环五个方面的异常情况。'
      },
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history: '/caseDetails/1139'
      },
      {
        logoSrc: `${baseUrl}b.png`,
        history: '/caseDetails/1140'
      },
      {
        logoSrc: `${baseUrl}c.png`,
        history: '/caseDetails/1141'
      }
    ]
  }
}