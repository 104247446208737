/*
 * @Author: your name
 * @Date: 2020-01-06 16:31:00
 * @LastEditTime : 2020-01-06 16:49:47
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\overview\digitalSite\DigitalSite.js
 */
import '../../../global.js';
import React from 'react';
import './digital-site.less';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Show from '../components/show/Show'

class DigitalSite extends React.Component {

    handleClick(address) {
        this.props.history.push('/' + address);
    }


    render() {
        return (
            <div>
                <Head />
                <div className="digital-site">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"企业级劳务实名制管理"} link={this.handleClick.bind(this, "realNameLaborManager")}
                                        img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E4%BC%81%E4%B8%9A%E7%BA%A7%E5%8A%B3%E5%8A%A1%E5%AE%9E%E5%90%8D%E5%88%B6%E7%94%A8%E5%B7%A5%E7%AE%A1%E7%90%86/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"现场机械管理"} link={this.handleClick.bind(this, "machineryManagement")}
                                        img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%9C%BA%E6%A2%B0%E7%AE%A1%E7%90%86/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"现场物料管理"} link={this.handleClick.bind(this, "materialManagement")}
                                        img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E7%89%A9%E8%B5%84%E7%AE%A1%E7%90%86/banner.png"} />
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"现场管理"} link={this.handleClick.bind(this, "onsiteIns")}
                                        img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%AE%89%E5%85%A8%E6%95%99%E8%82%B2/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"标化工地"} link={this.handleClick.bind(this, "visualChemicalSiteManagement")}
                                        img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%8F%AF%E8%A7%86%E5%8C%96%E5%B7%A5%E5%9C%B0%E7%AE%A1%E7%90%86/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"工地芯"} link={this.handleClick.bind(this, "siteCore")}
                                        img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%B7%A5%E5%9C%B0%E8%8A%AF/banner.png"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default DigitalSite;