/*
 * @Author: your name
 * @Date: 2019-12-12 09:40:26
 * @LastEditTime : 2020-01-09 13:13:39
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\machinery-management\MachineryManagement.js
 */

import React from 'react';
//import './index.css';
import '../../../global';
import './cost-control.less';
import ani from '../../../transition/animation/animation';
//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../../digitalSite/components/TitleSize40Center'
import Describe from '../../digitalSite/components/Describe'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"成本管理解决方案"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案介绍"} />
                <Describe text={"项目成本管理套件（PCO）根据事先制订的成本目标，围绕工程项目管理过程中与成本有关的各个环节，进行成本的预算、计划、变更控制、核算、决算及统计分析，将各项成本控制在成本目标和预算之内。<br/>"+
                                "成本管理功能贯穿工程项目成本控制的全过程，系统根据项目管理的特点以及工程专业要求，灵活设置成本控制维度、方式、阶段与成本科目；在工程建设过程中，可以编制总体成本计划和阶段成本计划，对成本发生和成本变更进行严格控制、核算、决算以及跟踪监控，并对成本版本进行对比选择。<br/>"+
                                "新中大成本预控解决方案支持BIM模型及预算软件数据导入功能，将模型算量数据直接引入到项目管理系统，形成成本预控数据，项目实际业务发生过程中的材料采购、分包合同签订、劳务分包合同签订、管理费用发生等均处于受控状态，如果费用超预算，系统会自动预警并加以控制，业务办理人无法超预算操作，必须经过严格变更申请方可调整，保证成本过程受控。"} />
                <div className="interval-50"></div>
                <div className="second-box">
                    <div className="box-img">
                        <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/2-1.png"}></img>
                    </div>
                    <div className="interval-col-30"></div>
                    <div className="box-img">
                        <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/2-2.png"}></img>
                    </div>
                </div>
                <div className="interval-50"></div>
                <Title text={"客户需求"} />
                <Describe text="实现成本的预测、控制、核算和分析。建立成本管理体系，并通过系统逐步积累和完善公司的企业定额，完成公司指标成本的编制和项目各个时期工程量的计算，完成项目预算成本的编制、实现项目支出成本的动态监控。实现合同预算成本、责任成本、计划成本、实际成本的多算对比分析。具有对项目成本运行情况进行监控的管理功能，同时具有对项目预算成本（投标成本或目标成本）和实际成本进行对比分析的统计报表功能。具有项目投标成本、目标成本、实际成本进行报表的对比分析的功能，实现三算对比。具有按照项目生产周期（月度或季度）对项目进行项目成本统计分析和对比的功能。反映投标成本、目标责任成本、材料实际成本、机械实际成本、劳动力实际成本、分包实际成本、其他成本归计、成本分析、成本考核、成本数据库等内容。" />
                <div className="interval-154"></div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"方案价值"} />
                <Describe text={"实现业务成本与财务成本对接，达到一体化管控目标。<br/>"+
                                "实现了成本管理与各核心业务模块间见的集成，如物资管理、设备管理、人工管理、进度管理、合同管理、预警管理等。<br/>"+
                                "通过各项目成本预算、核算的规范化，实现整个企业多项目成本分析，全盘了解企业各项目目标利润变化、阶段成本差异变化等指标，实时了解和控制项目成本风险。通过企业级成本分析，了解到了企业可能存在成本失控的项目，通过项目成本分析，可以将成本超支情况聚焦到具体分部分项和费用类型，进而更好的进行调整和控制，实现项目经营降本增效。"} />
                <div className="interval-154" />
            </div>
        );
    }
}


//网页主模块
class CostControl extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("fifth-page-card", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");

        ani.initclass("sixth-page-card", "moveright100-center-init")
        ani.initclass("sixth-page-card-textcard", "moveleft100-center-init")
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("fifth-page-card", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");

        ani.getClassHight("sixth-page-card", 20, "moveright100-center-notime");
        ani.getClassHight("sixth-page-card-textcard", 20, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="cost-control">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default CostControl;