/*
 * @Author: your name
 * @Date: 2019-12-12 09:40:26
 * @LastEditTime : 2020-01-09 13:13:39
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\machinery-management\MachineryManagement.js
 */

import React from 'react';
import '../../../global';
//import './index.css';
import './safety-ring.less';
import ani from '../../../transition/animation/animation';
//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../../digitalSite/components/TitleSize40Center'
import Describe from '../../digitalSite/components/Describe'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"安质环管理解决方案"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案介绍"} />
                <Describe text={"新中大安全/质量/环境管理解决方案这个系统是为贯彻行业ISO9000标准开发而成，同时，企业基于自身的实际安质环实施情况，可以制定适合企业发展的量化标准，通过过程不断改进与创新，实现高质量，高安全，优环境的生产。<br/>"+
                                "质量管理包括质量目标、质量策划、质量检查、质量事故管理四个方面，实现对质量管理活动进行全过程的管理。安全管理和环境管理系统也完全采用PDCA闭环管理，通过与项目进度的关联，主动实现对安全事务的危险源识别、安全培训、安全考核以及安全事故的全过程闭环管理。"} />
                <div className="interval-50"></div>
                <Title text={"客户需求"} />
                <Describe text="安全/环境/质量是每一个施工企业都非常关注的问题，保证施工安全，保证质量第一，保证施工环境优良是施工企业在建筑施工中的重要任务。然而对应的管理难度大，并且要做到预先控制，提前消除存在的一切安全隐患，引进先进的管理系统非常必要。信息化解决方案应根据建筑施工企业的特点来实现施工企业对质量、安全、环境的管理。" />
                <div className="interval-40"></div>
                <div className="second-box">
                    <div className="box-card">
                        <div className="box-img">
                            <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/1-1.png"}></img>
                        </div>
                        <div className="box-text">安全解决方案流程图</div>
                    </div>
                    <div className="interval-col-30"></div>
                    <div className="box-card">
                        <div className="box-img">
                            <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/1-2.png"}></img>
                        </div>
                        <div className="box-text">质量解决方案流程图</div>
                    </div>
                </div>
                <div className="interval-50"></div>
                <div className="second-box">
                    <div className="box-card">
                        <div className="box-img">
                            <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/1-3.png"}></img>
                        </div>
                        <div className="box-text">环境解决方案流程图</div>
                    </div>
                    <div className="interval-col-30"></div>
                    <div className="box-card">
                        <div className="box-img">
                            <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/1-4.png"}></img>
                        </div>
                        <div className="box-text">移动端应用</div>
                    </div>
                </div>
                <div className="interval-154"></div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"方案价值"} />
                <Describe text={"通过对安全质量环境的过程管理，实现企业高质量，高安全的目标，通过对目标与指标的管控、应急预案的报备、演习以及评价，包括安全培训，应急预案（控制方案制定与执行）等。实现动态管理，满足预警要求。通过建立安全知识库建立，提升安全管理的精细化水平，通过知识库的积累和完善，提升企业安全的安全管理能力。"} />
                <div className="interval-154" />
            </div>
        );
    }
}


//网页主模块
class SafetyRing extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("fifth-page-card", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");

        ani.initclass("sixth-page-card", "moveright100-center-init")
        ani.initclass("sixth-page-card-textcard", "moveleft100-center-init")
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("fifth-page-card", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");

        ani.getClassHight("sixth-page-card", 20, "moveright100-center-notime");
        ani.getClassHight("sixth-page-card-textcard", 20, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="safety-ring">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default SafetyRing;