/*
 * @Author: your name
 * @Date: 2019-12-11 15:53:27
 * @LastEditTime : 2020-01-09 11:54:07
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\safety-education\SafetyEducation.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './safety-education.less';
import ani from '../../../transition/animation/animation';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../components/TitleSize40Center'
import ButtonYellow from '../components/ButtonYellow'
import Describe from '../components/Describe'
import Card from '../components/CardInEducation'
import LongList from '../components/LongList'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"安全教育"} />
                                    <div className="interval-44"></div>
                                    <ButtonYellow text={"“互联网+VR”智能安全体验"} />
                                </div>
                            </div>
                            <div className="interval-33"></div>
                            
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"市场需求"} />
                <Describe text={"据统计分析，全国建筑业伤亡事故80％以上都是违章指挥、违章作业、违反劳动纪律造成的。加强安全教育培训，提高建筑施工队伍的安全素质；杜绝“三违”现象，减少伤亡事故发生，是建筑施工管理的关键措施。<br/>安全培训体验馆是提升员工安全培训与教育的有效手段，目的就是让传统的安全教育模式和体验式安全培训相结合，让施工参与者从心灵深处感受到安全生产的重要性，通过体验安全培训增强了员工安全意识，减少施工生产意外事故的发生。"} />
                <div className="interval-100"></div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"方案概述"} />
                <div className="third-page-img">
                    <div className="third-page-box">
                        <div className="third-page-text">
                            新中大借助于建筑施工行业信息化系统20多年的经验，根据建筑施工行业的安全生产和作业方式，通过第三方VR产品以及培训课件与新中大i6P工程信息化平台的融合，提出一套行之有效的整体解决方案，创新性地和战略伙伴开发了“互联网+VR”智能安全体验馆，是传统安全体验馆的更新换代产品。VR智能安全体验馆，通过虚拟现实技术(VR）及互联网IT技术在安全教育及训练中的应用，从而全面提高一线工人的安全意识和自我防范意识，促进企业安全管理。
                        </div>
                    </div>
                </div>
                <div className="interval-126"></div>
            </div>
        );
    }
}

//网页第四个模块
class FourthPage extends React.Component {
    render() {
        return (
            <div className="fourth-page">
                <div className="fourth-page-col">
                    <div className="interval-col-123"></div>
                    <LongList img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%AE%89%E5%85%A8%E6%95%99%E8%82%B2/image-1.png"}
                        title={"标准版<br/>适用于房建等工程建设"} text={"支撑高处坠落VR虚拟体验<br/>临边高处坠落VR虚拟体验<br/>机械伤害VR虚拟体验(挖掘机)<br/>机械伤害VR虚拟体验（电梯笼）<br/>" +
                            "起重伤害VR虚拟体验（塔吊）<br/>物体打击VR虚拟体验（防护外架临边）<br/>基坑坍塌VR虚拟体验<br/>脚手架坍塌VR虚拟体验<br/>" +
                            "触电伤害VR虚拟体验<br/>乙炔瓶爆炸VR虚拟体验<br/>混凝土顶板坍塌VR虚拟体验<br/>个人劳保用品VR虚拟体验<br/>起重伤害VR虚拟体验（塔吊）<br/>宿舍火灾VR虚拟体验"} />
                    <div className="interval-col-51"></div>
                    <LongList img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%AE%89%E5%85%A8%E6%95%99%E8%82%B2/image-2.png"}
                        title={"地铁版<br/>适用于地铁、市政等工程建设"} text={"支撑坠落事故VR体验<br/>基坑顶板坍塌事故VR体验<br/>围护结构坍塌事故VR体验<br/>堆载物滑坡事故VR体验<br/>" +
                            "爆炸事故VR体验<br/>机械伤害VR体验<br/>宿舍火灾伤害VR体验<br/>物体打击伤害VR体验<br/>触电伤害VR体验<br/>" +
                            "移动平台倒塌VR体验<br/>车辆伤害VR体验<br/>个人劳保用品VR体验"} />
                    <div className="interval-col-51"></div>
                    <LongList img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%AE%89%E5%85%A8%E6%95%99%E8%82%B2/image-3.png"}
                        title={"路桥版<br/>适用于公路、铁路等工程建设"} text={"高处坠落VR虚拟体验（桥梁下构）<br/>物体打击VR虚拟体验（桥梁下构）<br/>高边坡坍塌VR虚拟体验（路基）<br/>" +
                            "触电伤害VR虚拟体验（桥面）<br/>机械伤害VR虚拟体验（钢筋加工厂切断机）<br/>起重伤害VR虚拟体验（桥梁上构挂篮施工）<br/>起重伤害VR虚拟体验（预制梁厂龙门吊倾覆）<br/>" +
                            "车辆伤害VR虚拟体验（隧道内）<br/>高处坠落VR虚拟体验（塔吊爬梯）<br/>隧道逃生VR虚拟体验（隧道）<br/>宿舍火灾VR虚拟体验（宿舍）<br/>" +
                            "挖掘机机械伤害VR虚拟体验(路桥基坑）<br/>乙炔瓶爆炸VR虚拟体验（钢筋加工厂）<br/>基坑坍塌VR虚拟体验（路桥基坑）<br/>起重伤害VR虚拟体验（塔吊）<br/>个人劳保用品VR虚拟体验（钢筋加工厂）"} />
                </div>
                <div className="interval-136"></div>
            </div>
        );
    }
}

//网页第五个模块
class FifthPage extends React.Component {
    render() {
        return (
            <div className="fifth-page">
                <Title text={"方案优势"} />
                <div className="fifth-page-col">
                    <div className="interval-col-131"></div>
                    <Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%AE%89%E5%85%A8%E6%95%99%E8%82%B2/vr-1.png"}
                    text={"仿真模拟，虚拟现实。安全体验馆采用最新VR、互联网技术，在虚拟现实的“真实”环境中，让员工受到刻骨铭心的震撼式教育，安全体验效果好，极大地增强了员工的安全意识。"} />
                    <div className="interval-col-32"></div>
                    <Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%AE%89%E5%85%A8%E6%95%99%E8%82%B2/scene.png"}
                    text={"场景化、定制化教育内容。安全教育与体验内容全面、丰富，并能结合客户的应用场景进行定制化开发，全面提升员工的安全知识与技能，为彻底杜绝伤亡事故，实现企业本质安全夯实坚定的基础。"} />
                    <div className="interval-col-32"></div>
                    <Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%AE%89%E5%85%A8%E6%95%99%E8%82%B2/room.png"}
                    text={"节约空间，可移动、可复用。VR安全体验馆占用场地较少,安全体验馆所有的设备可移动，可重复使用，节约项目部的投入成本。"} />
                </div>
            </div>
        );
    }
}

//网页主模块
class SafetyEducation extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("long-list", "moveup100-init");
        ani.initclass("card-in-education", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");

        ani.initclass("third-page-img", "moveright100-center-init")
        ani.initclass("third-page-box", "moveleft100-center-init")
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("long-list", 20, "moveup100");
        ani.getClassHight("card-in-education", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");

        
        ani.getClassHight("third-page-img", 20, "moveright100-center-notime");
        ani.getClassHight("third-page-box", 20, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="safety-education">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                    <FourthPage />
                    <FifthPage />
                    <div className="blank"></div>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default SafetyEducation;