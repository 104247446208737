/*
 * @Author: your name
 * @Date: 2019-12-18 16:12:47
 * @LastEditTime : 2020-01-13 11:20:50
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\common-components\list\List.js
 */
import React from 'react';
//import './index.css';
import './list.less'

class List extends React.Component {
    //绑定点击事
    render() {
        const mainCo = this.props.name === '杭州新中大科技股份有限公司';

        return (
            <div className="common">
                <div className="list" onClick={this.props.Getname}>
                    <div>
                    <div className="interval-20" />
                    <div className="list-name" style={{fontWeight:mainCo?'bold':'none'}}>{this.props.name}</div>
                    <div className="list-adress" style={{fontWeight:mainCo?'bold':'none'}}>地址: {this.props.adress}</div>
                    <div className="list-phone" style={{fontWeight:mainCo?'bold':'none'}}>联系电话: {this.props.phone}</div>
                    <div className="interval-20" />
                    <div className="list-line"/>
                    </div>
                </div>
            </div>

        );
    }
}

export default List;