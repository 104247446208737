import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/11/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%9C%8D%E5%8A%A1/%E5%BE%8B%E5%B8%88%E8%A1%8C%E4%B8%9A%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/banner.png`,
    describe: "新中大项目服务律师行业管理解决方案是针对律师服务行业团队协作沟通、案件管理、知识共享、业务风险管理等需求，打造的由SUP平台支持、基于灵动技术的可扩展架构的信息化应用平台。方案帮助律师事务所实现综合案件、协同办公、人力资源、集团财务、知识文档、企业绩效的管理。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML:'项目服务之<br>律师行业管理解决方案',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '从集团到项目一体化管控'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '灵动配置，满足个性化需求'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '适用的工作流引擎'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '移动表单自定义'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '高效协同办公'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '万向接口，系统集成'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 6, y: 0 },
        title: '规范流程，提升效率',
        describe: '为企业搭建信息化平台，实现对非标产品项目管理企业的全过程管理。提供从接单、报价、到生产、交货、结算的动态订单跟踪功能。'
      },
      {
        key: 1,
        iconPosition: { x: 7, y: 0 },
        title: '知识积累与共享',
        describe: '通过产品族配置、柔性制造和快速响应来实现产品的多样化和定制化。'
      },
      {
        key: 2,
        iconPosition: { x: 8, y: 0 },
        title: '降低管理成本',
        describe: '提供完整的成本控制体系，解决订单洽谈过程中订单估价困难的问题，并支持企业生产过程的成本监控，让企业实时了解到订单的实际成本。'
      },
      {
        key: 3,
        iconPosition: { x: 9, y: 0 },
        title: '提升服务质量',
        describe: '优化业务流程，建立系统的信息流、物流、资金流管理模式，减少因采购、技术等因素引起的停工滞料产品交期延误的问题。'
      }
    ]
  },
  sessionE: {
    title: '国浩律师集团事务所',
    describe: `国浩律师集团事务所成立于1998年6月，是中华人民共和国司法部批准组建的集团性律师事务所，由北京市张涌涛律师事务所、上海市万国律师事务所、深圳市唐人律师事务所基于合并而共同发起设立。2011年3月，更名为国浩律师事务所。国浩律师事务所是投融资领域尤其是资本市场专业的法律服务提供者，在北京、上海、深圳、杭州、广州、昆明、天津、成都、宁波、福州、西安、南京、南宁、济南、重庆、苏州、长沙、太原、武汉、贵阳、乌鲁木齐、郑州、石家庄、合肥、海南、青岛、香港、巴黎、马德里、硅谷、斯德哥尔摩、纽约等三十二地设有分支机构。`,
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}