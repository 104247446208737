import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/uic/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E4%BA%A7%E5%93%81/uic/banner.png`,
    describe: "新中大UIC供应商门户软件是一款基于URP理论，以互动管理为核心思想，提供各平台信息出入口，实现经济资源联盟体内盟主、业主单位、承包单位、监理单位、供应商、政府单位及员工进行数据交换、信息交流、应用共享、服务实现、互动管理的平台系统。帮助联盟体单位建立协同工作、敏捷事务的信息环境。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "供应商门户UIC",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 5,
    rowCount: 1,
    default: {
      backgroundImage: `url(${baseUrl}uic.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '阳光采购'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '招标规范化、投标便捷化 评标科学化、监管有效化'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '降低人工、采购成本'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '标准化应用'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '标准化报表与分析'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}uic.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 5, y: 0 },
        title: '询单报价管理',
        describe: '采购商内部审核采购计划后发布询价信息到供应商门户，供应商在门户中登录之后，可以看到采购商发布到门户上的询价信息，可以自主选择合适的询价信息进行报价，报价完成之后信息推送到新中大i6P或i8产品中，采购商在新中大内部系统中完成对所有供应商的报价信息评定，筛选符合条件的供应商作为卖方签订乡音的合同/订单，同时供应商可以根据合同/订单进行发货、结算管理，供应商可以打印发货单，采购商可以扫描发货单上的二维码进行扫码入库，实现掌上办公。'
      },
      {
        key: 2,
        iconPosition: { x: 6, y: 0 },
        title: '招投标管理',
        describe: '供应商在门户中登录之后，可以看到发包商发布到门户上的招标信息，供应商可以选择合适的招标信息进行投标报价，投标完成之后信息推送到新中大i6pP或i8产品中，发包商在报价截止时间到后由专家在系统内部完成报价打分评定中标供应商，中标的供应商可在门户中查看自己是否中标，如中标后续可签订相应的合同/订单，进行发货、结算管理；过程中还可以对招标相关内容发起线上质疑，发包商针对质疑信息进行答疑，实现线上线下一体化招投标管理。'
      },
      {
        key: 3,
        iconPosition: { x: 7, y: 0 },
        title: '供应商注册',
        describe: '在供应商门户为供应商提供在线注册，经审核后按类型进行归类，并通知其按注册的用户名、密码登入进行后续投标、报价等操作，同时形成新中大i6P或i8产品的合格供应商。'
      }
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history:'/caseDetails/1144'
      },
      {
        logoSrc: `${baseUrl}b.png`,
        history:'/caseDetails/1099'
      }
    ]
  }
}