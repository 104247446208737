/*
 * @Author: your name
 * @Date: 2020-01-02 10:41:46
 * @LastEditTime : 2020-01-06 15:45:21
 * @LastEditors  : Please set LastEditors
 * @Description: 首页轮播组件
 * @FilePath: \my-demo\src\common-components\wheel-planting\WheelPlanting.js
 */
import React from 'react';
//import './index.css';
import './wheel-planting.less'

class WheelPlanting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nowlocation: 0,//默认0，当前播放的页面
            prelocation: 0,//默认0，上一个播放的页面，切换播放页面后
            timer: null,
        }

    }

    //修改轮播停滞时间改这里 
    componentDidMount() {
        // this.state.timer = setInterval(() => {
        //     this.rightHandler()
        // }, 10000)
        this.setState({
            ...this.state, timer: setInterval(() => {
                this.rightHandler()
            }, 10000)
        })
    }

    //离开页面后停止轮播（不解绑跳转页面调错）
    componentWillUnmount() {
        if (this.state.timer != null) {

            clearInterval(this.state.timer);

        }
    }

    //手动改变轮播页面函数
    changePage(page) {
        let nowlocation = this.state.nowlocation;
        let prelocation = this.state.prelocation;
        nowlocation = (page - 1) * 100 * (-1);  //移动到指定的页面
        this.setState({
            nowlocation: nowlocation,
        });
        document.getElementById("option" + ((prelocation / 100) * (-1) + 1)).className = "first-page-show-option";
        document.getElementById("option" + ((nowlocation / 100) * (-1) + 1)).className = "first-page-show-option-active";
        this.setState({
            prelocation: nowlocation,
        });
    }

    //用于固定往右拨一张图
    //如果已经到最右边则重置为第一张
    rightHandler() {
        let nowlocation = this.state.nowlocation;
        let prelocation = this.state.prelocation;
        nowlocation = nowlocation - 100;
        if (nowlocation < -400) {
            nowlocation = 0;
            this.setState({
                nowlocation: 0,
            });
            document.getElementById("option" + ((prelocation / 100) * (-1) + 1)).className = "first-page-show-option";
            document.getElementById("option" + ((nowlocation / 100) * (-1) + 1)).className = "first-page-show-option-active";
            this.setState({
                prelocation: nowlocation,
            });
        }
        else {
            this.setState({
                nowlocation: nowlocation,
            });
            document.getElementById("option" + ((prelocation / 100) * (-1) + 1)).className = "first-page-show-option";
            document.getElementById("option" + ((nowlocation / 100) * (-1) + 1)).className = "first-page-show-option-active";
            this.setState({
                prelocation: nowlocation,
            });
        }


    }
    render() {
        return (
            <div className="common">
                <div className="wheel-planting">

                    <div className="all-box" id='box'>
                        <div className="screen">
                            <ul id="screen" style={{ transform: "translateX(" + this.state.nowlocation + "vw)" }}>
                                <li>{this.props.show1}</li>
                                <li>{this.props.show2}</li>
                                <li>{this.props.show3}</li>
                                <li>{this.props.show4}</li>
                                <li>{this.props.show5}</li>
                                {/* <li>{this.props.show6}</li> */}
                            </ul>

                            <div className="first-page-show-box">
                                <div className="first-page-show-box1">
                                    <div className="first-page-show-option-active" id="option1" onMouseEnter={() => this.changePage(1)}></div>
                                    {/* <div className="interval-col-10"></div> */}
                                    <div className="first-page-show-option" id="option2" onMouseEnter={() => this.changePage(2)}></div>
                                    {/* <div className="interval-col-10"></div> */}
                                    <div className="first-page-show-option" id="option3" onMouseEnter={() => this.changePage(3)}></div>
                                    {/* <div className="interval-col-10"></div> */}
                                    <div className="first-page-show-option" id="option4" onMouseEnter={() => this.changePage(4)}></div>
                                    {/* <div className="interval-col-10"></div> */}
                                    <div className="first-page-show-option" id="option5" onMouseEnter={() => this.changePage(5)}></div>
                                    {/* <div className="interval-col-10"></div>
                                    <div className="first-page-show-option" id="option6" onMouseEnter={() => this.changePage(6)}></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default WheelPlanting;