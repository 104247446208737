import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/18/`;
export default {
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "现场管理系统主要是为建筑施工企业提高项目检查完成率，降低项目安全隐患而设计的一款移动端建筑信息化产品。旨在为建筑施工企业承担公司项目安全检查职责的成员服务。安全检查人员能够通过移动端发布项目需整改的安全检查事项，实时跟进项目的整改情况，对问题的整改过程进行跟踪、指导以及最终确定闭环，从而为项目的安全实施提供支持，最终建筑企业安全质量管理水平的提升。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML: '现场管理',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 1,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: 'PDCA闭环管理'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '基于二维码，低成本应用'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '移动管理，简单易用'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 3, y: 0 },
        // title: '践行核心管理思想',
        describe: '手机APP模式即时事项抽/检查检查项目分类清晰专业，让检查更规范专业。'
      },
      {
        key: 1,
        iconPosition: { x: 4, y: 0 },
        // title: '适应多种管理体系',
        describe: '在线沟通信息流转清晰明确高效，公事专用，避免了信息交叉梳理的麻烦，公私分明，提高整改效率。'
      },
      {
        key: 2,
        iconPosition: { x: 5, y: 0 },
        // title: '标准化、规范化管控',
        describe: '数据统计，专业的统计报表帮助了解部门工作、问题分布、薄弱环节，为之后的发展提供引导。'
      },
      {
        key: 3,
        iconPosition: { x: 6, y: 0 },
        // title: '集成化、集中化管控',
        describe: '基于二维码软应用，降低项目部使用成本，符合大众使用习惯，易操作。'
      },
      {
        key: 4,
        iconPosition: { x: 7, y: 0 },
        // title: '知识沉淀，经验共享',
        describe: '施工日志移动上报，体现项目部一日工作全记录，并结合天气、任务自动记录，减轻填报工作量。'
      },
      {
        key: 5,
        iconPosition: { x: 8, y: 0 },
        // title: '随时随地，移动互联',
        describe: '移动单兵模式，新中大与海康威视共同合作，倾情推出了新中大移动单兵巡检解决方案及终端产品，手持视频终端支持内置和外置摄像头可接入项目管理i8平台进行集中监控。'
      }
    ]
  },
  sessionE: {
    title: '江苏扬建集团有限公司',
    describe: `江苏扬建集团有限公司前身为扬州市建筑工程公司，公司始创于1953年，是全国创建早的一级资质总承包施工企业之一。江苏扬建集团在国内外建筑市场上享有盛誉，先后总包承建了各类高层、超高层建筑百余幢，大型工业厂房50余座，其它公用、民用建筑千余幢，创优质工程400多项。其中近五年，公司共创建“鲁班奖”工程3项、“国家优质工程”2项、国家装饰工程奖3项，扬子杯、白玉兰杯、长城杯、绿岛杯、广东省优质样板等省优工程50多项；获评全国建筑装饰科技创新奖5项，QC成果2项，江苏省省级工法10项，省级科研项目2项，江苏省新技术应用示范工程30多项。`,
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}