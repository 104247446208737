/*
 * @Author: your name
 * @Date: 2019-12-12 09:39:14
 * @LastEditTime : 2020-01-09 13:16:01
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\interlligent-printing-control\InterlligentPrintingControl.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './interlligent-printing-control.less';
import ani from '../../../transition/animation/animation';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../components/TitleSize40Center'
import ButtonYellow from '../components/ButtonYellow'
import Describe from '../components/Describe'
import Card from '../components/CardInPrint'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"智能印控"} />
                                    <div className="interval-44"></div>
                                    <ButtonYellow text={"用印风险 智能管控"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"建筑企业印章管理难点"} />
                <div className="second-page-img"></div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"新中大智能印控管理系统"} />
                <Describe text={"智能印控以“互联网+智能硬件”的方式，解决建筑企业在用印中存在的盖章难、印章被滥用、代盖、偷盖等问题，通过审批流程、手机APP、物联网控制的方式将传统印章与互联网相结合，并采用了“目前先进的”智能硬件技术，实现人章分离。"} />
                <div className="third-page-img"></div>
                <div className="interval-190"></div>
            </div>
        );
    }
}

//网页第四个模块
class FourthPage extends React.Component {
    render() {
        return (
            <div className="fourth-page">
                <Title text={"应用价值"} />
                <div className="show-list">
                    <table >
                        <tbody>
                            <tr>
                                <td><Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%99%BA%E8%83%BD%E5%8D%B0%E6%8E%A7/%E7%94%A8%E5%8D%B0.png"} text={"用印与审批一体"} /></td>
                                <td><Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%99%BA%E8%83%BD%E5%8D%B0%E6%8E%A7/Printing.png"} text={"打印与印控一体"} /></td>
                                <td><Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%99%BA%E8%83%BD%E5%8D%B0%E6%8E%A7/Record.png"} text={"记录与备案一体"} /></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td><Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%99%BA%E8%83%BD%E5%8D%B0%E6%8E%A7/file.png"} text={"用印与文件管理一体"} /></td>
                                <td><Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%99%BA%E8%83%BD%E5%8D%B0%E6%8E%A7/move.png"} text={"外带与移动授权一体"} /></td>
                                <td><Card img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%99%BA%E8%83%BD%E5%8D%B0%E6%8E%A7/recovery.png"} text={"下发与回收一体"} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="interval-170"></div>
            </div>
        );
    }
}
//网页主模块
class InterlligentPrintingControl extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("card-print", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("card-print", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="interlligent-printing-control">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                    <FourthPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default InterlligentPrintingControl;