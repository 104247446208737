import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/D6e/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "D6e企业级数字工地管理软件是助力建筑企业项目现场管理在智慧建造4.0时代快速数字化转型的一站式管理应用平台。产品从集团、分/子公司、项目部三个层级出发，站在企业级统一规划、统一标准、统一部署的角度实现多项目部现场人、机、料、法、环、测六要素的组合监管，并且与企业级应用合同管理、成本管理、资金管理等业务互联互通，数据实时上传，统一看板分析，满足企业远程监管需求。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "D6e企业级数字工地管理软件",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 3,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 0 },
        describe: '企业级部署，多项目组合管理'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 0 },
        describe: '以人为本劳务用工全过程监管'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 0 },
        describe: '基于智能物联的机械设备管理'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 0 },
        describe: '互联网+供应链现场物料全流程管理'
      },
      {
        key: 5,
        iconPosition: { x: 0, y: 0 },
        describe: '节能提效，注重现场安全管理'
      },
      {
        key: 6,
        iconPosition: { x: 1, y: 0 },
        describe: '移动应用，指尖上的数字工地'
      },
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '现场人员管理',
        describe: '现场人员管理通过人脸识别设备、移动APP、身份证读卡器、身份识别一体机等多种输入手段快速完成现场人员实名制采集，登记、下发动作，并通过企业级部署的优势实现跨组织跨项目调动，多项目用工信息共享控制。模块包含：人员登记、实名制考勤、安全教育、综合交底、奖惩记录、劳务工资结算、工资支付、智慧派工等功能，实现人员预进场、进场、在场、退场全过程监管。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '现场机械管理',
        describe: '现场机械管理基于智能设备机械宝，结合移动APP实现台班数据自动采集、定位查询、油耗数据查询并为机械租赁结算提供数据支撑，模块包含：资产设备台账、设备入网注册、设备进退场、派工管理、租入台班、加油记录单等功能。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '现场物料管理',
        describe: '现场物料管理作为物资全过程管理中的现场管理环节，主要实现现场物料收发料管理，结合智能地磅称重实现车牌识别、自动称重、称重全过程监控、流水自动关联入库为物资库存管理与结算提供数据支撑，从而达到收料验控、管控材料成本亏缺风险。模块包含：过磅称重、收料管理、发料管理等功能。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '现场管理',
        describe: '现场管理的核心为PDCA闭环，通过基于二维码的项目巡检，结合现场检查、不符合通知、整改、复检、关闭达到检查闭环管理。模块包含：项目巡检、现场检查、施工日志、实测实量等功能。'
      },

      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '指挥中心看板',
        describe: '指挥中心分为企业级、项目级。通过企业级指挥中心实现集团多层级数据汇总、多项目、多维度数据查询。通过项目级指挥中心实现项目现场人、机、料、法、环、测六要素看板查询，并结合视频监控、人员定位等实现非接触式、可视化现场管理。指挥中心可穿透业务报表，业务层层追溯到底。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '政府平台集成',
        describe: '随着国家对工地数字化监管力度日渐加强，尤其在劳务实名制监管上，全国平台、各地区平台均有数据集成和上传要求，政府平台集成模块通过对接全国及各省市区县平台，完成数据对照、数据上传、数据查询、数据下载等功能，全方面应对政府监管，配合相关部门检查。'
      },
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${global.config.url}/newgrand/picture/img/new-product/iot/a.png`,
        history:'/caseDetails/1129',
      },
      {
        history:'/caseDetails/1059',
        logoSrc: `${global.config.url}/newgrand/picture/img/new-product/iot/b.png`,
      },
      {
        logoSrc: `${global.config.url}/newgrand/picture/img/new-product/iot/c.png`,
        history:'/caseDetails/1127',
      }
    ]
  }
}