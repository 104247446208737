/*
 * @Author: your name
 * @Date: 2019-12-17 09:06:02
 * @LastEditTime : 2020-01-09 11:18:42
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\projectInvestment\large-project\LargeProject.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './large-project.less';
import ani from '../../../transition/animation/animation';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import Describe from '../../../common-components/describe/describe'
import CardB from '../components/card-b/CardB'
import Card from '../components/card/Card'
import CardA from '../components/card-a/CardA'

//页面第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="first-page-left">
                        <div className="first-page-title">大型工程投资项目信息化解决方案</div>
                        <div className="first-page-text">
                            大型工程投资项目管理解决方案主要服务于企业对大型投资项目管控的需要，是为了能更科学，更有效对投资较大的大型工程项目各个环节进行实时管控，为公司决策层、经营管理层、项目执行层提供科学的决策依据，通过信息共享和统筹管理，保证项目工作顺利开展，通过过程管理降低项目投资，提高项目效益。
                        </div>
                    </div>
                    <div className="first-page-right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }

}

//页面第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"市场需求"} />
                <div className="second-page-col">
                    <CardB title={"项目盈亏"} text={"大型投资项目投资较大、建设周期长，工程项目管理水平高低直接影响项目盈亏。"} />
                    <div className="interval-col-38" />
                    <CardB title={"项目管理水平"} text={"工程项目管理的水平和理念，与一些发达国家相比仍有相当大的差距。"} />
                    <div className="interval-col-38" />
                    <CardB title={"投资方监管"} text={"项目投资方对项目有较高的进度控制要求以及资金的合理使用要求。"} />
                    <div className="interval-col-38" />
                    <CardB title={"现场管理"} text={"项目各参建方的沟通协调，管理协调，亦或是项目现场的质量、安全以及环境的管理要求，都需要一套管理体系来支撑。"} />
                </div>
            </div>
        );
    }
}

//网页第三个模块 
class ThridPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"方案核心功能"} />
                <Describe text={"新中大大型工程投资信息化系统应用模块主要实现了对成本、合同、进度、信息、资源等工程项目关键要素的有效管理。系统深入项目管理的各个范畴，包括：投资控制、现金中心、招投标管理、合同管理、进度管理、物资管理、成本管理、质量管理、安全管理、设备管理、风险管理、竣工管理、协同办公管理、集团管理等，并可以与新中大URP软件工程版的人力资源管理、财务管理、企业绩效管理、电子商务等套件集成。"} />
                <div className="interval-80" />
                <div className="show-list">
                    <table >
                        <tbody>
                            <tr>
                                <td><Card title={"项目前期管理"} text={"实现工程立项审批（可行性研究报告、项目申请报告、环评报告）、工程开工准备（用地征地与拆迁、工程开工报告）、设计审查与投资估算等管理。"} /></td>
                                <td><Card title={"计划进度管理"} text={"通过横道图、网络图管理，实现项目计划进度编制以及实际进度对比分析，并可对进度延期情况进行预警，保证项目工期受控，发现问题及时进行调整。"} /></td>
                                <td><Card title={"投资控制"} text={"实现投资估算的建立，明确项目预算指标，通过对合同性成本的目标值、计划值以及实际发生值的管理，计算出动态成本，利用成本控制平台进行动态成本发生控制，比较动态成本与目标成本，找出差异， 最终达到成本控制的目的。"} /></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td><Card title={"合同管理"} text={"实现项目合同全过程的管理，从合同评审、合同签订、预付、变更、计量支付、扣款、结算、决算等全过程进行整体管控，有利于信息快速査询，降低了履约风险。"} /></td>
                                <td><Card title={"项目资金管理"} text={"通过项目资金账户管理，过程中资金收入、支出审批管控，动态了解项目资金余额及资金计划，提高资金使用率，降低经营风险。"} /></td>
                                <td><Card title={"质量管理"} text={"通过质量计划管理、质量检査与评定、质量整改、质量知识库等方面的管理，实现工程质量全过程管理。通过手机端可以实现移动质量巡检，提高检查信息反馈的及时性。"} /></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td><Card title={"技术管理"} text={"通过系统实现施工组织设计线上评审，专项施工方案线上审批，项目过程中的技术方案审批及存档。"} /></td>
                                <td><Card title={"安全管理"} text={"提供安全管理知识体系支撑，并对安全关注点进行预警，防患于未然，减少安全事故发生。通过手机端可以实现移动安全巡检，远程动态掌握现场安全管理情况。"} /></td>
                                <td><Card title={"环境管理"} text={"通过系统实现危险源的识别及评价，环境因素的识别及评价，动态了解环境因素对项目的潜在影响及预控措施。"} /></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td><Card title={"竣工管理"} text={"项目实施及竣工阶段资料可以进行存档，对于竣工资料的快速归集与査询非常有益；通过对资料的电子化保存,减少原始资料因借阅造成的丢失。"} /></td>
                                <td><Card title={"风险管理"} text={"风险预警对合同、进度、投资、质量、安全、流程延误等比较容易忽视的潜在风险预留空间与时间，一旦触发，主动提醒，有效地减少了各类风险的发生。"} /></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="interval-80" />
            </div>
        );
    }
}

//网页第四个模块
class FourthPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstText: ["项目资金的动态管理", "项目投资控制与合同管理", "项目进度协同", "质量、安全与环境管理", "项目竣工与转固"],
            secondText: ["运筹帷幄掌控您投入的项目资金", "体现您的投资价值",
                "构建项目进度监控平台", "及时规避项目实施中风险隐患",
                "为您后期运营管理提供切实的保障"],
        };
    }
    render() {
        return (
            <div className="fourth-page">
                <Title text={"方案价值"} />
                <div className="interval-100"></div>
                <div className="fourth-page-col">
                    <div className="fourth-page-one">
                        <CardA img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%8A%95%E8%B5%84/%E5%A4%A7%E5%9E%8B%E5%B7%A5%E7%A8%8B%E6%8A%95%E8%B5%84%E9%A1%B9%E7%9B%AE%E4%BF%A1%E6%81%AF%E5%8C%96/icon-1.png"}
                        firstText={this.state.firstText[0]} secondText={this.state.secondText[0]} />
                    </div>
                    <div className="fourth-page-two">
                        <CardA img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%8A%95%E8%B5%84/%E5%A4%A7%E5%9E%8B%E5%B7%A5%E7%A8%8B%E6%8A%95%E8%B5%84%E9%A1%B9%E7%9B%AE%E4%BF%A1%E6%81%AF%E5%8C%96/icon-2.png"}
                        firstText={this.state.firstText[1]} secondText={this.state.secondText[1]} />
                    </div>
                    <div className="fourth-page-two">
                        <CardA img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%8A%95%E8%B5%84/%E5%A4%A7%E5%9E%8B%E5%B7%A5%E7%A8%8B%E6%8A%95%E8%B5%84%E9%A1%B9%E7%9B%AE%E4%BF%A1%E6%81%AF%E5%8C%96/icon-3.png"}
                        firstText={this.state.firstText[2]} secondText={this.state.secondText[2]} />
                    </div>
                </div>
                <div className="interval-30"></div>
                <div className="fourth-page-col">
                    <div className="fourth-page-three">
                        <CardA img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%8A%95%E8%B5%84/%E5%A4%A7%E5%9E%8B%E5%B7%A5%E7%A8%8B%E6%8A%95%E8%B5%84%E9%A1%B9%E7%9B%AE%E4%BF%A1%E6%81%AF%E5%8C%96/icon-4.png"}
                        firstText={this.state.firstText[3]} secondText={this.state.secondText[3]} />
                    </div>
                    <div className="fourth-page-two">
                        <CardA img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%8A%95%E8%B5%84/%E5%A4%A7%E5%9E%8B%E5%B7%A5%E7%A8%8B%E6%8A%95%E8%B5%84%E9%A1%B9%E7%9B%AE%E4%BF%A1%E6%81%AF%E5%8C%96/icon-5.png"}
                        firstText={this.state.firstText[4]} secondText={this.state.secondText[4]} />
                    </div>
                </div>
                <div className="interval-162"></div>
            </div>
        );
    }
}
//网页主模块
class LaregProject extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("first-page-title", "moveup20-initial");
        ani.initclass("first-page-text", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("card", "moveup100-init");
        ani.initclass("card-b", "moveup100-init");
        ani.initclass("card-a", "moveup100-init");

    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("first-page-title", 20, "moveup20");
        ani.getClassHight("first-page-text", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        ani.getClassHight("card", 20, "moveup100");
        ani.getClassHight("card-a", 20, "moveup100");
        ani.getClassHight("card-b", 20, "moveup100");

    }
    render() {
        return (
            <div>
                <Head />
                <div className="large-project">
                    <FirstPage />
                    <SecondPage />
                    <ThridPage />
                    <FourthPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default LaregProject;