/*
 * @Author: your name
 * @Date: 2020-01-09 09:39:31
 * @LastEditTime : 2020-01-09 13:50:46
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\transition\animation\utils.js
 */


import $ from 'jquery';
export default class {

    //获取相关id的高度
    static getHight(id, showposition, classname) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }
    //初始化添加动效的类名
    static initclass(classname, addclassname) {
        let classlist = $("." + classname);
        for (let i = 0; i < classlist.length; i++) {
            $(classlist[i]).addClass(addclassname);
        }
    }
    //监控所有指定class的目标
    static getClassHight(myclass, showposition, classname) {
        let classlist = $("." + myclass);
        for (let i = 0; i < classlist.length; i++) {
            let height = $(classlist[i]).offset().top - $(window).scrollTop() - $(window).height();
            if (height < showposition) {
                $(classlist[i]).addClass(classname);
            }
        }
    }
    //触发本组件的动画
    static getOtherHight(otherid, id, showposition, classname) {
        let height = $("#" + otherid).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }

    // @{DOM} 欲进行判断的DOM节点
    // @{triggerHeight} 期待的触发高度（距离浏览器底部）
    static reachTriggerHeight(DOM, triggerHeight) {
        if (!DOM) { return false }
        return $(DOM).offset().top - $(window).scrollTop() - $(window).height() < (triggerHeight || -20);
    }
};