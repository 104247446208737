/*
 * @Author: your name
 * @Date: 2019-12-12 09:40:26
 * @LastEditTime : 2020-01-09 13:13:39
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\machinery-management\MachineryManagement.js
 */

import React from 'react';
//import './index.css';
import './live.less';
import ani from '../../../transition/animation/animation';
//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../../digitalSite/components/TitleSize40Center'
import Describe from '../../digitalSite/components/Describe'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"网络直报解决方案"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案介绍"} />
                <Describe text={"网络直报解决方案在系统中通过报表管理中心这个套件来实现相关的业务，由集团或者总部来统一报表的格式，而项目部或者分公司等相关人员通过填报报表或者汇总报表的方式，向总公司层层上报各项运营数据，提供给管理层各类数据进行决策分析和管理。<br/>"+
                                "此方案的构建,进一步深入推进了统计信息化建设,加强了企业数据上报效率性和规范性,实现了基本统计工作自动化、标准化、高效化,为各级领导及管理人员的决策提供了强大的数据支持。"} />
                <div className="interval-50"></div>
                <Title text={"客户需求"} />
                <Describe text="统计信息作为企业重要的战略资源之一，是企业决策者了解企业内外经营环境，掌握企业生产经营的历史和现状，把握企业发展方向，做出科学合理的决策重要的依据。大多是施工企业集团都存在二级以上的管理层级，管理面广、管理层级深且复杂；管理是分层级的，对于上级来说不可能管理到下级单位的详细业务，更多的是对下级所上报的信息进行审核和分析，对关键点风险进行管控，从而起到监督和服务的作用。统计信息涵盖了企业生产经营活动的多方面、各个环节，牵涉面广，影响程度深，需要进行系统有效的管理。" />
                <div className="interval-154"></div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"方案价值"} />
                <div className="interval-90" />
                <div className="fourth-page-card">
                    <div className="fourth-page-card-icon">
                        <div className="fourth-page-card-num">1</div>
                    </div>
                    <div className="fourth-page-card-text">
                        数据更准确：实现了数据的自动累计，使得数据统计更高效、准确。
                    </div>
                </div>
                <div className="interval-20" />
                <div className="fourth-page-card">
                    <div className="fourth-page-card-icon">
                        <div className="fourth-page-card-num">2</div>
                    </div>
                    <div className="fourth-page-card-text">
                        数据更及时：实现了数据上线级自动汇总统计，数据统计更及时。
                    </div>
                </div>
                <div className="interval-20" />
                <div className="fourth-page-card">
                    <div className="fourth-page-card-icon">
                        <div className="fourth-page-card-num">3</div>
                    </div>
                    <div className="fourth-page-card-text">
                        数据质量更高：可以进一步保证数据直接报送，减少中间环节，提高数据质量。
                    </div>
                </div>
                <div className="interval-20" />
                <div className="fourth-page-card">
                    <div className="fourth-page-card-icon">
                        <div className="fourth-page-card-num">4</div>
                    </div>
                    <div className="fourth-page-card-text">
                        数据分析更透彻：便于对数据进行深层次分析，如根据单位按照年度、月度逐级统计分析。
                    </div>
                </div>
                
                <div className="interval-140" />
            </div>
        );
    }
}


//网页主模块
class Live extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("fifth-page-card", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");

        ani.initclass("sixth-page-card", "moveright100-center-init")
        ani.initclass("sixth-page-card-textcard", "moveleft100-center-init")
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("fifth-page-card", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");

        ani.getClassHight("sixth-page-card", 20, "moveright100-center-notime");
        ani.getClassHight("sixth-page-card-textcard", 20, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="live">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default Live;