import './index.less';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import Animation from '../../transition/animation/animation';

export default (props) => {
  const state = props;
  const ref = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    let animationFinash = false;
    let animationFn = () => {
      if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
        setStartAnimation(true);
        animationFinash = true;
      };
    }
    animationFn();
    window.$(window).scroll(animationFn);
    return () => {
      window.$(window).unbind('scroll', animationFn);
    }
  },[]);

  return (
    <div className={`img-title-text-item moveup20-initial opacity-initial${startAnimation ? ` moveup20` : ``}`} ref={ref}>
      <div className="icon-container">
        <div className={`icon moveup20-initial opacity-initial${startAnimation ? ` moveup20` : ``}`} style={{ ...state.iconStyle }}></div>
      </div>

      <div className={`text-container moveup20-initial opacity-initial${startAnimation ? ` moveup20` : ``}`} >
        <div className="middle-inner">
          <div className={`${state.title ? `title` : ``}`}>
            <span>{state.title}</span>
          </div>

          <div className="text">
            <span>{state.describe}</span>
          </div>
        </div>
      </div>
    </div>
  )
}