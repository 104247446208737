import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/2/`;
export default {
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "集团人力资源管理解决方案面向大中型集团型企业，针对具有项目管理特性的HR管理业务需求提供全方面的管理支持。方案应用包括了组织规划、员工信息管理、项目人员管理、员工事务管理、绩效管理、考勤管理、薪资福利管理、招聘管理等核心功能模块，帮助企业解决组织结构复杂多变、人力资源管理信息滞后、员工事务性工作量较大、绩效考核难度较大、员工培训组织困难、薪资奖金核算不清、企业文化建设缺乏、人才经常流失、招聘不到合适人才等问题。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "集团人力资源管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '多组织架构'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '移动化应用'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '全业务覆盖'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '一体化管控'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '互联网风格'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '互动式管理'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        describe: '个性化配置'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 4,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        describe: '帮助企业实现企业战略与人力资源管理有效结合。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        describe: '实现企业人力资源互动式管理，包括HR部门与员工互动、HR管理与业务领导互动、HR管理与企业领导互动、HR管理内部互动。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        describe: 'eHR系统借助互联网+物联网技术，实现企业人力资源全过程管理，减少员工事务性工作。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        describe: '构建企业人力资源与外部机构协作管理，包括与外部高校、人才网站的协作。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        describe: '构建企业在线学习平台，让企业员工培训学习更加轻松。'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 1 },
        describe: '考勤、奖惩、绩效、社保、薪资、个税一体化管控，通过薪点表构建企业薪资结构。'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 1 },
        describe: '实现HR数据智能化采集、集成化、移动化应用，全方位管理员工信息。'
      },
      {
        key: 7,
        iconPosition: { x: 7, y: 1 },
        describe: 'HR管理数据实时在线统计分析、预警风险即时推送，实现人力资源数字化管理。'
      }
    ]
  },
  sessionE: {
    title: '天元建设集团有限公司',
    describe: '天元建设集团有限公司是一家具有国家特级施工总承包资质，2017年集团成功申请市政公用工程施工总承包特级资质及工程设计市政行业甲级资质，成为同时拥有建筑、市政施工双特级，建筑、市政、装饰、幕墙、消防设计五甲级资质的民营企业，推动集团率先进入“双特新阶段”。集团现有分支机构和控股子公司70余家，各类经济技术人员8500余人，其中，高、中级职称人员3600余人，具有年完成产值1000亿元、承建8000万平方米工程的总承包能力。',
    backgroundImage:`url(${baseUrl}anli.png)`
  }
}