/*
 * @Author: your name
 * @Date: 2019-12-18 14:46:18
 * @LastEditTime : 2019-12-25 17:41:11
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\common-components\card-b\CardB.js
 */
import React from 'react';
//import './index.css';
import './card-B.less'
import '../../transition/up/up.less'
import '../../transition/change-color/color.less'

class CardB extends React.Component {
    render() {
        return (
            <div className="common">
                <div className="card-b up-18 bg-yellow" >
                    <div className="interval-50"></div>
                    <div className="card-b-img changeimg" style={this.props.img}></div>
                    <div className="card-b-text white">{this.props.text}</div>
                </div>
            </div>
        );
    }
}

export default CardB;