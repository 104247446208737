/*
 * @Author: your name
 * @Date: 2020-01-02 09:14:32
 * @LastEditTime : 2020-01-09 13:56:52
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\common-components\scroll-to-top\ScrollToTop.js
 */
/*回到页面顶部 */
import React from 'react';
import {withRouter} from 'react-router-dom';

class ScrollToTop extends React.Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(ScrollToTop);