/*
 * @Author: your name
 * @Date: 2019-12-18 15:40:52
 * @LastEditTime : 2020-01-13 15:01:55
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\common-components\card-d\CardD.js
 */

import React from 'react';
//import './index.css';
import './card-D.less'
import '../../transition/up/up.less'
import '../../transition/add-shadow/shadow.less'

class CardD extends React.Component {
    render() {
        return (
            <div className="common">
                <div className="card-d up-18 shadow" onClick={this.props.click}>
                    <div className="card-d-img">
                        <img src={this.props.img}></img>
                    </div>
                    <div className="card-d-text">{this.props.text}</div>
                    <div className="card-d-time">{this.props.time}</div>
                </div>
            </div>

        );
    }
}

export default CardD;