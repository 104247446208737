import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/i8/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E4%BA%A7%E5%93%81/i8/banner.png`,
    describe: "i8工程项目管理平台是助力建筑企业在智慧建造4.0时代转型升级的战略级、一站式管理应用平台，产品融入互联（interconnection）、互动（interaction）、智能（intelligence）、集成（integration）、创新（innovation）、个性（individuality）、共享（ishare）、国际（international）8大理念，赋能企业管理升级。产品按照一个中心、两大目标、三个层级、四条主线、十大平台的框架进行设计开发：以智慧建造4.0为中心；以企业级融合与项目部融合为两大目标；以集团-区域公司/分公司-项目部管理为三个层级；以进度、成本、资金、综合四条主线为核心进行模块设计及逻辑关系搭建；打造工程项目管理为核心的运营管理、人力资源管控、财务资产与资金管控、营改增税控管理、协同办公、共享中心、管理支撑、电子商务、智慧建筑管理、应用集成等十大平台。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "i8工程项目管理平台",
    imgStyle: {
      maxHeight: '100%',
    },
  },

  sessionB: {
    src: global.config.url + '/NewGrand/media/i8.e71a8980.mp4'
  },

  // 产品特点
  sessionC: {
    default: {
      backgroundImage: `url(${baseUrl}i8.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 0 },
        describe: 'BIM融合、支持装配式施工创新企业级项目管理'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 0 },
        describe: '设计采购施工一体，支持EPC模式'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 0 },
        describe: '四流合一、业税财一体'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 0 },
        describe: '七大中心，共享服务'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 0 },
        describe: '九算对比、精细化经营'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 0 },
        describe: '智慧建造 风险管控'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 0 },
        describe: '全新WEB架构、全面移动应用'
      },
      {
        key: 8,
        iconPosition: { x: 7, y: 0 },
        describe: '柔性化平台，管理随需而变'
      },
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}i8.png)`,
    },
    extraInfo:[
      {title:'投标管理',text:"通过商机跟踪、标前立项、招标文件、标书编制、投标保证金管理、标后分析、标后交底等功能，实现投标过程管理以及经营分析。"},
      {title:'合同管理',text:"实现项目合同全过程的管理，从合同评审、合同签订、预付、变更、计量支付、扣款、结算、决算等全过程进行整体管控，有利于信息快速査询，降低了履约风险。"},
      {title:'进度管理',text:"通过横道图、网络图管理，实现项目计划进度编制以及实际进度对比分析，并可对进度延期情况进行预警，保证项目工期受控，发现问题及时进行调整。"},
      {title:'质量管理',text:"通过质量计划管理、质量检査与评定、质量整改、质量知识库等方面的管理，实现工程质量全过程管理。通过手机端可以实现移动质量巡检，提高检查信息反馈的及时高效。"},
      {title:'技术管理',text:"通过系统实现施工组织设计线上评审，专项施工方案线上审批，项目过程中的技术方案审批及存档。"},
      {title:'安全管理',text:"提供安全管理知识体系支撑，并对安全关注点进行预警，防患于未然，减少安全事故发生。通过手机端可以实现移动安全巡检，远程动态掌握现场安全管理情况。"},
      {title:'环境管理',text:"通过系统实现危险源的识别及评价，环境因素的识别及评价，动态了解环境因素对项目的潜在影响及预控措施。"},
      {title:'物资管理',text:"系统支持集中采购和自行采购等模式，集采通过电商门户或集采平台进行采购数据融合，将供应商与企业串联起来，提高采购效率。"},
      {title:'周转材料管理',text:"通过周转材料台账、租赁合同及进场单、退场单等数据，自动计算租赁天数及数量，自动生成租赁结算单，方便周转材料管理及结算。"},
      {title:'设备管理',text:"系统支持自有、外租设备台账及维修保养管理，支持租赁设备进退场单以及租赁费用自动计算功能，方便设备台账管理及成本分析。"},
      {title:'成本管理',text:"通过企业级成本分析，了解到企业可能存在成本失控的项目，通过项目成本分析，可以将成本超支情况聚焦到具体分部分项和费用类型，进而更好得进行调整和控制。"},
      {title:'项目资金管理',text:"通过项目资金账户管理，过程中资金收入、支出审批管控，动态了解项目资金余额及资金计划，提高资金使用率，降低经营风险。"},
      {title:'竣工管理',text:"项目实施及竣工阶段资料可以进行存档，对于竣工资料的快速归集与査询非常有益；通过对资料的电子化保存,减少原始资料因借阅造成的丢失。"},
      {title:'风险管理',text:"风险预警对合同、进度、成本、质量、安全、流程延误等比较容易忽视的潜在风险预留空间与时间，一旦触发，主动提醒，有效地减少了各类风险的发生。"},
      {title:'',text:""},
    ],
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '项目管理',
        describe: '新中大i8系统项目管理套件涵盖了从项目前期投标到中标后过程管理以及竣工收尾的项目全生命周期管理，包括投标策划管理、合同管理、进度管理、质量管理、技术管理、沟通管理、安全管理、环境管理、物资管理、周转材料管理、设备管理、成本管理、项目资金管理、竣工管理、风险控制等功能模块，实现对项目全过程管控。',
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '财税管理',
        describe: '以实现财务集中管理为主导思想，充分体现以财务管理、项目管理为主线的具有工程企业特色的集团财务管理体系；集团财务管控在设计时体现为“两个全面、三集中、五统一”原则，功能涵盖了集团财务核算、报表管理、集团资金管理、全面预算管理等，同时通过与合同管理、项目管理、物资管理、发票税务、资金管理等系统的集成实现业务税务资金财务一体化，最终实现对企业“关键经营、重点风险”的整体管控。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '数字工地管理',
        describe: '数字工地管理包括智慧用工管理（劳务实名制管理、智慧派工管理、工地考勤管理、安全教育培训VR）、可视化工地管理（工地视频监控系统、塔吊安全管理、塔吊盲吊系统与升降机管理、环境监测系统、智能安全帽管理、远程移动巡检系统、危险源识别管理）、现场物资验收管理（物料验收地磅管理、二维码收料管理）、智能印章管理（项目部印章管理），以及集团统一部署的远程评标会议系统等综合应用管控系统，助推建筑施工企业二次发展应用转型的高速腾飞。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '人力资源管理',
        describe: '通过人力资源管理系统能够对组织内外相关人力资源进行有效运用，满足组织当前及未来发展的需要，保证组织目标实现与成员发展的最大化，有效降低企业的人力资源运营成本。人力资源管理系统主要包括组织管理、人事档案管理、员工事务管理、员工招聘管理、员工考勤管理、薪资福利管理、员工培训管理、员工绩效管理、员工职业规划等。'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '协同办公管理',
        describe: '协同办公模块主要包括个人事务管理、日常办公管理、内部协同管理、文档知识管理、档案管理、流程监控管理六个功能模块,实现企业内部信息协同及管理协同，提高企业管理效率。'
      }
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history:'/caseDetails/1129',
      },
      {
        history:'/caseDetails/1128',
        logoSrc: `${baseUrl}b.png`,
      },
      {
        logoSrc: `${baseUrl}c.png`,
        history:'/caseDetails/1127',
      },
      {
        logoSrc: `${baseUrl}d.png`,
        history:'/caseDetails/1118',
      }
    ]
  }
}