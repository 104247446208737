/*
 * @Author: your name
 * @Date: 2019-12-11 13:12:25
 * @LastEditTime : 2019-12-23 09:43:30
 * @LastEditors  : Please set LastEditors
 * @Description: 首次用于 数据仓库页面 用16号字体的描述 居中
 * @FilePath: \my-demo\src\pages\digitalSite\components\Describe.js
 */
import React from 'react';
//import './index.css';
import './describe.less'


/**
 * @description  宽度1000px的一段非粗体描述文本
 *              需提供参数: 描述的文本 <text>
 * @author chenyutan
 * @date 2019-12-11
 * @class Describe
 */
class Describe extends React.Component {
    render() {
        return (
            <div className="common">
                <div className="describe" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
            </div>

        );
    }
}

export default Describe;