/*
 * @Author: your name
 * @Date: 2019-12-26 15:35:12
 * @LastEditTime : 2020-01-09 13:58:03
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\CompanyIntroduction\management-layer\management-layer.js
 */
import React from 'react';
//import './index.css';
import './management-layer.less'

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'


//第一个模块
//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-col">
                    <div className="first-page-img"></div>
                    <div>
                        <div className="manager-name">石钟韶</div>
                        <div className="manager-introduction">
                            公司创始人、董事长，1983年毕业于北京大学力学系获得学士学位，
                            1989年毕业于浙江大学力学系获得硕士学位。石钟韶先生自1993年创立新中大以来，
                            不断致力于管理软件和企业管理思想的创新发展，帮助中国企业和政府机构通过信息化实现先进管理
                            ，带领公司在项目管理 信息化、品牌企业电子商务、智慧三农及政府理财信息化等领域不断前行。
                            石钟韶先生在事业践行过程中，屡获殊荣，曾获得“中国软件企业十大领军人物”、“中国优秀民营科技企业家”、“杭州市十大杰出青年”、“中国软件行业十大杰出青年”、“十大时尚浙商”等荣誉，
                            现任中国软件行业协会常务理事、杭州市计算机学会副理事长、浙商理事会主席、浙江省政协委员、杭州市民盟常委。
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <div className="second-page-col">
                    <div>
                        <div className="manager-name-second">韩爱生</div>
                        <div className="manager-introduction-second">
                        韩爱生，杭州新中大科技股份有限公司总裁。具有丰富的经营及管理经验，创造性地提出“智慧建造4.0”理念
                        ，将移动互联网、 云计算、大数据、智能物联网等新型技术与企业级综合项目管理、营改增、
                        财税管理等信息化解决方案深度融合。参编由中国建筑业协会主编的《建筑业营改增实施指南》。
                        韩爱生总裁凭借在企业管理上的创新和行业信息化服务领域的突出贡献先后获评“2017中国信息化和软件服务业
                        年度风云人物”、“2017年中国建筑业年度人物”。
                        </div>
                    </div>
                    <div className="second-page-img"></div>
                </div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <div className="first-page-col">
                    <div className="third-page-img"></div>
                    <div>
                        <div className="manager-name">李伯鸣</div>
                        <div className="manager-introduction">
                        公司总工程师，教授级高级工程师，1988年毕业于上海理工大学系统工程系获硕士学位，
                        2000年加入新中大。李伯鸣先生现任全国工业自动化系统与集成标准委员会委员、中国电子商务学会专家、
                        中国制造业ERP产业技术联盟理事、中国土木工程学会咨询委员会委员、中国建筑学会经济分会理事、浙江省电子商务学会理事、
                        杭州市政府采购评标委员会专家
                        、浙江工业大学计算机学院专业建设咨询委员会专家，国家863项目负责人和国家“核高基”项目课题组长，是业内知名的技术专家。
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页主模块
class ManagementLayer extends React.Component {
    render() {
        return (
            <div>
                <Head />
                <div className="management-layer">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default ManagementLayer;