import React from "react";
import './index.less';

function PureImage(props) {
  return (
    <div className="pureImage-img-container">
      <img src={props.imgSrc} alt="" />
    </div>
  )
}

export default PureImage;