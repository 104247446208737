import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/i6s/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E4%BA%A7%E5%93%81/i6s/banner.png`,
    describe: "新中大i6s企业管理软件是面向项目型服务企业的、以项目管理为核心的企业级综合管理信息系统软件。系统基于联盟体资源计划（URP）理论，融合了优秀项目管理思想，并在律师事务所、软件服务与系统集成行业、全过程工程咨询服务行业、会计事务所、证券公司、IT服务业等项目服务型企业调研基础上研发而成，帮助项目服务型企业实现多组织多项目管控。产品功能涵盖项目管理、客户关系管理、项目合同管理、项目供应链管理、项目成本管理、协同办公管理、人力资源管理、集团财务管理、集团费控管理、企业资金管理、企业资产管理、企业管控等。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "i6s企业管理软件",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 4,
    rowCount: 1,
    default: {
      backgroundImage: `url(${baseUrl}i6s.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 0 },
        describe: '项目和业务系统一体化管控'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 0 },
        describe: '多项目统筹协调管控'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 0 },
        describe: '项目管理办公室PMO运作'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 0 },
        describe: '管理集成'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}i6s.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '项目管理',
        describe: 'i6s系统秉承“全程项目管理”的思想，实现多组织、多部门、多任务协同的企业管理应用新模式，同时实现项目进度、项目人员、项目文档与知识、项目成本多维度管控。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '客户关系管理',
        describe: '客户关系管理的目标是提高大项目投标中标率、缩减销售周期和销售成本、增加收入、寻找扩展业务所需新的市场和渠道，同时提高客户的满意度和忠实度。客户关系管理套件旨在选择和管理有价值客户及其关系，通过以客户为中心的企业文化来支持有效的市场营销、销售与服务流程。模块包含商机管理、客户信息管理等核心子模块。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '项目合同管理',
        describe: '项目合同管理突破传统意义上的非结构化纯文本备案式管理，将合同中的关键要素（如收款条件、结算里程碑、结算计划、合同续签期限等）紧密嵌入企业的业务与管理流程，并结合着预警与审批机制，有效进行合同的全生命周期管理。使企业各部门围绕合同内容开展的工作都可以通过系统实现智能化协同。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '人力资源管理',
        describe: '人力资源管理套件从项目管理实际问题出发，动态适应项目与企业组织的多变性、地域的广泛性、人员的流动性，实现人力资源的组织管理、职位职务管理、招聘管理、薪酬管理、薪点表体系管理、培训管理、和绩效管理等，提升项目服务的人才竞争能力。'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '集团财务管理',
        describe: '集团财务管理系统基于URP理念，帮助经济资源联盟体建立以预算管理为核心的集团财务控制体系。系统应用分为二层：  1、基础核心层包括总账、报表中心、现金中心、客户中心、供应商中心等系统，构成企业会计核算基础； 2、决策管理层包括全面预算管理、经营分析、集团管理等系统，满足企业高层财务管控的需要。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '集团费控管理',
        describe: '集团费控管理集费用预算、自助借款报销、费用结算为一体，提供费用预算编制、费用预算控制、移动借款报销、移动审批、费用预算分析、费用结算等功能，实现企业从费用预算的制定到预算执行的监控，从借款报销的过程控制到事后的预算分析，帮助企业实现借款报销、费用控制和费用分析，监控企业费用开支的全过程管控。'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 1 },
        title: '企业资金管理',
        describe: '系统提供资金计划、资金收支管理、资金分析报表、业务结算、票据管理、利息管理等功能，实现企业资金计划编制，资金收支的实时监控和事后分析。企业资金管理同时提供资金结算中心模式的解决方案，实现企业建立结算中心的资金模式管控方式，统一调度资金、运筹以降低资金成本，提高资金使用效率。'
      },
      {
        key: 8,
        iconPosition: { x: 7, y: 1 },
        title: '企业资产管理',
        describe: '企业资产管理套件实现企业对资产的盘点、资产内部转移、资产账务管理及报废处理等资产生命周期各环节统一的平台化管理，可实现企业查询、统计、分析、决策支持的功能，有利于实现企业资产的统一管理、监控和查询，大大提高企业整体资产的使用效率。'
      },
      {
        key: 9,
        iconPosition: { x: 8, y: 1 },
        title: '项目供应链管理',
        describe: '项目供应链管理提供了以项目为主导的请购、询价、报价、采购计划、采购订单、检验、入库、销售计划、销售订单、发货、出库的全过程管理，同时实现按项目管理库存，按项目核销，按项目调整价格策略。'
      },
      {
        key: 10,
        iconPosition: { x: 9, y: 1 },
        title: '项目成本管理',
        describe: '灵动项目成本套件根据事先制订的项目成本目标，围绕项目服务管理过程中与成本有关的各个环节，进行成本的预算、计划、变更控制、核算、决算及统计分析，将各项成本控制在成本目标和预算之内，配合项目管理套件中任务工时确认系统，细化项目各阶段的人工成本的正态分布，从而给项目报价、预算和成本控制带来管理提升。'
      },
      {
        key: 11,
        iconPosition: { x: 0, y: 2 },
        title: '移动应用',
        describe: '移动APP的应用主要包括企业通讯录、即时通讯、消息自由呼、我的照片、请假、签到、日程日志、总结计划、文档管理、我们知道、维基百科、商机管理、借款报销、新闻公告、档案借阅、移动审批、移动报表、项目资金、项目信息、任务与工时、项目验收、项目日程日志、项目总结计划、利益冲突检索等功能。'
      },
      {
        key: 12,
        iconPosition: { x: 1, y: 2 },
        title: '柔性化统一平台',
        describe: '新中大柔性化统一平台SUP以项目管理为核心，基于企业服务总线ESB的大型企业管理信息化系统建设和管理软件开发为目的，提供可配置、可重构的基础应用中间件平台与关键支撑的工具集。应用于信息化项目实施团队快速、低成本、高可靠地开发和部署项目服务型企业信息化管理系统。'
      },
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history:'/caseDetails/1097'
      },
      {
        logoSrc: `${baseUrl}b.png`,
        history:'/caseDetails/1096'
      },
      {
        logoSrc: `${baseUrl}c.png`,
        history:'/caseDetails/1146'
      }
    ]
  }
}