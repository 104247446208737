/**
 * 全名： 集团财务管理页面
 * 功能描述： 财税共享
 * 创建日期：--
 * 作者：hzw
 */

import React from "react";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import ContactBottom from '../../../new-components/ContactBottom';
import ArchitectureImage from '../privateComponents/ArchitectureImage';
import RealCase from '../privateComponents/RealCase';
import RenderWithTitle from '../../../high-orders/RenderWithTitle';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn';

import config from "./config"
import './index.less';

function IntroduceEntry(props) {
  // const rowCount = 1;
  const colCount = 4;

  const currentRow = [];
  [...Array(colCount)].forEach((el, colIndex) => {
    let currentColProps = props.items[colIndex]
    currentRow.push(
      <div alt=""
        style={currentColProps.style}
        key={currentColProps.key}
        className="entry"
        onClick={(e) => { props.onClick && props.onClick({ ...e, busKey: currentColProps.key }) }}
      ></div>
    )
  })

  return <div className="row">{currentRow}</div>
}



export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleEntryClick = this.handleEntryClick.bind(this)
  }

  handleEntryClick(e) {
    switch (e.busKey) {
      case 0:
        this.props.history.push('/financialAccounting');
        break;
      case 1:
        this.props.history.push('/financeManage');
        break;
      case 2:
        this.props.history.push('/financeControl');
        break;
      case 3:
        this.props.history.push('/financeShare');
        break;
      default:
    }
  }

  render() {
    const RealCaseWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(RealCase, '更多案例 >', {}, () => {
      this.props.history.push('/caseof21/集团管控/业务领域');
    }), '用户案例');

    return (
      <div className="finance-group">
        <Head />
        <Abstract {...config.sessionA} />
        <ArchitectureImage  {...config.sessionB} />
        <div className="entryContainer" >
          <IntroduceEntry {...config.sessionC} onClick={this.handleEntryClick} />
        </div>
        {/* <RealCaseWithTitleAndMoreBtn  {...config.sessionD}/> */}
        <ContactBottom />
        <Bottom />
      </div>
    )
  }
}