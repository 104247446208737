import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/19/`;
export default {
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "工程总承包企业项目管理信息化解决方案针对总承包企业在集团化运营管理中，存在的项目多地分散；内部协同、外部协调沟通成本大、效率低；项目多板块、多业态管理模式不同；项目中的种种风险难以管控等问题，方案覆盖项目全生命周期，包括项目策划、项目设计管理、项目进度管理、项目采购管理、项目费用管理、项目合同管理、项目质量管理、项目HSE管理、项目施工管理、项目试运行管理、项目资源管理、项目风险管理、项目收尾、项目档案管理等阶段。以帮助企业改进项目管理集中化、标准化、可视化、复用化，来确保企业战略目标的如期实现，同时通过项目信息的归集、整理与沉淀，基于企业项目最佳实践来促进项目管理水平的提高，进而提升企业项目管理成熟度及竞争能力。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML: '工程总承包企业项目管理',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '覆盖项目全生命周期管理，基于PMBOK核心知识体系'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '借助工作流驱控加业界实践标准来规范业务运作及实现团队协同'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '以成本管理为核心，符合企业“业财税”一体化管理要求'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '可配置平台按需定制，实现用户自定义配置功能'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '动态化、前瞻性、直观化地监控项目过程'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: 'SOA架构搭积木式的系统实现，按需定制、范围可选择、深度可定义'
      },
      {
        key: 6,
        iconPosition: { x: 4, y: 0 },
        describe: '“互联网+智慧工地”背景下 “云、大、物、智、移”的数字化转型'
      },
      {
        key: 7,
        iconPosition: { x: 5, y: 0 },
        describe: '项目看板、BI输出、监控级中心不同层级的企业项目数据支持'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        title: '践行核心管理思想',
        describe: '以计划管控为核心，成本管理为主线，知识管理为沉淀确保项目的全方位、全生命周期管理。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        title: '适应多种管理体系',
        describe: '适用于企业多管理矩阵、多角色、多分支机构、多项目并行的应用。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        title: '标准化、规范化管控',
        describe: '对项目进行有序管理有效控制，全面提高管理人员的项目管理水平，实现企业从多业务角度、规范化、标准化管理各项目管理单位的工作。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        title: '集成化、集中化管控',
        describe: '录入与积累项目执行过程的信息，通过与企业其他业务系统的集成，避免信息多次录入，造成信息不准确、版本不唯一，及人力资源的浪费。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        title: '知识沉淀，经验共享',
        describe: '固化项目执行最佳实践，提高项目的快速复制能力，形成企业的无形资产。'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 1 },
        title: '随时随地，移动互联',
        describe: '自主研发的APP平台、邮件平台及即时通讯工具，并支持集成企业微信、钉钉等移动管理应用，将企业办公与通讯工具融为一体。'
      }
    ]
  },
  sessionE: {
    title: '山东国舜建设集团有限公司始',
    describe: `山东国舜建设集团有限公司始建于1985年，注册资金1.17亿元，于2001年由“长清工业设备安装有限公司”改制而来。历经三十余年的稳健发展，国舜集团已由一个小型工业设备安装企业成功转型成为拥有年产值40亿元生产能力、国内资质最齐全、规格最高，集研发、设计、制造、施工、运营管理、投资服务于一体的大气污染治理龙头环保企业、绿色装配式建筑企业和钢结构国际贸易企业。`,
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}