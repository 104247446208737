/*
 * @Author: your name
 * @Date: 2019-12-16 14:35:35
 * @LastEditTime: 2022-02-15 14:08:10
 * @LastEditors: qxy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\talent-recruitment\TalentRecruitment.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './talent-recruitment.less';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Item from '../components/item/Item'
import Item2 from '../components/item/Item2'
import Axios from 'axios';

//网页第一个模块
class FirstPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            careers: { "code": 0, "msg": "操作成功", "count": 1, "data": [] },
            arr: []
        }
    }


    componentDidMount() {
        Axios.get(global.config.serverUrl+'/JobsInfo/LoadData/?page=1&limit=100&key=')
        .then( (careers) => {
            console.log(careers.data);
            this.setState({ careers: careers.data })
        })
        .catch( (error) => {
            console.log(error);
        });
    }
    render() {
        var careerListSchool = []
        var careerListSocial = []
        let schoolNum = 0;
        let socialNum = 0;
        for (let i = 0; i < this.state.careers.data.length; i++) {
            console.log(this.state.careers.data[i].JobsType)
            //如果isuseful字段为1就加入列表
            if (this.state.careers.data[i].IsUseful === 1) {
                //判断是否为社招
                if (this.state.careers.data[i].JobsType === 1) {
                    if (schoolNum % 2 == 0) {
                        careerListSocial.push(
                            <div key={i}>
                                <Item name={this.state.careers.data[i].JobsName} category={this.state.careers.data[i].JobsKeyWord}
                                    adress={this.state.careers.data[i].JobsAddress} num={this.state.careers.data[i].JobsNum} 
                                    time={this.state.careers.data[i].PublishDateStr.substring(0, 10)} click={this.props.handleClick(this.state.careers.data[i].Id)}/>
                            </div>
                        )
                        schoolNum = schoolNum + 1;
                    }
                    else {
                        careerListSocial.push(
                            <div key={i + "1"} className="career-other-color">
                                <Item name={this.state.careers.data[i].JobsName} category={this.state.careers.data[i].JobsKeyWord}
                                    adress={this.state.careers.data[i].JobsAddress} num={this.state.careers.data[i].JobsNum}
                                     time={this.state.careers.data[i].PublishDateStr.substring(0, 10)} click={this.props.handleClick(this.state.careers.data[i].Id)}/>
                            </div>
                        )
                        schoolNum = schoolNum + 1;
                    }
                }
                else {
                    if (socialNum % 2 == 0) {
                        careerListSchool.push(
                            <div key={i}>
                                <Item2 name={this.state.careers.data[i].JobsName} category={this.state.careers.data[i].JobsKeyWord}
                                    adress={this.state.careers.data[i].JobsAddress} num={this.state.careers.data[i].JobsNum} 
                                    time={this.state.careers.data[i].PublishDateStr.substring(0, 10)} click={this.props.handleClick(this.state.careers.data[i].Id)}/>
                            </div>
                        )
                        socialNum = socialNum + 1;
                    }
                    else {
                        careerListSchool.push(
                            <div key={i + "1"} className="career-other-color">
                                <Item2 name={this.state.careers.data[i].JobsName} category={this.state.careers.data[i].JobsKeyWord}
                                    adress={this.state.careers.data[i].JobsAddress} num={this.state.careers.data[i].JobsNum} 
                                    time={this.state.careers.data[i].PublishDateStr.substring(0, 10)} click={this.props.handleClick(this.state.careers.data[i].Id)}/>
                            </div>
                        )
                        socialNum = socialNum + 1;
                    }
                }
            }
        }
        return (
            <div className="first-page">
                <div className="first-page-title">欢迎来到新中大</div>
                <div className="first-page-text">
                    只要敢想，没有什么做不了！ 我们的成长邀请你一同参与！
                </div>

                <div className="career-list">
                    <div className="career-list-head-1">
                        <div className="career-list-head-icon">
                            <img src={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/%E7%A4%BE%E6%8B%9B.png"}></img>
                        </div>
                        <div className="career-list-head-title-1">2022 社会招聘岗位</div>
                        <div className="career-list-head-title-2">Social recruitment post</div>
                    </div>
                    <div className="career-list-title">
                        <div className="career-list-title-name">职位名称</div>
                        <div className="career-list-title-category">职位类别</div>
                        <div className="career-list-title-adress">工作地点</div>
                        <div className="career-list-title-num">招聘人数</div>
                        <div className="career-list-title-time">更新时间</div>
                        <div className="career-list-title-more">详情</div>
                    </div>
                    {careerListSocial}
                </div>
                <div className="interval-57" />
                <div className="career-list">
                    <div className="career-list-head">
                        <div className="career-list-head-icon">
                            <img src={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/%E6%A0%A1%E6%8B%9B.png"}></img>
                        </div>
                        <div className="career-list-head-title-1">2022 校园招聘岗位</div>
                        <div className="career-list-head-title-2">Campus recruitment post</div>
                    </div>
                    <div className="career-list-title">
                        <div className="career-list-title-name">职位名称</div>
                        <div className="career-list-title-category">职位类别</div>
                        <div className="career-list-title-adress">工作地点</div>
                        <div className="career-list-title-num">招聘人数</div>
                        <div className="career-list-title-time">更新时间</div>
                        <div className="career-list-title-more">详情</div>
                    </div>
                    {careerListSchool}
                </div>
            </div>
        );
    }
}

//网页主模块
class JoinNG extends React.Component {
    //跳转传参
    handleClick(address, myid) {
        this.props.history.push(address + "/" + myid);
    }

    render() {
        return (
            <div>
                <Head />
                <div className="talent-recruitment">
                    <FirstPage handleClick={(myid) => this.handleClick.bind(this,"/jobDetails", myid)}/>
                    <div className="interval-80" />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default JoinNG;