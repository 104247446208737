import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/16/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E4%BC%81%E4%B8%9A%E7%BA%A7%E5%8A%B3%E5%8A%A1%E5%AE%9E%E5%90%8D%E5%88%B6%E7%94%A8%E5%B7%A5%E7%AE%A1%E7%90%86/banner.png`,
    describe: "基于建筑企业满足国家住建部以及各地各级政府对全国建筑工人实名制管理要求，新中大推出企业级劳务实名制管理解决方案，方案利用信息化、数字化、物联网等手段，从集团企业级角度出发，基于移动应用，完成劳务实名制采集进场、安全教育、实名制考勤、工资结算发放、不良记录管理、施工任务管理、劳务工评价、劳务工退场等现场人员全过程闭环管理，从而达到规范劳务管理行为，提高管理效率，降低企业风险的目的。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "企业级劳务实名制管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '企业级管理 规范项目标准'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '移动化应用 提高使用效率'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '物联网开放 降低硬件成本'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '离线端应用 规避网络缺陷'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '政府端对接 应对上级监管'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '多应用场景 满足行业需求'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        describe: '微信小程序 内外协同管理'
      },
      {
        key: 7,
        iconPosition: { x: 7, y: 0 },
        describe: '多数据看板 辅助领导决策'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        // title: '规范流程，提升效率',
        describe: '企业级统一监管，标准化高融合。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        // title: '知识积累与共享',
        describe: '现场劳务用工全过程监管。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        // title: '降低管理成本',
        describe: '全面移动化，更适应工地应用。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        // title: '提升服务质量',
        describe: '多应用模式，满足不同行业需求。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        // title: '降低管理成本',
        describe: '内外协同，提高现场管理效率。'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 1 },
        // title: '提升服务质量',
        describe: '企业级、项目级两级指挥中心。'
      }
    ]
  },
  sessionE: {
    title: '中启胶建集团有限公司',
    describe:`中启胶建集团有限公司始建于1949年，1999年改制为股份制公司，是建筑工程施工总承包特级资质企业，具有市政公用工程施工总承包一级资质和钢结构、地基基础、电子与智能化、建筑装饰装修、建筑机电安装、消防安装、起重设备安装、建筑幕墙等工程施工专业承包资质以及对外承包工程和劳务合作经营权。公司下辖十个建筑分公司及安装、市政、装饰、钢结构、商品混凝土等专业公司。市场范围遍及山东省内各地市和北京、上海、天津、江苏、安徽、宁夏、内蒙古、辽宁、吉林、贵州、湖北、湖南、新疆、福建等全国大部分省、直辖市、自治区以及柬埔寨、澳大利亚等国家和地区。`, 
    backgroundImage: `url(${baseUrl}anli.png)`
  },
  sessionF: {
    src: global.config.url + '/NewGrand/media/realname.3686888d.mp4',
  },
}