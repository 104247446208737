/*
 * @Author: your name
 * @Date: 2019-12-12 10:45:33
 * @LastEditTime : 2020-01-09 13:56:19
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\components\CardInPrint.js
 */
import React from 'react';
//import './index.css';
import './components.less'


/**
 * @description 卡片组件 用于应用价值的卡片展示，360*200的比例，有底色，70x70的图（居中） 下方一行简介文字
 *              需要参数：图片url<>, 文字文本 <text>
 * @author chenyutan
 * @date 2019-12-12
 * @class Card
 */

class Card extends React.Component {
    render() {
        return (
            <div className="digital-site">
                <div className="card-print">
                    <div className="interval-45"></div>
                    <div className="card-print-img">
                        <img alt="" src={this.props.img}></img>
                    </div>
                    <div className="card-print-text">{this.props.text}</div>
                </div>
            </div>

        );
    }
}

export default Card;