import "./index.less";
import React from "react";
import { useEffect, useRef, useState } from "react";
import Animation from "../../../../../transition/animation/animation";

export default (props) => {
  const state = props;
  const ref = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    let animationFinash = false;
    let animationFn = () => {
      if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
        console.log("触发滚动");
        setStartAnimation(true);
        animationFinash = true;
      }
    };
    console.log("animationFinash :", animationFinash);
    animationFn();
    window.$(window).scroll(animationFn);
    return () => {
      window.$(window).unbind("scroll", animationFn);
    };
  }, []);

  return (
    <div className="new-icon-title-text-item" ref={ref}>
      <div className="icon-container">
        <div className={`icon moveup20-initial opacity-initial${startAnimation ? ` moveup20` : ``}`} style={{ ...state.iconStyle }}>
          <img src={props.img} alt="img" />
        </div>

        <div className={`title moveup20-initial opacity-initial${startAnimation ? ` moveup20` : ``}`}>
          {state.title}
        </div>
      </div>

      <div className={`text moveup20-initial opacity-initial${startAnimation ? ` moveup20` : ``}`}>
        <span>{state.describe}</span>
      </div>
    </div>
  );
};
