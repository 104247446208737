/*
 * @Author: your name
 * @Date: 2019-12-09 16:04:23
 * @LastEditTime : 2019-12-20 16:48:05
 * @LastEditors  : Please set LastEditors
 * @Description: 水平居中粗体字标题，自带80px（自适应）的上边框
 * @FilePath: \my-demo\src\pages\products\components\Title.js
 */
import React from 'react';
//import './index.css';
import './title.less'


/**
 * @author chenyutan
 * @date 2019-12-06
 * @class Card
 * @description 粗体标题组件，需要提供参数 标题的文本<text> 
 */
class Title extends React.Component {
    render() {
        return (
            <div className="common">
                <div className="title">{this.props.text}</div>
            </div>
        );
    }
}

export default Title;