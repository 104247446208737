/*
 * @Author: your name
 * @Date: 2019-12-12 09:40:26
 * @LastEditTime : 2020-01-09 13:13:39
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\machinery-management\MachineryManagement.js
 */

import React from 'react';
//import './index.css';
import '../../../global';
import './c-office.less';
import ani from '../../../transition/animation/animation';
//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../../digitalSite/components/TitleSize40Center'
import Describe from '../../digitalSite/components/Describe'
import Card from '../../ProjectConstruction/components/card/Card'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"协同办公解决方案"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案介绍"} />
                <Describe text={"协同软件的核心应用是围绕企业提高办公效率、实现信息共享、降低管理成本这条主线，将集团企业内部单位日常的办公及信息门户、行政审批及业务流程、知识及资源共享和档案等通过统一信息协同平台实现统一管理。<br/>"+
                                "新中大利用自主开发的协同办公系统通过上万家集团企业的应用实践，总结提出了集团企业协同办公信息化应用解决方案，方案以统一平台、便捷办公为基本特点，以灵动工作流为核心技术，涵盖了信息门户、企业信息发布、公文管理、流程审批、内部协同、个人办公、企业资源、企业知识、档案管理、流程监控、业务应用整合等企业办公事务所需的各项功能。系统还具有非常灵活的系统扩展能力，可根据企业用户自身业务角色的需要与部门的工作流程，灵活添加办公功能或定制。同时系统集成了包括电子邮件、即时通讯、手机等经济实用的通讯工具，使之与软件功能融为一体，为企业用户构建一个随时随地、移动办公处理平台，并面向分布环境，提供了良好的网络办公工作环境，对于企业各层次人员提供简单实用、应用方便的人性化操作模式，通过WEB浏览器可以完成系统全部应用功能的操作，帮助企业真正实现办公信息化、自动化、无纸化管理。"} />
                <div className="interval-50"></div>
                <div className="second-box">
                    <div className="box-img">
                        <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/7-1.png"}></img>
                    </div>
                    <div className="interval-col-30"></div>
                    <div className="box-img">
                        <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/7-2.png"}></img>
                    </div>
                </div>
                <div className="interval-154"></div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"客户需求"} />
                <div className="second-page-col-first">
                    <div className="interval-col-194"></div>
                    <div className="left">
                        <div className="second-page-1-2">
                            随着企业经营规模的逐渐扩大，企业从相对集中的管理向松散型协同管理转变，过去完成集中在一个办公环境下的办公方式，已经变为跨地区、跨组织、跨部门、跨时区、跨网络的团队协作模式，由于空间和地理环境分散，存在沟通障碍、协同工作困难，导致信息孤岛，缺乏统一的工作协同平台，企业间的行政事务往来需要耗费较多的时间和交通及通讯成本。
                        </div>
                    </div>
                    <div className="interval-col-108"></div>
                    <div className="line-col-1"></div>
                    <div className="interval-col-104"></div>
                    <div className="right">
                        <div className="second-page-1-2">
                            表格、文档、制度、经验等散落在各部门个人的办公电脑里、人脑里，需要时花费大量的精力、财力去获取，有效的知识文档未沉淀，人走电脑坏，知识随之而失，大量有价值的知识性文档成为库存，不能彻底有效共享，缺乏一个信息共享的知识平台。
                        </div>
                    </div>
                </div>
                <div className="second-page-col-second">
                    <div className="line-row-1"></div>
                    <div className="line-row-2"></div>
                </div>
                <div className="second-page-col-third">
                    <div className="interval-col-194"></div>
                    <div className="left">
                        <div className="second-page-2-2">
                            企业制度规章一大堆，但是却两层皮管理，制度流程一套体系，实际管理流程一套体系，制度都是文字性描述，员工对制度理解不清晰，缺乏基础的流程执行工具把制度转换成图形化，让员工清晰了解制度流程说明。
                        </div>
                    </div>
                    <div className="interval-col-108"></div>
                    <div className="line-col-2"></div>
                    <div className="interval-col-104"></div>
                    <div className="right">
                        <div className="second-page-2-2">
                            集团企业管理过程中大多数事务性工作都在做审批工作，但由于领导因为工作需要经常，领导无法随时随地办公审批，随时掌握企业的情况，出差数天工作即堆积如山。
                        </div>
                    </div>
                </div>
                <div className="interval-31"></div>
            </div>
        );
    }
}

//网页第四个模块
class FourthPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案价值"} />
                <Describe text={"　新中大秉承上万家集团企业的应用实践，推出与业务系统紧密整合应用的协同办公平台，构建企业人机协同、流程协同、数据协同、业务协同、团队协同多组织、多层次的协同应用模式，为企业提供了多方面的应用价值，包括："} />
                <div className="interval-80" />
                <div className="show-list">
                    <table >
                        <tbody>
                            <tr>
                                <td><Card title={"互联互通、共享共治"} text={"通过构建企业统一协同工作平台，提升企业内相关单位之间协同工作能力，提高集团内信息沟通、办公事务的效率，加强企业内部的团队工作意识。"} /></td>
                                <td><Card title={"制度流程化、流程信息化"} text={"工作流引擎从流程的注册、设计、授权、运用、监控，动态的反映了一个完整的业务处理流程企业日常管理中，电子流程实现了可跨组织、可跨部门审批，实现员工过程可视化、流程清晰化、审批快捷化，有效缩短审批决策周期，提高审批工作效率。"} /></td>
                                <td><Card title={"随时随地，移动办公"} text={"系统集成手机APP、邮件、即时通讯、企业微信等多种主流移动通讯工具，将企业办公与通讯工具融为一体，解决企业层随时随地移动式办公，在手机、ipad等移动设备上即可实现企业办公。"} /></td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td><Card title={"知识管理、创造价值"} text={"通过构建企业级的知识管理平台，将人与知识紧密结合，构建出企业内部完善的知识评价体系，将我们企业内部员工大脑中及管理过程形成的档案知识通过沉淀转变为企业资产，对知识进行有效传播，有效将企业知识与企业战略结合，提高企业的学习能力。"} /></td>
                                <td><Card title={"企业资源、合理调配、降低企业管理成本"} text={"有效管理各种行政资源，例如会议、车辆、办公用品、企业证书、企业印章等，企业管理这些行政资源简单而又繁琐，什么时间资源可以使用、使用的流程如何、被谁在使用都成为企业事务性繁琐的日常办公，通过协同办公软件规范、集中企业资源管理，提升员工的节约意识，降低企业经营成本，减少内部使用的管理冲突等一系列资源调配问题。"} /></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </ div>
                <div className="interval-154"></div>
            </div>
        );
    }
}


//网页主模块
class COffice extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("fifth-page-card", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");

        ani.initclass("sixth-page-card", "moveright100-center-init")
        ani.initclass("sixth-page-card-textcard", "moveleft100-center-init")
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("fifth-page-card", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");

        ani.getClassHight("sixth-page-card", 20, "moveright100-center-notime");
        ani.getClassHight("sixth-page-card-textcard", 20, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="c-office">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                    <FourthPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default COffice;