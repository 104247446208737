/*
 * @Author: your name
 * @Date: 2019-12-16 15:56:04
 * @LastEditTime : 2020-01-02 08:58:47
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\components\card\Card.js
 */

import React from 'react';
//import './index.css';
import './card.less'

class Card extends React.Component {
    render() {
        return (
            <div className="company-introduction">
                <div className="card">
                    <div className="card-img">
                        <img alt="" src={this.props.img}></img>
                    </div>
                    <div className="card-title">{this.props.title}</div>
                    <div className="card-text">{this.props.text}</div>

                </div>
            </div>

        );
    }
}

export default Card;