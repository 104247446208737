/*
 * @Author: your name
 * @Date: 2019-12-06 11:07:47
 * @LastEditTime: 2022-04-19 13:32:18
 * @LastEditors: qxy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\products\components\BottomBar.js
 */
import React from "react";
//import './index.css';
import "./components.less";
import { Link } from "react-router-dom";

const bottomConfig = [
  {
    title: "加入我们",
    items: [
      {
        to: "/joinNG",
        innerText: "加入新中大",
      },
      {
        to: "/careerDevelopment",
        innerText: "职业发展",
      },
      // {
      //     to: '/employeeLife',
      //     innerText: '员工生活'
      // },
      {
        to: "/talentRecruitment",
        innerText: "人才招聘",
      },
    ],
  },
  {
    title: "伙伴加盟",
    items: [
      {
        to: "/cooperationGuide",
        innerText: "合作指南",
      },
      // {
      //   to: "/joinApply",
      //   innerText: "加盟申请",
      // },
      // {
      //   to: "/",
      //   innerText: "合作申请",
      // },
    ],
  },
  {
    title: "战略合作",
    items: [
      {
        to: "/more",
        innerText: "浙大管理学院",
      },
      {
        to: "/more",
        innerText: "鲁班软件",
      },
      {
        to: "/more",
        innerText: "海康威视",
      },
      {
        to: "/more",
        innerText: "更多",
      },
    ],
  },
  // {
  //     title: '子公司',
  //     items: [
  //         {
  //             to: 'http://www.gdataware.cn',
  //             innerText: '政云数据'
  //         },
  //     ]
  // },
];

/**
 * @author chenyutan
 * @date 2019-12-06
 * @class Bottom
 * @description BottomBar组件用于网站底栏，不带任何参数直接使用在网页最后加入底部导航栏，
 *                   直接用组件名 Bottom
 */

class Bottom extends React.Component {
  render() {
    return (
      <div className="common">
        <div className="bottombar">
          <div className="bottom">
            {/* <div className="bottom-next">
              <BottomLine />
              <div style={{ width: "calc(450vw/14.4)" }} className="bottom-qrcode-container">
                <div className="bottom-qrcode">
                  <div>新中大服务号</div>
                </div>
                <div className="bottom-qrcode">
                  <div>建闻订阅号</div>
                </div>
              </div>
            </div> */}
            <div className="bottom-end">
              <a href="https://beian.miit.gov.cn/">
                <div className="bottom-end-text-first">杭州浩联智能科技有限公司　　浙ICP备2022010038号</div>
              </a>
              <div className="bottom-end-icon"></div>
              <a href="http://www.beian.gov.cn/portal/registerSystemInfo?spm=a3c00.7621333.7y9jhqsfz.111.6b92wDeHwDeHnN">
                <div className="bottom-end-text-second">公安备案号　　33010502007019</div>
              </a>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

class BottomLine extends React.Component {
  render() {
    return (
      <div className="bottom-table">
        {bottomConfig.map((ele, index) => {
          return (
            <div key={index} className="bottom-col">
              <div className="bottom-col-title">{ele.title}</div>
              {ele.items.map((el, i) => {
                return (
                  <div className="bottom-cell" key={i}>
                    {el.to.startsWith("http") ? <a href={el.to}>{el.innerText}</a> : <Link to={el.to}>{el.innerText}</Link>}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

export default Bottom;
