import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/13/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%9C%8D%E5%8A%A1/%E5%85%A8%E8%BF%87%E7%A8%8B%E5%B7%A5%E7%A8%8B%E5%92%A8%E8%AF%A2%E6%9C%8D%E5%8A%A1%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/banner.png`,
    describe: "全过程工程咨询服务管理解决方案面向全过程工程咨询各参与方，汇聚集团管理和项目全过程管控于一身，方案以合同管理为入口，以进度管理为主线，以成本管理为核心，围绕企业经营管理和项目管控两个层面，助推市场经营、项目管控、资源管理、客户服务、知识管理的数字化、网络化、集成化管理实现。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML:'项目服务之<br>全过程工程咨询服务管理解决方案',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '项目一体化管理'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '灵活配置满足行业个性化需求'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '适用的工作流引擎'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '移动应用及微信集成'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '参建方信息实时共享'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '万向接口，系统集成'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        title: '实现知识和信息共享',
        describe: '协同工作平台将企业现有的知识和信息集成在一起，通过信息门户，根据每个用户的要求定制出个性化的信息和应用，使用户可以方便地获取相关的知识和应用。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        title: '基于工作流管理的应用集成',
        describe: '工作流管理是新中大协同工作平台的核心，也是实现协同工作平台与ERP业务系统集成的重要途径。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        title: '个性化的工作空间',
        describe: '将监理行业的应用和数据集成到一个信息管理平台之上，使监理行业可以快速地建立个性化应用。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        title: '联盟体事务处理平台',
        describe: '新中大平台涉及监理行业事务处理的各个方面的功能，包括市场营销、销售、服务、人力资源、办公管理、工作流程、项目管理、培训管理、接待管理、会议管理等工作领域。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        title: '灵动企业，移动商务',
        describe: '本解决方案系统支持移动计算，提供基于GPRS、CDMA技术的网络计算功能。用户可以通过各种移动设备实现随时、随地访问本管理系统，进行工作任务的指派、各类企业信息的访问等，实现移动办公并且打破监理行业传统的纸质办公。'
      }
    ]
  },
}