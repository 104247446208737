/*
 * @Author: your name
 * @Date: 2019-12-16 11:14:58
 * @LastEditTime : 2019-12-23 09:59:53
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\partner\components\describe\Describe.js
 */

import React from 'react';
//import './index.css';
import './describe.less'


/**
 * @description  宽度1000px的一段非粗体描述文本 居中
 *              需提供参数: 描述的文本 <text>
 * @author chenyutan
 * @date 2019-12-11
 * @class Describe
 */
class Describe extends React.Component {
    render() {
        return (
            <div className="partner">
                <div className="describe" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
            </div>
        );
    }
}

export default Describe;