import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/1d/`;
export default {
  sessionA: {
    describe: '以集团财务、税务、资金等系统为基础，通过数字化、影像化的票据处理系统，提供共享任务处理平台，建立财税共享服务八大共享中心：费控共享、应收共享、应付共享、资金共享、税务共享、记账共享、资产共享、HR共享。'
  },
  sessionB: {
    rowCount: 1,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '共享任务平台'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '强大影像采集、处理能力'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '八大共享中心'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },
  sessionC: {
    imgSrc:`${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount:2,
    rowCount:3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 3, y: 0 },
        title: '推动财务管理转型',
        describe: '共享服务中心将企业各个单位的后台功能集中在一起，使各领域的专家在一起共事，直接交流。'
      },
      {
        key: 2,
        iconPosition: { x: 4, y: 0 },
        title: '加强集团管控',
        describe: '共享服务使各业务单元更专注于核心业务，且提供了一个标准的工作程序，实现业务标准化。'
      },
      {
        key: 3,
        iconPosition: { x: 5, y: 0 },
        title: '提高效率和服务质量',
        describe: '通过集约化把复杂的工作变得更简单、更标准、分工更细，把传统的会计记账变成了“会计工厂”的运营 。'
      },
      {
        key: 4,
        iconPosition: { x: 6, y: 0 },
        title: '有效保证业务发展',
        describe: '业务单位将非核心业务（后台业务）交由共享服务中心来运作，而共享服务中心又能提供足够的后台支持，使各业务单位可以将自己的重心完全放在它们的核心业务和顾客上。'
      },
      {
        key: 5,
        iconPosition: { x: 7, y: 0 },
        title: '降低成本',
        describe: '共享服务对成本降低、效率提升及整体业绩改善有积极的影响，业务量不增加的情况下人员减少；业务量增加而人员不增加。'
      },
      {
        key: 6,
        iconPosition: { x: 8, y: 0 },
        title: '加速标准化进程',
        describe: '共享服务中心为工作流程的标准化以及管理数据的统一提供平台。'
      }
    ]
  },
}