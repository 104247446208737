import './index.less';
import React, { useEffect, useRef, useState } from 'react';
import Animation from '../../transition/animation/animation';

export default (props) => {
  let state = props;
  let [startAnimation, setStartAnimation] = useState(false);

  // 这里必须声明 textInput，这样 ref 才可以引用它
  const ref = useRef(null);

  useEffect(() => {
    let animationFinash = false;
    let animationFn = () => {
      if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
        setStartAnimation(true);
        animationFinash = true;
      };
    }
    animationFn();
    window.$(window).scroll(animationFn);
    return () => {
      window.$(window).unbind('scroll', animationFn);
    }
  })

  return (
    <div className="abstract" style={{ background: state.background }} ref={ref}>

      {/* 左侧文本区域 */}
      <div className={startAnimation ? "text moveup20-initial opacity-initial moveup20" : "text moveup20-initial opacity-initial"} >
        <div className="text-container">
          <div className="title">
            {state.dangerouslySetInnerHTML ? <span dangerouslySetInnerHTML={{ __html: state.dangerouslySetInnerHTML }}></span> : <span >{state.title}</span>}
          </div>

          <div className="body" id="" dangerouslySetInnerHTML={{ __html: state.describe }}></div>
          {
            state.describeImg && <img alt="" style={state.desImgStyle}
              src={state.describeImg || (global.config.url + "/newgrand/picture/img/%E4%BA%A7%E5%93%81/se/banner-6.png")}>
            </img>
          }
          {
            state.hiddenBtn === true ? '' : <a href="http://newgrand.mikecrm.com/uFFgAoS" target="_blank" rel="noopener noreferrer">
              <div className="applyBtn">
                <span>申请体验使用</span>
              </div>
            </a>
          }
        </div>
      </div>

      {/* 右侧图片区域 */}
      <div className={startAnimation ? "img moveleft100-init moveleft100" : "img moveleft100-init"} >
        <img alt="" style={state.imgStyle}
          src={state.imgSrc || (global.config.url + "/newgrand/picture/img/%E4%BA%A7%E5%93%81/se/banner-6.png")}>
        </img>
      </div>

    </div>
  )
}