import React from 'react';
import { contentInfo, contentList } from './date.js';
import './index.less';

const PrivacyPolicyApp = () => {
  return <div className="privacy">
  <main className='content'>
    <h3>{contentInfo[0].title}</h3>
    <p>{contentInfo[0].update}</p>
    {contentList[0]}
  </main>
</div>;
}

export default PrivacyPolicyApp;