/*
 * @Author: your name
 * @Date: 2020-01-13 11:34:57
 * @LastEditTime : 2020-01-13 13:12:32
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\components\item\Item2.js
 */
import React from 'react';
//import './index.css';
import './item.less'

class Item2 extends React.Component {

    render() {
        return (
            <div className="join-us">
                <div className="career">
                    <div className="career-name" dangerouslySetInnerHTML={{ __html: this.props.name }}></div>
                    <div className="career-category" dangerouslySetInnerHTML={{ __html: this.props.category }}></div>
                    <div className="career-adress" dangerouslySetInnerHTML={{ __html: this.props.adress }}></div>
                    <div className="career-num" dangerouslySetInnerHTML={{ __html: this.props.num }}></div>
                    <div className="career-time" dangerouslySetInnerHTML={{ __html: this.props.time }}></div>
                    <div className="career-more">
                        <div className="career-botton bg-blue" onClick={this.props.click}>查看详情</div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Item2;