/*
 * @Author: your name
 * @Date: 2019-12-27 11:31:58
 * @LastEditTime : 2020-01-14 15:03:09
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\ungrouped\search\Search.js
 */
import React from "react";
import "./search.less";

//引入prefeb模块
import Bottom from "../../../common-components/BottomBar";
import Head from "../../../common-components/TopBar/TopBar";
import Axios from "axios";

//网页主模块
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 0, //控制搜索的总数
      nowlocation: 1, //控制当前页码
      searchs: [], //展示给用户的搜索内容
      allsearchs: [], //全部搜索的内容
      findsearchs: [], //含有关键词的内容
      nowtype: "all", //当前选中的类别
      allInfo: [],
      caseInfo: 0,
      newsInfo: 0,
      jobsInfo: 0,
      groupInfo: 0
    };
  }

  deepClone(obj) {
    var _obj = JSON.stringify(obj),
      objClone = JSON.parse(_obj);
    return objClone;
  }

  //业内的搜索按钮
  searchInpage() {
    //深拷贝一个副本
    let nowinput = document.getElementById("input").value;
    let cloneSearchs = this.deepClone(this.state.allsearchs);
    console.log("形参", cloneSearchs);
    console.log("实参", this.state.allsearchs);
    let findsearchs = this.findKeyWord(cloneSearchs, nowinput);

    let mycloneSearchs = this.deepClone(findsearchs);
    console.log("形参", cloneSearchs);
    console.log("实参", this.state.allsearchs);
    let searchs = this.findByType(mycloneSearchs, "all");

    this.setState({ findsearchs: searchs });
    this.setState({ searchs: searchs });
    let num = 0;
    num = num + searchs.data.caseInfo.length;
    num = num + searchs.data.newsInfo.length;
    num = num + searchs.data.jobsInfo.length;
    num = num + searchs.data.groupInfo.length;
    console.log(num);

    this.setState({
      num: num,
      caseInfo: searchs.data.caseInfo.length,
      newsInfo: searchs.data.newsInfo.length,
      jobsInfo: searchs.data.jobsInfo.length,
      groupInfo: searchs.data.groupInfo.length,
      nowtype: "all",
      nowlocation: 1
    });

    document
      .getElementById(this.state.nowtype)
      .classList.remove("category-active");
    document.getElementById("all").classList.add("category-active");
    this.setState({
      nowtype: "all"
    });
  }

  //跳转传参
  handleClick(address, myid) {
    this.props.history.push(address + "/" + myid);
  }
  //过滤关键字
  findKeyWord(searchs, keyword) {
    console.log("searchs", searchs.data.caseInfo.length);
    if (typeof keyword === "undefined" || keyword.length < 1) {
    } else {
      for (let i1 = searchs.data.caseInfo.length - 1; i1 >= 0; i1--) {
        //---紧急处理方法
        if (searchs.data.caseInfo[i1].Name === null) {
        } else {
          if (searchs.data.caseInfo[i1].Name.indexOf(keyword) === -1) {
            searchs.data.caseInfo.splice(i1, 1);
          }
        }
      }
      for (let i2 = searchs.data.newsInfo.length - 1; i2 >= 0; i2--) {
        //---紧急处理方法
        if (searchs.data.newsInfo[i2].Name === null) {
        } else {
          if (searchs.data.newsInfo[i2].Name.indexOf(keyword) === -1) {
            searchs.data.newsInfo.splice(i2, 1);
          }
        }
      }
      for (let i3 = searchs.data.jobsInfo.length - 1; i3 >= 0; i3--) {
        //---紧急处理方法
        if (searchs.data.jobsInfo[i3].Name === null) {
        } else {
          if (searchs.data.jobsInfo[i3].Name.indexOf(keyword) === -1) {
            searchs.data.jobsInfo.splice(i3, 1);
          }
        }
      }
      for (let i4 = searchs.data.groupInfo.length - 1; i4 >= 0; i4--) {
        //---紧急处理方法
        if (searchs.data.groupInfo[i4].Name === null) {
        } else {
          if (searchs.data.groupInfo[i4].Name.indexOf(keyword) === -1) {
            searchs.data.groupInfo.splice(i4, 1);
          }
        }
      }
    }

    return searchs;
  }

  //替换数据 ***△严重注意超级备用方案△*** 直接替换静态数据，有条件请及时更换
  changedata1(data) {
    data.push({
      Name: "i8工程项目管理平台",
      path: "/i8",
      Context:
        "新中大深耕工程建设行业近20年，不断整合行业专家资源，汇聚众多优秀建筑企业管理实践经验，5年磨一剑，打造出智慧建造4.0时代的战略产品i8，为移动互联时代建筑企业转型升级提供一站式信息化解决方案。"
    });
    data.push({
      Name: "i6企业管理软件",
      path: "/i6",
      Context:
        "新中大i6企业管理软件是基于联盟体资源计划（URP）理论和项目管理的思想，解决以价值链为驱动的联盟体资源优化配置的电子商务需求，涉及联盟体核心企业的集团化管理、供应链管理、生产管理、变型设计管理、合同管理、项目管理、成本管理、质量管理、企业绩效和员工绩效管理、人力资源管理、协同工作管理、知识库管理和商业智能等管理信息化应用系统，致力打造互联网时代品牌企业基于产业联盟的管理平台。"
    });
    data.push({
      Name: "i6s企业管理软件",
      path: "/i6s",
      Context:
        "新中大i6s企业级项目管理软件基于联盟体资源计划（URP）理论，融合了优秀的项目管理思想，并在软件与系统集成企业调研基础上研发而成，帮助项目服务型企业实现多组织多项目信息化管控。系统功能涵盖项目管理、客户关系管理、项目合同管理、协同办公管理、人力资源管理、集团财务管理、集团费控管理、企业资金管理、企业资产管理、项目供应链管理、项目成本管理、企业管控等。"
    });
    data.push({
      Name: "mi8工程项目管理云平台",
      path: "/mini",
      Context:
        "mi8工程项目管理云平台是一款针对成长型建筑企业量身打造的简单易用、快速部署的信息化普及产品，产品包括合同资金云、经济成本云、物资云、投标云、办公云、发票税务云、现场管理云6大解决方案，帮助成长型建筑企业提高企业运营能力，降低管理风险。"
    });
    data.push({
      Name: "A3企业管理软件",
      path: "/a3",
      Context:
        "新中大企业管理软件A3系统秉承了传统A3软件的功能强大、专业务实的特点，更吸收了数以万计国内外制造业客户的应用体验，并结合中国企业的管理特性的企业综合管理系统。"
    });
    data.push({
      Name: "GE企业管理软件",
      path: "/GE",
      Context:
        "新中大管理软件金色快车GE（Gold Express）：一款具备优秀管理思想，功能适用，实施便捷、简约型的优秀管理软件。产品针对以项目管理为主线的订单加工、配套生产、商贸服务等类型成长型企业的管理需求而研发，集财务、供应链、生产计划、物料计划、客户关系、协同办公等功能于一体，实现企业物流、资金流、信息流的有效协同。产品精心提炼了十多万家成长型企业的优秀管理应用经验，并蕴含新中大软件十七年来的管理软件行业实践经验，功能强大、维护简单、操作便捷，"
    });
    data.push({
      Name: "SE+企业管理软件",
      path: "se",
      Context:
        "新中大银色快车SE+(Sliver Express)是以管理会计理论和集团财务管理实务为基础，以功能完善、操作方便、快速见效为指导原则，面向广大小企业的财务应用系统，帮助企业实现从基本核算到集团财务的信息化管理。SE+产品是在新中大财务管理软件NGPower产品的基础之上发展而成的一个升级替代产品，是在近十万各行各业用户的成功应用基础上，继承了新中大财务管理软件NGPower的优秀品质，充分吸取了新中大在管理软件领域的成功经验而逐渐形成的成熟产品..."
    });
    data.push({
      Name: "供应商门户UIC",
      path: "/UIC",
      Context:
        "新中大供应商门户软件UIC是一款基于URP思想，实现经济资源联盟体内盟主、成员、顾客及员工进行数据交换、信息交流、应用共享、服务实现、互动管理的门户系统。系统以互动管理为核心思想，提供各门户信息出入口，将联盟体内目标用户各种复杂信息分类、分析并优化，用户能动态地发布、存储其内部和外部的各种信息，实现信息和知识的收集、分析、增值和共享，依靠适用的系统报表和自定义报表，为各用户提供各种决策支持..."
    });
    data.push({
      Name: "柔性化统一平台UP",
      path: "/UP",
      Context:
        "新中大柔性统一平台SUP，提供了完整的Service管理功能——单入口的Web Service部署和调用机制。在平台中部署了大量跨产品、跨模块的公共服务，可变流程节点服务，实现了系统在应用层面的有效集成，而不仅仅是数据层面的集成，并且可以任意集成组合。"
    });
  }

  //替换方案数据 ***△严重注意超级备用方案△*** 直接替换静态数据，有条件请及时更换
  changedata2(data) {
    data.push({
      Name: "集团财务",
      path: "/groupFinance",
      Context:
        "作为企业集团管控核心的财务管理，更需要运用数字化技术更好地革新传统的财务流程，新中大集团财务管理解决方案功能涵盖了集团财务核算、 集团资金管理、费控管理、财务共享、报表管理等，实现对企业集团“关键经营、重点风险”的整体管控。"
    });
    data.push({ Name: "集团人力资源", path: "/humanResources", Context: " " });
    data.push({ Name: "协同办公", path: "/cooperativeOffice", Context: " " });
    data.push({ Name: "税务管理", path: "/taxAdministration", Context: " " });
    data.push({
      Name: "工程投资企业信息化解决方案",
      path: "/projectInvestment",
      Context:
        "方案总结新中大20余年工程项目管理信息化建设经验，融会西方项目管理思想，结合中国工程项目管理实务，满足了现阶段工程投资企业的项目管理需求。以规范工程投资的核心业务项目管理为基础，紧紧抓住成本、资金二条主线，监控工程投资型企业多项目运作，建立多项目管理、多分支机构、业务一体化的集团项目管理平台。"
    });
    data.push({
      Name: "大型工程投资项目信息化解决方案",
      path: "/largeProject",
      Context:
        "大型工程投资项目管理解决方案主要服务于企业对大型投资项目管控的需要，是为了能更科学，更有效对投资较大的大型工程项目各个环节进行实时管控，为公司决策层、经营管理层、项目执行层提供科学的决策依据，通过信息共享和统筹管理，保证项目工作顺利开展，通过过程管理降低项目投资，提高项目效益。"
    });
    data.push({
      Name: "工程总承包企业信息化解决方案",
      path: "/projectContracting",
      Context:
        "工程总承包企业信息化解决方案为企业构建从经营开发，承接项目、项目规划、项目实施到项目竣工移交全过程的一个大型信息平台，系统涵盖综合项目管理、人力资源管理、企业资产管理、集团财务管理、协同办公管理以及企业绩效管理等解决方案，实现总承包各个环节的信息化支撑,以及项目相关的数据、信息的录入和收集，同时实现工作环节之间的数据共享，避免重复劳动和手工数据传递，提高工作效率和管理水平。"
    });
    data.push({
      Name: "施工总承包企业信息化解决方案",
      path: "/constructionContract",
      Context:
        "施工总承包企业信息化解决方案整体思路以“智慧建造4.0”为中心，以企业级融合与项目部融合为目标，将项目部与集团充分融合到一起，实现数据共享。方案以进度、成本、资金、综合四条主线为核心进行模块设计及逻辑关系搭建，突出管理重点，抓住管理核心，保证应用尽快见效。"
    });
    data.push({
      Name: "厂房新建（搬迁）信息化解决方案",
      path: "/workShop",
      Context:
        "新中大厂房新建（搬迁）信息化解决方案目的是为了能更科学，更有效的进行项目过程管控，对不断增多的新建厂房项目的各个管控环节进行实时控制，为公司决策层、经营管理层、项目执行层提供科学的决策依据，以及应对不断拓展的市场领域和不断增加的业务信息和业务量，提供一体化的管理平台支撑。"
    });
    data.push({
      Name: "项目制造信息化解决方案",
      path: "/projectManufacturing",
      Context:
        "方案致力解决以价值链为驱动的联盟体内外各种资源配置优化，实现一体化企业管理应用，方案应用涉及品牌企业供应链、生产计划、制造执行、客户关系、财务管理、人力资源、协同工作、知识管理、商务智能、企业门户等。"
    });
    data.push({
      Name: "律师行业解决方案",
      path: "/lawyerProfession",
      Context:
        "新中大i6s律师行业解决方案可快速建立基于SUP平台支持的企业级专业知识库平台。 它提供的大量专业化功能、多元化的方法、个性化的信息访问和报告以及行业指南， 快速搭建律师团队的协同工作平台，建立顺畅的沟通渠道。系统基于灵动技术的可扩展架构， 可实现综合案件管理、协同办公管理、人力资源管理、集团财务管理、知识文档管理、企业绩效管理管理。"
    });
    data.push({
      Name: "软件服务与系统集成行业解决方案",
      path: "/softwareService",
      Context:
        "新中大软件服务与系统集成行业解决方案，针对IT企业多项目管理面临的挑战， 通过企业级项目管控平台构建，实现大项目全程管控，实现项目管理和业务系统的一体化管控。"
    });
    data.push({
      Name: "全过程工程咨询服务解决方案",
      path: "/processConsulting",
      Context: " "
    });
    data.push({
      Name: "工地芯",
      path: "/siteCore",
      Context:
        "工地芯是基于新中大智慧建造4.0整体架构的数据远传终端新型产品。“工地芯”以万物互联为设计理念，连接施工现场各种设备PLC、OPC、变频器、仪表等，将数据经过计算、分析后通过以太网、4G/5G等方式，传输到企业级应用服务器中，实现数据监控、本地逻辑控制、边缘计算、远程升级、故障报警等功能。"
    });
    data.push({
      Name: "企业级劳务实名制用工管理",
      path: "/realNameLaborManager",
      Context: " "
    });
    data.push({ Name: "安全教育", path: "/safetyEducation", Context: " " });
    data.push({ Name: "物资管理", path: "/materialManagement", Context: " " });
    data.push({
      Name: "可视化工地管理",
      path: "/visualChemicalSiteManagement",
      Context: " "
    });
    data.push({ Name: "机械管理", path: "/machineryManagement", Context: " " });
    //data.push({Name: "智能印控", path: "/interlligentPrintingControl", Context: " "});
    data.push({
      Name: "专业承包企业",
      path: "/professionalContract",
      Context:
        "专业承包企业信息化解决方案的系统架构以合同管理为主线，成本资金管理为重点，通过投标管理、合同管理、进度管理、物资管理、成本管理、质量管理、安全管理、风险管理、竣工管理、资金管理等解决方案实现对专业承包项目的全过程管理。"
    });
    data.push({ Name: "专项解决方案", path: "", Context: "" });
  }
  //首次调用接收数据
  componentDidMount() {
    if (typeof this.props.history.location.state != "undefined") {
      console.log(this.props.history.location.state.id);
      var id = this.props.history.location.state.id;
    }else {
      var id = "";
    }
    document.getElementById("input").value = id;

    Axios.get(
      global.config.serverUrl + "/CaseInfo/LoadAll/?page=1&limit=100&key="
    )
      .then(res => {
        let allsearchs = res.data;
        console.log(allsearchs);
        allsearchs.data.groupInfo.splice(0, allsearchs.data.groupInfo.length);
        allsearchs.data.jobsInfo.splice(0, allsearchs.data.jobsInfo.length);
        this.changedata1(allsearchs.data.groupInfo);
        this.changedata2(allsearchs.data.jobsInfo);
        console.log(allsearchs.data);

        //处理结束  ** **
        this.setState({ allsearchs: allsearchs });
        var tempsearchs = JSON.parse(JSON.stringify(allsearchs));

        let searchs = this.findKeyWord(
          tempsearchs,
          this.props.location.state.id
        );
        this.setState({
          findsearchs: tempsearchs,
          searchs: searchs
        });
        var allnum = searchs.count;
        var num = 0;
        num = num + searchs.data.caseInfo.length;
        num = num + searchs.data.newsInfo.length;
        num = num + searchs.data.jobsInfo.length;
        num = num + searchs.data.groupInfo.length;
        this.setState({
          num: num,
          caseInfo: searchs.data.caseInfo.length,
          newsInfo: searchs.data.newsInfo.length,
          jobsInfo: searchs.data.jobsInfo.length,
          groupInfo: searchs.data.groupInfo.length
        });
      })
      .catch(error => {
        console.log(error);
      });
  }

  //过滤类别

  findByType(searchs, keytype) {
    if (keytype === "all") {
    } else {
      for (let i1 = searchs.data.caseInfo.length - 1; i1 >= 0; i1--) {
        if (keytype !== "case") {
          searchs.data.caseInfo.splice(i1, 1);
        }
      }
      for (let i2 = searchs.data.newsInfo.length - 1; i2 >= 0; i2--) {
        if (keytype !== "new") {
          searchs.data.newsInfo.splice(i2, 1);
        }
      }
      for (let i3 = searchs.data.jobsInfo.length - 1; i3 >= 0; i3--) {
        if (keytype !== "job") {
          searchs.data.jobsInfo.splice(i3, 1);
        }
      }
      for (let i4 = searchs.data.groupInfo.length - 1; i4 >= 0; i4--) {
        if (keytype !== "group") {
          searchs.data.groupInfo.splice(i4, 1);
        }
      }
    }
    return searchs;
  }
  //改变class
  changeActive(type) {
    document
      .getElementById(this.state.nowtype)
      .classList.remove("category-active");
    document.getElementById(type).classList.add("category-active");
    this.setState({
      nowtype: type,
      nowlocation: 1
    });

    //深拷贝一个副本
    let cloneSearchs = this.deepClone(this.state.findsearchs);
    console.log("形参", cloneSearchs);
    console.log("实参", this.state.allsearchs);
    let searchs = this.findByType(cloneSearchs, type);
    this.setState({ searchs: searchs });
    let num = 0;
    num = num + searchs.data.caseInfo.length;
    num = num + searchs.data.newsInfo.length;
    num = num + searchs.data.jobsInfo.length;
    num = num + searchs.data.groupInfo.length;
    console.log(num);

    this.setState({
      num: num,
      caseInfo: searchs.data.caseInfo.length,
      newsInfo: searchs.data.newsInfo.length,
      jobsInfo: searchs.data.jobsInfo.length,
      groupInfo: searchs.data.groupInfo.length
    });
  }

  SetNum(Tnum) {
    this.setState({
      num: Tnum
    });
  }

  SetNowlocation(Tnowlocation) {
    this.setState({
      nowlocation: Tnowlocation
    });
  }

  render() {
    const num = this.state.num;
    const nowlocation = this.state.nowlocation;
    let list = [];
    list.push(
      <div key={"head"} className="search-num" id="num">
        搜到 {num} 条结果
      </div>
    );
    console.log(this.state.caseInfo, this.state.caseInfo + this.state.newsInfo);
    for (
      let i = (nowlocation - 1) * 5;
      i <= (nowlocation - 1) * 5 + 4 && i < num;
      i++
    ) {
      if (i < this.state.caseInfo) {
        if (this.state.searchs.data.caseInfo[i].Context === null) {
          list.push(
            <div key={i}>
              <div
                className="search-title"
                onClick={this.handleClick.bind(
                  this,
                  "/caseDetails",
                  this.state.searchs.data.caseInfo[i].Id
                )}
              >
                {this.state.searchs.data.caseInfo[i].Name}
              </div>
              <div className="search-text">
                {this.state.searchs.data.caseInfo[i].Context}
              </div>
              <div className="search-time">2018-08-20</div>
              <div className="search-line"></div>
            </div>
          );
        } else {
          list.push(
            <div key={i}>
              <div
                className="search-title"
                onClick={this.handleClick.bind(
                  this,
                  "/caseDetails",
                  this.state.searchs.data.caseInfo[i].Id
                )}
              >
                {this.state.searchs.data.caseInfo[i].Name}
              </div>
              <div className="search-text">
                {this.state.searchs.data.caseInfo[i].Context.substring(0, 100)}
              </div>
              <div className="search-time">2018-08-20</div>
              <div className="search-line"></div>
            </div>
          );
        }
      } else if (
        i >= this.state.caseInfo &&
        i < this.state.caseInfo + this.state.newsInfo
      ) {
        if(this.state.searchs.data.newsInfo[
          i - this.state.caseInfo
        ].Context !== null)
        {
        list.push(
          <div key={i}>
            <div
              className="search-title"
              onClick={this.handleClick.bind(
                this,
                "/newsDetails",
                this.state.searchs.data.newsInfo[i - this.state.caseInfo].Id
              )}
            >
              {this.state.searchs.data.newsInfo[i - this.state.caseInfo].Name}
            </div>
            <div className="search-text">
              {this.state.searchs.data.newsInfo[
                i - this.state.caseInfo
              ].Context.substring(0, 100)}
            </div>
            <div className="search-time">2018-08-20</div>
            <div className="search-line"></div>
          </div>
        );
        }else {
          list.push(
            <div key={i}>
              <div
                className="search-title"
                onClick={this.handleClick.bind(
                  this,
                  "/newsDetails",
                  this.state.searchs.data.newsInfo[i - this.state.caseInfo].Id
                )}
              >
                {this.state.searchs.data.newsInfo[i - this.state.caseInfo].Name}
              </div>
              <div className="search-text">
                
              </div>
              <div className="search-time">2018-08-20</div>
              <div className="search-line"></div>
            </div>)
        }
      } else if (
        i >= this.state.caseInfo + this.state.newsInfo &&
        i < this.state.caseInfo + this.state.newsInfo + this.state.jobsInfo
      ) {
        list.push(
          <div key={i}>
            <div
              className="search-title"
              onClick={this.handleClick.bind(
                this,
                this.state.searchs.data.jobsInfo[
                  i - (this.state.caseInfo + this.state.newsInfo)
                ].path,
                0
              )}
            >
              {
                this.state.searchs.data.jobsInfo[
                  i - (this.state.caseInfo + this.state.newsInfo)
                ].Name
              }
            </div>
            <div className="search-text">
              {this.state.searchs.data.jobsInfo[
                i - (this.state.caseInfo + this.state.newsInfo)
              ].Context.substring(0, 100)}
            </div>
            <div className="search-time"></div>
            <div className="search-line"></div>
          </div>
        );
      } else if (
        i >= this.state.caseInfo + this.state.newsInfo + this.state.jobsInfo &&
        i <
          this.state.caseInfo +
            this.state.newsInfo +
            this.state.jobsInfo +
            this.state.groupInfo
      ) {
        list.push(
          <div key={i}>
            <div
              className="search-title"
              onClick={this.handleClick.bind(
                this,
                this.state.searchs.data.groupInfo[
                  i -
                    (this.state.caseInfo +
                      this.state.newsInfo +
                      this.state.jobsInfo)
                ].path,
                0
              )}
            >
              {
                this.state.searchs.data.groupInfo[
                  i -
                    (this.state.caseInfo +
                      this.state.newsInfo +
                      this.state.jobsInfo)
                ].Name
              }
            </div>
            <div className="search-text">
              {this.state.searchs.data.groupInfo[
                i -
                  (this.state.caseInfo +
                    this.state.newsInfo +
                    this.state.jobsInfo)
              ].Context.substring(0, 100)}
            </div>
            <div className="search-time"></div>
            <div className="search-line"></div>
          </div>
        );
      }
    }
    //=====下方列表渲染
    let locationList = [];
    //==上一页的样式
    if (nowlocation === 1) {
      locationList.push(
        <div key={"start"} className="search-location-text">
          上一页
        </div>
      );
    } else {
      locationList.push(
        <div
          key={"start"}
          className="search-location-text-active"
          onClick={() => this.SetNowlocation(nowlocation - 1)}
        >
          上一页
        </div>
      );
    }
    //===渲染中间页码
    let nowPage = this.state.nowlocation;
    let minP = 0;
    let maxP = 14;
    if(nowPage <= 8){
      minP = 0;
      maxP = 14;
    }
    else if(Math.ceil(this.state.num / 5) - nowPage <= 7){
      minP = Math.ceil(this.state.num / 5) - 15;
      maxP = Math.ceil(this.state.num / 5);
    }
    else {
      minP = this.state.nowlocation - 8;
      maxP = this.state.nowlocation + 6;
    }
    console.log("maxPage", nowPage, minP, maxP);
    for (let k = 0; k < num / 5; k++) {
      if(k >= minP && k <= maxP){
      if (k + 1 === nowlocation) {
          locationList.push(<div key={"bank" + k} className="interval-col-32" />);
          locationList.push(
            <div key={"location" + k} className="search-location-text">
              {k + 1}
            </div>
          );
        } else {
          locationList.push(<div key={"bank" + k} className="interval-col-32" />);
          locationList.push(
            <div
              key={"location" + k}
              className="search-location-text-active"
              onClick={() => this.SetNowlocation(k + 1)}
            >
              {k + 1}
            </div>
          );
        }
      }
    }
    //===下一页样式
    if (nowlocation === Math.ceil(num / 5)) {
      locationList.push(<div key={"end" + 1} className="interval-col-32" />);
      locationList.push(
        <div key={"end"} className="search-location-text">
          下一页
        </div>
      );
    } else {
      locationList.push(<div key={"end" + 1} className="interval-col-32" />);
      locationList.push(
        <div
          key={"end"}
          className="search-location-text-active"
          onClick={() => this.SetNowlocation(nowlocation + 1)}
        >
          下一页
        </div>
      );
    }
    //======列表渲染结束
    if (num > 5) {
      list.push(
        <div key={"end"} className="search-location">
          {locationList}
        </div>
      );
    }

    return (
      <div>
        <Head />
        <div className="search">
          <div className="first-page">
            <div className="first-page-head">
              <div className="search-box">
                <input
                  id="input"
                  className="search-input"
                  type="text"
                  placeholder="请输入你的问题或关键字"
                ></input>
                <div
                  className="search-botton"
                  onClick={() => this.searchInpage()}
                >
                  <div className="search-icon"></div>
                </div>
              </div>
            </div>
            <div className="interval-50" />
            {/*搜索界面的主体*/}
            <div className="search-body">
              <div className="search-body-left">
                <div className="search-body-category-title">
                  <div className="search-body-category-text">按类别过滤</div>
                </div>
                <div className="seach-body-category">
                  <ul>
                    <li
                      id="all"
                      onClick={this.changeActive.bind(this, "all")}
                      className="category-active"
                    >
                      全部
                    </li>
                    <li
                      id="group"
                      onClick={this.changeActive.bind(this, "group")}
                    >
                      产品
                    </li>
                    <li id="job" onClick={this.changeActive.bind(this, "job")}>
                      方案
                    </li>
                    <li
                      id="case"
                      onClick={this.changeActive.bind(this, "case")}
                    >
                      案例
                    </li>
                    <li id="new" onClick={this.changeActive.bind(this, "new")}>
                      新闻
                    </li>
                  </ul>
                </div>
              </div>

              {/*中间间隔*/}
              <div className="interval-col-66" />

              {/*右侧具体页面*/}
              <div className="search-body-right">{list}</div>
            </div>
          </div>
        </div>
        <Bottom />
      </div>
    );
  }
}

export default Search;
