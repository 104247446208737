/*
 * @Author: your name
 * @Date: 2019-12-30 13:17:54
 * @LastEditTime: 2022-02-15 14:06:53
 * @LastEditors: qxy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\CompanyIntroduction\corporate-honor\CorporateHonor.js
 */
import React from "react";
import "./corporate-honor.less";
import ani from "../../../transition/animation/animation";

//引入prefeb模块
import Bottom from "../../../common-components/BottomBar";
import Head from "../../../common-components/TopBar/TopBar";

//网页第一个模块
class FirstPage extends React.Component {
  render() {
    return (
      <div className="first-page">
        <div className="first-page-img"></div>
        <div className="first-page-logo" />
        <ul className="myul">
          <li className="height-230">
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-230" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2021</div>
            <div className="timeline-text-right">
              6月，新中大获评 “2020-2021年度中国区人力资源管理领先企业50强”
              <br />
              7月，新中大上榜2021信创产业分类排行榜
              <br />
              8月，新中大荣获“2020-2021年度工业互联网产业最具影响力企业”奖项
              <br />
              12月，新中大钱潮平台获评“2021数字建造领域国产自主可控优秀技术平台”
            </div>
          </li>
          <li className="height-340">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-340" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2020</div>
            <div className="timeline-text-left">
              7月，新中大荣登2020数字基建百强
              <br />
              8月，CCID报告数据显示新中大占据建筑特级企业项目管理软件34%市场份额、税务管理软件40%市场份额
              <br />
              11月，新中大荣获“2020年度中国区最佳雇主”
              <br />
              12月，新中大入选“浙江省软件行业20年突出贡献企业”
            </div>
          </li>
          <li className="height-230">
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-230" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2019</div>
            <div className="timeline-text-right">
              1月，新中大获评“2018年度中国最佳雇主”
              <br />
              1月，新中大智慧建造解决方案荣获“2018年中国软件行业优秀解决方案”奖项
              <br />
              1月，新中大被浙江省软件行业评定为“2018年度浙江省软件行业成长型百强企业”
              <br />
              4月，新中大智慧建造解决方案荣获“2019中国信息技术优秀解决方案”奖项
              <br />
              4月，新中大i8工程项目管理平台荣获“2019中国信息技术优秀产品”奖项
              <br />
              5月，新中大荣获“2019年度中国建筑装饰行业‘互联网+’最具创新力品牌”称号
              <br />
              7月，新中大获评“2018年度瞪羚企业”
            </div>
          </li>
          <li className="height-340">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-340" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2018</div>
            <div className="timeline-text-left">
              1月，新中大荣获“2017中国信息化和软件服务业年度用户推荐奖”、“2017中国信息化和软件服务业年度风云企业奖”
              <br />
              1月，新中大i6P工程企业管理软件荣获“2017中国信息化和软件服务业年度最佳产品奖”
              <br />
              3月，新中大总裁韩爱生获评2017年“中国建筑业年度人物”称号
              <br />
              8月，新中大i8工程项目管理平台产品荣获“2018中国软件行业工程建设管理领域最佳产品”奖项
              <br />
              10月，新中大获评“2018最具投资发展潜力软件企业”
              <br />
              11月，新中大工程企业管理软件被浙江省质量技术监督局认定为“浙江名牌产品”
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-280" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2017</div>
            <div className="timeline-text-right">
              6月，新中大通过美国软件工程学会SEI（Software Engineering Institute）颁发的CMMI5权威认证
              <br />
              8月，新中大荣获“信息化影响中国-2017年行业最具影响力企业奖”
              <br />
              12月，新中大获评“2017中国人力资源先锋雇主”称号
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-290" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2016</div>
            <div className="timeline-text-left">
              5月，新中大建筑业“营改增”信息化解决方案荣获2016年度中国软件行业“建筑业营改增领域最佳解决方案奖”
              <br />
              8月，新中大获评“2016年度中国项目管理信息化领军企业奖”
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-310" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2015</div>
            <div className="timeline-text-right">12月，新中大i6P工程企业管理软件荣获2015年度中国软件和信息服务“工程项目管理信息化领域最佳产品奖”</div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2014</div>
            <div className="timeline-text-left">
              12月，新中大董事长石钟韶先生荣获“2004—2014杰出浙商”荣誉奖
              <br />
              新中大基于GIS技术的农村土地承包经营权信息管理系统荣获“2014年中国软件和信息服务三农信息化行业最佳产品”
            </div>
          </li>
          <li style={{ height: 240 / 14.4 + "vw" }}>
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2013</div>
            <div className="timeline-text-right">
              7月，新中大董事长石钟韶经中国建筑业协会第五次会员代表大会选举，当选为协会第五届理事会理事
              <br />
              7月，在中国项目管理服务业优秀机构评选活动中，荣获“中国最具价值项目管理软件公司（2003-2013）”荣誉称号
            </div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2012</div>
            <div className="timeline-text-left">
              5月，新中大公司获选“杭州市农村电子商务试点企业”
              <br />
              9月，新中大荣获2012年中国电子商务百强企业，并在第九届网商大会上成功入选了2012十佳电商服务商
              <br />
              12月，新中大荣获2012中国软件和信息服务“农经管理行业最佳解决方案”
              <br />
              12月，在2012中国软件大会暨大数据发展与应用峰会上，新中大荣获由中国电子信息产业发展研究院和中国软件评测中心共同颁发的“2012年中国软件和信息服务农经行业最佳解决方案奖”
              <br />
              12月，新中大荣获由中国电子商务协会产业链营销发展中心和中国社会经济调查研究中心产业经济研究所联合颁发的“2012中国电子商务最具投资价值技术服务商”称号。同月，荣获浙江省商务厅和省电子商务工作领导小组办公厅公共认定的“浙江省电子商务服务示范企业”称号
            </div>
          </li>
          <li className="height-380">
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2011</div>
            <div className="timeline-text-right">
              4月，新中大入选中国网络零售业金蚕奖“年度最佳软件及电商外包服务商”提名
              <br />
              4月，新中大作为首批通过《财经信息技术 会计核算软件数据接口》(GB/ T 24589-2010)国家标准的七家企业之一，赴京参加国家标准实施推进成果新闻发布会，公司总工程师李伯鸣先生与会接受表彰
              <br />
              10月“新中大URP软件i6工程版V11.0”通过由住房和城乡建设部信息中心组织的2011年度住房城乡建设领域应用软件测评
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2010</div>
            <div className="timeline-text-left">
              7月，公司荣获“2009年度华东百佳方案商”称号
              <br />
              9月，荣获“全国电子商务百强企业”称号
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2009</div>
            <div className="timeline-text-right">
              “新中大URP工程企业信息化整体解决方案”荣获“2009年度中国信息主管推荐奖”
              <br />
              新中大公司荣获“2008年度华东百佳方案商”称号
            </div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2008</div>
            <div className="timeline-text-left">
              新中大URP软件i6工程版荣获“2008年度金软件奖”
              <br />
              新中大公司荣获“2008首届年度项目管理软件优秀奖”
              <br />
              新中大入选“2007年度中国电脑供应商500强之供应商100强”
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2007</div>
            <div className="timeline-text-right">新中大再次经中华人民共和国科学技术部和火炬高技术产业开发中心认证，入选“国家火炬计划软件产业基地骨干企业”</div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2006</div>
            <div className="timeline-text-left">
              2月，新中大荣获“2005—2006年度管理软件市场增长最快企业”称号
              <br />
              3月，在“2005年制造业信息化岁末盘点”系列评选中，新中大总裁石钟韶荣获“2005中国制造业信息化十大风云人物”称号
              <br />
              8月，在“2006年中国IT服务年会”上，新中大荣获“2006中国服务满意金奖”
              <br />
              9月，据CCID“2006中国管理软件产品服务市场及用户满意度调查研究报告”显示，新中大软件服务用户满意度位居第一
              <br />
              12月，新中大总裁石钟韶荣获“2005时尚浙商”称号
              <br />
              新中大国际ERP软件A3被评为“2005年中国制造业信息化优秀产品”
            </div>
          </li>
          <li className="height-380">
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2005</div>
            <div className="timeline-text-right">
              1月,经国家发展计划委员会、信息产业部、对外贸易经济合作部、国家税务总局审核，新中大公司被认定为“2002年度国家规划布局内的重点软件企业”
              <br />
              3月，新中大国际财务管理软件i6/Intfi、新中大生产制造管理软件i6/PM以及新中大工程管理软件Psoft三项产品荣获“2002年全国推荐优秀软件产品”称号
              <br />
              4月，新中大荣获“2002年中国最具潜质成长型IT企业”称号
              <br />
              10月，新中大再次跻身中国电子政务IT百强
            </div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2004</div>
            <div className="timeline-text-left">
              1月，新中大入选国家 “863”计划的“面向中小制造企业信息化的集成系统开发与应用”
              <br />
              4月，新中大软件公司顺利通过施乐新加坡研发中心CMM主任评估师 Rayney Wong 及ATM评估小组对其软件过程CMM三级评估
              <br />
              新中大总裁石钟韶先生被评为“中国软件行业杰出青年”，并被北京航空航天大学软件学院聘为特聘教授
              <br />
              信息产业部电子信息产业发展基金管理办公室正式发文认定杭州新中大软件股份有限公司通过2003年度电子信息产业发展基金项目
            </div>
          </li>
          <li className="height-380">
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2003</div>
            <div className="timeline-text-right">
              1月,经国家发展计划委员会、信息产业部、对外贸易经济合作部、国家税务总局审核，新中大公司被认定为“2002年度国家规划布局内的重点软件企业”
              <br />
              3月，新中大国际财务管理软件i6/Intfi、新中大生产制造管理软件i6/PM以及新中大工程管理软件Psoft三项产品荣获“2002年全国推荐优秀软件产品”称号
              <br />
              4月，新中大荣获“2002年中国最具潜质成长型IT企业”称号
              <br />
              10月，新中大再次跻身中国电子政务IT百强
            </div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2002</div>
            <div className="timeline-text-left">
              3月，新中大总裁石钟韶先生当选为“2001年中国软件企业十大领军人物”
              <br />
              7月，新中大被认定为国家火炬计划软件产业基地骨干企业
              <br />
              7月，新中大国际财务管理软件列入国家级火炬计划项目
              <br />
              8月，新中大在“2002年中国IT用户满意度调查”中荣获“财务软件最佳用户服务满意度奖”
              <br />
              9月，在“2002年CCID中国管理软件厂商竞争力调查”中，新中大荣获“优秀财务软件厂商”和“政府行业优秀产品”两项大奖
              <br />
              10月，在“中国电子政务IT100强”评选活动中，新中大在电子政务百家IT企业中荣登总排名第17位，软件供应商三强
              <br />
              10月，新中大互动管理i6系统V3.0通过中国软件评测中心模拟运行评测和ERP专家评审委员会的严格评审，最终成为六家通过科技部ERP软件测评的产品之一
              <br />
              11月，新中大总裁石钟韶先生被中华全国工商业联合会,中国民营科技实业家协会等单位授予“中国优秀民营科技企业家”称号
            </div>
          </li>
          <li className="height-380">
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">2001</div>
            <div className="timeline-text-right">
              4月，新中大财务管理软件NGPower5.0被中国软件行业协会评为“中国优秀软件产品”
              <br />
              5月，新中大公司被评为“2000年中国十大品牌软件企业”
              <br />
              11月，在CCID举办的中国软件软件产品消费行为调查中新中大荣获“财务管理软件消费者满意品牌”称号,同时荣获知名品牌称号
              <br />
              12月，新中大生产制造管理软件i6/PM获浙江省信息产业部列入软件产业发展专项资金支持项目
            </div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">2000</div>
            <div className="timeline-text-left">
              5月，新中大顺利通过了ISO9001质量体系认证，并获得由中国电子质量体系认证中心（CCQE）颁发的质量体系认证证书及电子行业质量体系认证委员会（CQEC）颁发的证书
              <br />
              11月，新中大公司通过ISO9002服务体系认证
              <br />
              新中大公司被浙江日报和浙江统计学会评定为“浙江省优秀软件开发商”
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">1999</div>
            <div className="timeline-text-right">通过中国软件行业协会Y2K问题测试</div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">1998</div>
            <div className="timeline-text-left">
              新中大荣获中国国际博览会“金奖”
              <br />
              新中大软件产品再次被中国软件行业协会评为“优秀财务软件”
              <br />
              新中大连续第两年荣获中国软交会“优秀软件产品奖”称号
            </div>
          </li>
          <li style={{ height: 200 / 14.4 + "vw" }}>
            <div className="timeline-line-row-right"></div>
            <div className="timeline-line-col height-380" />
            <div className="timeline-icon-right"></div>
            <div className="timeline-img-right"></div>
            <div className="timeline-year-right">1997</div>
            <div className="timeline-text-right">
              新中大软件产品被中国软件行业协会评为“优秀财务软件”
              <br />
              新中大软件产品荣获中国软交会“优秀软件产品奖”
              <br />
              美国Intel公司和中国软件行业协会共同举办的第三届中国PC应用软件设计大奖赛“优胜奖”
            </div>
          </li>
          <li className="height-270">
            <div className="timeline-line-row-left"></div>
            <div className="timeline-line-col height-270" />
            <div className="timeline-icon-left"></div>
            <div className="timeline-img-left"></div>
            <div className="timeline-year-left">1996</div>
            <div className="timeline-text-left">在1996年中国第七届软件交流会上，新中大MIS型财务软件荣获“优秀新产品软件奖”</div>
          </li>
        </ul>
      </div>
    );
  }
}

//网页主模块
class CorporateHonor extends React.Component {
  constructor(props) {
    super(props);
    this.showAnimation = this.showAnimation.bind(this);
  }

  //离开解绑滚动条事件
  componentWillUnmount() {
    window.removeEventListener("scroll", this.showAnimation);
  }
  //绑定滚动条事件
  componentDidMount() {
    window.addEventListener("scroll", this.showAnimation);
    this.showAnimation();
    this.initAnimation();
  }

  initAnimation() {
    ani.initclass("first-page-logo", "opacity-initial");
    ani.initclass("myul", "opacity-initial");

    ani.initclass("timeline-year-right", "opacity-initial");
    ani.initclass("timeline-text-right", "opacity-initial");

    ani.initclass("timeline-year-left", "opacity-initial");
    ani.initclass("timeline-text-left", "opacity-initial");
  }
  //在这里绑定滚动播放动画事件 （具体绑定每个id）
  showAnimation() {
    ani.getClassHight("first-page-logo", 0, "opacity-change");
    ani.getClassHight("myul", 0, "opacity-change5");

    ani.getClassHight("timeline-year-right", 0, "opacity-change10");
    ani.getClassHight("timeline-text-right", 0, "opacity-change10");

    ani.getClassHight("timeline-year-left", 0, "opacity-change10");
    ani.getClassHight("timeline-text-left", 0, "opacity-change10");
  }
  render() {
    return (
      <div>
        <Head />
        <div className="corporate-honor">
          <FirstPage />
        </div>
        <div className="cor-change-bottom">
          <Bottom />
        </div>
      </div>
    );
  }
}

export default CorporateHonor;
