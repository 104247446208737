/*
 * @Author: your name
 * @Date: 2019-12-12 09:40:26
 * @LastEditTime : 2020-01-09 13:13:39
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\machinery-management\MachineryManagement.js
 */

import React from 'react';
//import './index.css';
import './enterprise-management.less';
import ani from '../../../transition/animation/animation';
//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../../digitalSite/components/TitleSize40Center'
import Describe from '../../digitalSite/components/Describe'
import Card from '../../ProjectConstruction/components/card/Card'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"企业管控中心解决方案"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案介绍"} />
                <Describe text={"企业管控中心（EPM）是以自主研发的商务智能技术为核心、以企业绩效管理理念为理论基础的管理应用软件平台。EPM作为新中大i8工程项目管理平台的一个套件，因以商业智能作为核心技术，它不仅仅能和新中大各业务子系统进行集成，而且能够和企业其他业务系统进行集成应用。<br/>"+
                                "新中大企业绩效管理系统EPM解决方案目标是为企业战略管理提供基于商业智能技术的大数据支持，由五个层次组成：系统应用层、企业应用层、商业智能层、应用服务层、信息展示层，包括管控平台、指标管理、报表仓库、数据窗口、绩效报告等功能模块。<br/>"+
                                "管控平台：是企业绩效管理系统核心模块，具有可定制化的企业大数据分析展示的平台。包括了管理视图设置、墙面设置、组织看板设置、决策中心等子功能。<br/>"+
                                "指标中心：监控企业各业务领域不同阶段的关键经营绩效指标，并通过评估标准评价指标的健康状况，并用不同颜色的指示灯和仪表盘进行直观显示。包括企业指标、项目指标、基础指标、评估指标等子模块。<br/>"+
                                "企业级报表仓库：提供给企业各部门和决策层进行企业管理和决策的强有力的辅助工具。帮助监控企业运作，及时规避风险，为管理决策提供依据，度量企业运作的绩效。<br/>"+
                                "绩效报告：系统根据企业管理模型，支持设置多套绩效报表生成模式，自动汇总产生绩效报表，并能以邮件、微信等方式呈送到企业领导中，方便数据跟踪分析查询。包括报表列表、报表呈送、报表模板设计等子模块。"} />
                <div className="interval-154"></div>
            </div>
        );
    }
}

//网页第三个模块
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <div className="interval-80"></div>
                <div className="third-page-img"></div>
                <div className="interval-80" />
            </div>
        );
    }
}

//网页第四个模块
class FourthPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案价值"} />
                <div className="interval-80" />
                <div className="show-list">
                    <table >
                        <tbody>
                            <tr>
                                <td><Card title={"数据整合"} text={"打通企业多部门的经营管理数据，解决数据孤岛，实现信息透明。"} /></td>
                                <td><Card title={"数据优化"} text={"产出可量化的、持续的企业经营管理数据，形成企业经营关键绩效指标，并通过大数据技术实现经营数据模拟与预测。"} /></td>
                                <td><Card title={"辅助决策"} text={"通过数据分析，辅助企业决策。帮助企业实现科学化、数据化的业务监测、业务洞察、业务优化、决策优化。"} /></td>
                            </tr>
                        </tbody>
                    </table>
                </ div>
                <div className="interval-154"></div>
            </div>
        );
    }
}


//网页主模块
class EnterpriseManagement extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("fifth-page-card", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");

        ani.initclass("sixth-page-card", "moveright100-center-init")
        ani.initclass("sixth-page-card-textcard", "moveleft100-center-init")
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("fifth-page-card", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");

        ani.getClassHight("sixth-page-card", 20, "moveright100-center-notime");
        ani.getClassHight("sixth-page-card-textcard", 20, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="enterprise-management">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                    <FourthPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default EnterpriseManagement;