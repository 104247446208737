/*
 * @Author: your name
 * @Date: 2019-12-16 14:37:40
 * @LastEditTime : 2020-01-08 17:33:55
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\employee-life\EmployeeLife.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './employee-life.less';
import $ from 'jquery';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Card from '../components/card/Card'


//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="interval-col-146"></div>
                    <div className="left">
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/1.png"}
                        title={"奋进新时代，共创新篇章——新中大25周年庆典隆重举行"} text={"奋进新时代，共创新篇章。8月19日，杭州新中大科技股份有限公司25周年庆典在浙江大学紫金港校区隆重举行。来自新中大总部全体员工、各分子机构代表300多人欢聚一堂，共同庆祝新中大成立25周年。"} time={"2018-08-20"} />
                        <div className="interval-70" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/2.png"}
                        title={"Mini版“杭马”来袭 新中大首届马拉松火力全开"} text={"11月4日，在“杭州马拉松”开跑的前一日，杭州新中大软件股份有限公司“第一届马拉松”于早上9时正式在钱塘江畔开跑，来自新中大运营、研发及其他职能部门的30余名“新马跑手”参加了本届马拉松，共同用速度与激情提前在杭城上演了一场Mini版“杭马”。"} time={"2017-11-06"} />
                        <div className="interval-70" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/3.png"}
                        title={"奋进新时代，共创新篇章——新中大25周年庆典隆重举行"} text={"奋进新时代，共创新篇章。8月19日，杭州新中大科技股份有限公司25周年庆典在浙江大学紫金港校区隆重举行。来自新中大总部全体员工、各分子机构代表300多人欢聚一堂，共同庆祝新中大成立25周年。"} time={"2018-08-20"} />
                    </div>
                    <div className="interval-col-70"></div>
                    <div className="right">
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/4.png"}
                        title={"奔跑吧！创业者！——新中大跑团亮相第二届杭州创业马拉松"} text={"9月16日，第二届杭州市创业马拉松——一场属于创业者的马拉松在杭州萧山区钱江世纪公园举行，萧山区区委常委、常务副区长顾春晓为比赛鸣枪之后，来自各地的近500名创业者正式开跑。新中大软件本次组织了“新中大营改增队”、“新中大一升特队”两支参赛。"} time={"2018-08-20"} />
                        <div className="interval-70" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/5.png"}
                        title={"创未来.成大业 ——2017年新中大企业文化月活动通知"} text={"为了加强新中大的企业文化建设，提高团队的创业、创新精神，增强企业的向心力和凝聚力，让公司全体员工更深刻地理解公司的愿景目标，提升责任感和荣誉感，从而有效地推动公司战略目标的实现，同时庆祝公司成立24周年，按照新中大传统，计划组织开展2017年新中大企业文化月活动。"} time={"2017-08-18"} />
                        <div className="interval-70" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/6.png"}
                        title={"奔跑吧！创业者！——新中大跑团亮相第二届杭州创业马拉松"} text={"9月16日，第二届杭州市创业马拉松——一场属于创业者的马拉松在杭州萧山区钱江世纪公园举行，萧山区区委常委、常务副区长顾春晓为比赛鸣枪之后，来自各地的近500名创业者正式开跑。新中大软件本次组织了“新中大营改增队”、“新中大一升特队”两支参赛。"} time={"2018-08-20"} />
                    </div>
                </div>
                <div className="interval-353" />
            </div>
        );
    }
}

//网页主模块
class EmployeeLife extends React.Component {
    //----从此处开始
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this)
    }
    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initclass("join-us", "moveup-initial");
    }
    //获取相关id的高度
    getHight(id, showposition, classname) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }
    //初始化添加动效的类名
    initclass(classname, addclassname) {
        let classlist = $("." + classname);
        for (let i = 0; i < classlist.length; i++) {
            $(classlist[i]).addClass(addclassname);
        }
    }
    //监控所有指定class的目标
    getClassHight(myclass, showposition, classname) {
        let classlist = $("." + myclass);
        for (let i = 0; i < classlist.length; i++) {
            let height = $(classlist[i]).offset().top - $(window).scrollTop() - $(window).height();
            if (height < showposition) {
                $(classlist[i]).addClass(classname);
            }
        }
    }
    //触发本组件的动画
    getOtherHight(otherid, id, showposition, classname) {
        let height = $("#" + otherid).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }

    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        this.getClassHight("join-us", 200, "moveup-notime")
    }
    render() {
        return (
            <div>
                <Head />
                <div className="employee-life">
                    <FirstPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default EmployeeLife;