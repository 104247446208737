/**
 * 全名： 工程投资企业管理
 * 功能描述： 工程投资企业管理
 * 创建日期：--
 * 作者：hzw
 */

import React from "react";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import ContactBottom from '../../../new-components/ContactBottom';
import PureImage from '../privateComponents/PureImage';
import RenderWithTitle from "@/high-orders/RenderWithTitle";
import config from "./config"
import './index.less';

export default class extends React.Component {

  render() {
    const DescribeWithTitleB = RenderWithTitle(PureImage, '一套适用于业主投资的项目管理系统');
    const DescribeWithTitleC = RenderWithTitle(PureImage, '一个基于云原生微服务的钱潮平台',{
      background: "linear-gradient(360deg, #fff 0%, #DFFEFE 100%)",
    });
    const DescribeWithTitleD = RenderWithTitle(RenderWithTitle(PureImage, '十大核心价值助力提升') ,'项目管理、协同工作和业务管理水平');
    const DescribeWithTitleE = RenderWithTitle(PureImage, '用户案例');

    return (
      <div className="project-investment-manage">
        <Head />
        <Abstract {...config.sessionA} />
        <DescribeWithTitleB {...config.sessionB} />
        <DescribeWithTitleC  {...config.sessionC} />
        <DescribeWithTitleD  {...config.sessionD} />
        <DescribeWithTitleE {...config.sessionE} />
        <ContactBottom />
        <Bottom />
      </div>
    )
  }
}