/*
 * @Author: your name
 * @Date: 2019-12-16 14:36:36
 * @LastEditTime : 2020-01-08 17:35:58
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\join-NG\JoinNG.js
 */
import React from 'react';
//import './index.css';
import './join-NG.less';
import $ from 'jquery';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'


//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-img">
                    <div className="interval-361"></div>
                    <div className="first-page-card moveright100-center-init" id="join">
                        <div className="first-page-card-text">
                            新中大以人为本，人才是新中大的重要资源。<br />        新中大海纳百川，汇萃精华，把人才作为企业发展的创业之本、竞争之本、发展之本。经过二十年的不断发展，公司已经形成了敬人敬业、公平竞争、尊重知识和人才的良好文化。
                            公司以独特的人才招聘战略、开阔的事业发展空间、优良的人才成长环境、有效的激励机制和人本的企业文化理念，使企业成为人才集聚的高地。<br />        我们希望有更多志同道合的人才加入新中大事业的行列，更多的人关注新中大的发展。<br />        新中大欢迎你！
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
            </div>
        );
    }
}
//网页主模块
class JoinNG extends React.Component {
    //----从此处开始
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this)
    }
    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
    }
    //获取相关id的高度
    getHight(id, showposition, classname) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }
    //触发本组件的动画
    getOtherHight(otherid, id, showposition, classname) {
        let height = $("#" + otherid).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }

    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        this.getHight("join", 0, "moveright100-center-notime")

    }
    render() {
        return (
            <div>
                <Head />
                <div className="join-NG">
                    <FirstPage />
                    <SecondPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default JoinNG;