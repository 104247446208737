import './index.less';
import React from 'react';
import '../../global.js';

export default () => {
  const iconsConfig = [
    {
      text: '免费体验',
      iconPosition: { x: 0, y: 0 },
    },
    {
      text: '预约演示',
      iconPosition: { x: 1, y: 0 },
    },
    {
      text: '价格咨询',
      iconPosition: { x: 2, y: 0 },
    },
    {
      text: '联系我们',
      iconPosition: { x: 3, y: 0 },
    },
  ]

  return (
    <div className="contact-bottom" style={{
      backgroundImage: `url(${global.config.url}/newgrand/picture/img/new-product/bottom.png)`
    }}>
      <div className="applyToUse">
        <a href="http://newgrand.mikecrm.com/uFFgAoS" target="_blank" rel="noopener noreferrer">
          <div className="btn"><span>申请免费试用</span>
          </div>
        </a>
      </div>

      <div className="options">
        {iconsConfig.map((el) => {
          return (
            <div key={el.text} className="icon-item" >
              <div className="icon" style={{ backgroundPosition: `calc(${-10 - el.iconPosition.x * 100}vw/14.4) calc(${-10 - el.iconPosition.y * 100}vw/14.4)` }}></div>
              <div className="text">{el.text}</div>
            </div>
          )
        })}
      </div>

      <div className="tel"> <span> 咨询热线： 95105820 </span> </div>
    </div>
  )
}