import React from 'react';
//import './index.css';
import './error.less'

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import { Link } from 'react-router-dom';

//网页主模块
class Error extends React.Component {
    render() {
        return (
            <div>
                <Head />
                <div className="error">
                    <div className="title">还在建设中</div>
                    <div className="text">可能是因为网页还没有建好，您可以尝试返回<Link to="/home">首页</Link>。</div>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default Error;