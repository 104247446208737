import React from "react";
import './index.less';
import { useEffect, useRef, useState } from 'react';
import Animation from '@/transition/animation/animation';

export default function (props) {
  const ref = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    let animationFinash = false;
    let animationFn = () => {
      if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
        setStartAnimation(true);
        animationFinash = true;
      };
    }
    animationFn();
    window.$(window).scroll(animationFn);
    return () => {
      window.$(window).unbind('scroll', animationFn);
    }
  });

  return (
    <div className={`describe-container  moveup20-initial opacity-initial ${startAnimation ? `moveup20` : ``}`} ref={ref}>
      <div className="describe-inner">
        <span>
          {props.describe}
        </span>
      </div>
    </div>
  )
}