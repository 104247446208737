import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/12/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%9C%8D%E5%8A%A1/%E8%BD%AF%E4%BB%B6%E6%9C%8D%E5%8A%A1%E4%B8%8E%E7%B3%BB%E7%BB%9F%E9%9B%86%E6%88%90%E8%A1%8C%E4%B8%9A%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/banner.png`,
    describe:`新中大软件服务与系统集成行业管理解决方案提供项目服务企业快速建立由SUP平台支持的、先进的企业级专业知识库平台，提升技术人员整体水平。它提供的大量专业化功能、多元化的方法、个性化的信息访问和报告以及行业指南，快速搭建项目团队的协同工作平台，建立顺畅的沟通渠道。而且允许您随着企业的不断发展，提供基于灵动技术的可扩展架构，以实现项目全过程管理、集团财务管理、人力资源管理、客户关系管理、知识文档管理、企业绩效管理管理。`, 
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML:'项目服务之<br>软件服务与系统集成行业解决方案',
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 1,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '全视角项目管控'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '知识共享体系'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '领先的电子商务门户技术'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '高效系统集成'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        title: '规范流程，提升效率',
        describe: '信息化同时规范了企业的管理流程，大大增加了企业级项目管理效率，使得企业管理者有更多的精力去考虑管理中的优化、协调、组织和决策等问题。'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        title: '全过程项目管控',
        describe: '建立从立项到验收的全流程项目管理平台；建立以技术能力评估模型为基准的工作任务分析、分派、跟踪机制，实现项目进度、成本、质量管控。'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        title: '提高项目人员素质和管理水平',
        describe: '信息化还可以帮助项目团队提高工作效率，可以降低工作的难度、减少差错、增加员工的成就感、提高员工的满意度和振奋士气、提升员工工作的品质。'
      }
    ]
  },
  sessionE: {
    title: '烟台海华电力科技股份有限公司',
    describe:`烟台海华电力科技股份有限公司成立于2003年5月，隶属于东方电子集团有限公司，是东方电子集团有限公司国际化战略推进和海外市场拓展的主要载体。2003年成立以来，公司按照东方电子集团有限公司的国际化战略规划，致力于国内外技术工程服务外包和海外电力市场发展，业务范围遍及马来西亚、新加坡、印度、印尼等亚洲国家、赤道几内亚、乍得、赞比亚等非洲国家，并且开拓了阿联酋、沙特等新兴市场。`, 
    backgroundImage: `${global.config.url}/newgrand/picture/img/new-cases/12/amli.png)`
  }
}