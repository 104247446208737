/*
 * @Author: your name
 * @Date: 2019-12-11 11:13:33
 * @LastEditTime : 2019-12-23 09:52:52
 * @LastEditors  : Please set LastEditors
 * @Description: 主要用于数字工地页面第一页的介绍标题
 * @FilePath: \my-demo\src\pages\digitalSite\components\TitleSize40.js
 */
import React from 'react';
//import './index.css';
import './components.less'


/**
 * @description  40字号的粗体标题组件 居中
 *              提供参数: 标题文本<text>
 * @author chenyutan
 * @date 2019-12-11
 * @class Title
 */
class TitleSize40Center extends React.Component {
    render() {
        return (
            <div className="digital-site">
                <div className="title-40-center">{this.props.text}</div>
            </div>
        );
    }
}

export default TitleSize40Center;