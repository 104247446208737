import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/1c/`;
export default {
  sessionA: {
    describe: '集团费控管理在全集团费用支出申请、审批、支付的各个环节提供预算数据和实际数据对比，并提供多种控制维度和控制方法，在费用还没有产生之前即进行及时控制。通过费控管理系统推行，建立一套完善的费用预算控制体系，固化费用报销和审批流程，强化费用预算和相关制度的执行力度，不断节省费用支出，实现降本增效。'
  },
  sessionB: {
    rowCount: 1,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '更方便、快捷的自助报销平台'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '全过程费用预算管控'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '多种报销业务融合管理'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '全面集成能力'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },
  sessionC: {
    imgSrc:`${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount:2,
    rowCount:3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 3, y: 0 },
        title: '',
        describe: '全面跟踪、控制企业的费用发生全过程，通过费用预算，合理有效控制费用的发生，节约不必要的浪费，降低企业总体运营成本，保证了企业资金流的良性循环。'
      },
      {
        key: 2,
        iconPosition: { x: 4, y: 0 },
        title: '',
        describe: '员工随时随地上网提交报销申请；各级领导网上集中审批，节省了更多的精力投入到更重要的管理工作中；财务人员无需二次手工凭证录入，报销单据凭证自动生成；审批通过的报销费用直接划拨到员工银行卡，提高了出纳人员的工作效率。'
      },
      {
        key: 3,
        iconPosition: { x: 5, y: 0 },
        title: '',
        describe: '通过系统，规范费用报销审批过程，明确职责划分，员工到领导到财务均可清晰地看到单据审批情况，报销过程透明化。'
      },
      {
        key: 4,
        iconPosition: { x: 6, y: 0 },
        title: '',
        describe: '费用报销流程、严密的记录控制，将进一步完善和规范企业报销业务；管理者更加关注企业内部控制流程和预算执行，将有效优化公司的控制环境；审计线索更加便利，可以快速从记账凭证追溯到原始业务活动的来龙去脉。'
      },
    ]
  },
}