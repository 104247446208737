/*
 * @Author: your name
 * @Date: 2019-12-17 09:39:41
 * @LastEditTime : 2019-12-23 10:02:45
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\projectInvestment\components\card\Card.js
 */
import React from 'react';
//import './index.css';
import './card.less'

class Card extends React.Component {
    render() {
        return (
            <div className="project-construction">
                <div className="card">
                    <div className="interval-32"></div>
                    <div className="card-title">{this.props.title}</div>
                    <div className="card-text">{this.props.text}</div>
                </div>
            </div>
        );
    }
}

export default Card;