import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/1/`;
export default {
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "作为企业集团管控核心的财务管理，更需要运用数字化技术更好地革新传统的财务流程，新中大集团财务管理解决方案功能涵盖了集团财务核算、集团资金管理、费控管理、财税共享、报表管理等，实现对企业集团“关键经营、重点风险”的整体管控。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "集团财务管理",
    hiddenBtn:true,
    imgStyle: {
      maxHeight: '100%',
    }
  },

  sessionB: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionC: {
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        style: {
          backgroundImage: `url(${baseUrl}a.png)`,
        }
      },
      {
        key: 1,
        style: {
          backgroundImage: `url(${baseUrl}b.png)`,
        }
      },
      {
        key: 2,
        style: {
          backgroundImage: `url(${baseUrl}c.png)`,
        }
      },
      {
        key: 3,
        style: {
          backgroundImage: `url(${baseUrl}d.png)`,
        }
      },
    ]
  },
  sessionD: {
    title: '山西建设投资集团有限公司',
    describe: '山西建设投资集团有限公司系山西省综合性国有投资建设集团公司，前身是山西建筑工程（集团）总公司，2017年9月20日完成公司制改革。山西建投是中国企业500强、中国承包商80强和中国建筑业竞争力200强企业，旗下拥有7个建筑、3个市政公用、1个石油化工共11项工程施工总承包特级资质及11项行业甲级设计资质，130多项总承包与专业承包一级资质。 山西建投拥有各类经营管理和专业技术人才1.8万余人，设有院士工作站、博士后科研工作站、土木工程研究生教育创新中心和国家级企业技术中心。集团具有建筑科研设计、建筑施工、设备安装、建筑装饰、道桥施工、工程机械制造、建筑构件生产、房地产开发、工程监理、物资供应、机具租赁、建筑技术开发和技术教育培训等综合实力，能够独立承包国内外大型高端工程，实施BOT、EPC、PPP模式建设项目等。',
    backgroundImage:`url(${baseUrl}anli.png)`
  }
}