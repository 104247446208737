import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/业主投资方/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/业主投资方/1.png`,
    describe: "",
    describeImg: `${global.config.url}/newgrand/picture/img/业主投资方/6.png`,
    background: "linear-gradient(360deg, #fff 0%, #DFFEFE 100%)",
    title: "业主投资方全过程 项目管理一体化平台",
    hiddenBtn: true,
    desImgStyle: {
      width: '100%',
    },
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    imgSrc: `${baseUrl}2.png`
  },

  sessionC: {
    imgSrc: `${baseUrl}3.png`
  },
  sessionD: {
    imgSrc: `${baseUrl}4.png`
  },
  sessionE: {
    imgSrc: `${baseUrl}5.png`
  }
}