/*
 * @Author: your name
 * @Date: 2019-12-16 13:51:06
 * @LastEditTime : 2020-01-09 13:57:16
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\partner\components\logo\Logo.js
 */
import React from 'react';
//import './index.css';
import './logo.less'


class Logo extends React.Component {
    render() {
        return (
            <div className="partner">
                <div className="logo">
                    <div className="logo-icon">
                        <img alt="" src={this.props.img}></img>
                    </div>
                    <div className="logo-text" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
                </div>
            </div>
        );
    }
}

export default Logo;