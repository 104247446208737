/*
 * @Author: your name
 * @Date: 2020-01-06 16:51:29
 * @LastEditTime : 2020-01-06 16:58:57
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\overview\projectService\ProjectService.js
 */
import '../../../global.js';
import React from 'react';
import './project-service.less';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Show from '../components/show/Show'

class ProjectService extends React.Component {

    handleClick(address) {
        this.props.history.push('/' + address);
    }


    render() {
        return (
            <div>
                <Head />
                <div className="project-service">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"律师行业解决方案"} link={this.handleClick.bind(this, "lawyerProfession")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%9C%8D%E5%8A%A1/%E5%BE%8B%E5%B8%88%E8%A1%8C%E4%B8%9A%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"软件服务与系统集成行业解决方案"} link={this.handleClick.bind(this, "softwareService")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%9C%8D%E5%8A%A1/%E8%BD%AF%E4%BB%B6%E6%9C%8D%E5%8A%A1%E4%B8%8E%E7%B3%BB%E7%BB%9F%E9%9B%86%E6%88%90%E8%A1%8C%E4%B8%9A%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"全过程工程咨询服务解决方案"} link={this.handleClick.bind(this, "processConsulting")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%9C%8D%E5%8A%A1/%E5%85%A8%E8%BF%87%E7%A8%8B%E5%B7%A5%E7%A8%8B%E5%92%A8%E8%AF%A2%E6%9C%8D%E5%8A%A1%E8%A7%A3%E5%86%B3%E6%96%B9%E6%A1%88/banner.png"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default ProjectService;