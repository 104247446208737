/*
 * @Author: your name
 * @Date: 2019-12-12 09:40:26
 * @LastEditTime : 2020-01-09 13:13:39
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\machinery-management\MachineryManagement.js
 */

import React from 'react';
import '../../../global';
//import './index.css';
import './project-manage.less';
import ani from '../../../transition/animation/animation';
//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import TitleSize40Center from '../../digitalSite/components/TitleSize40Center'
import Describe from '../../digitalSite/components/Describe'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="left">
                        <div className="first-page-left-margin">
                            <div className="first-page-group">
                                <div>
                                    <TitleSize40Center text={"项目管控中心解决方案"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="first-page-img"></div>
                    </div>
                </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"方案介绍"} />
                <Describe text={"项目管控中心主要面向决策层和有需要的管理层，对项目和管理过程中的动态情况进行跟踪和报表统计。从各个维度和角度进行数据的挖掘，使领导层能对项目的进度、招投标情况、进度、成本、合同、资金的情况进行跟踪和查询。<br/>"+
                                "项目管控中心能够自动加载施工过程中的信息，同时既支持单项目分析，也支持多项目分析。分析的内容非常广泛，其中单项目分析平台包括项目基本信息、项目合同执行情况、项目人员、成本分析多算对比表、风险管控、供方台账、设备台账、质量安全现场检查记录、质量安全目标、项目进度、材料库存以及消耗台账、质量安全图片等内容。多项目分析平台包括项目台账、承包分包合同执行情况、项目进度产值、项目人力、质量安全监控、成本分析、设备台账、风险管控、物资消耗等内容。"} />
                <div className="interval-50"></div>
                <div className="second-box">
                    <div className="box-img">
                        <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/6-1.png"}></img>
                    </div>
                    <div className="interval-col-30"></div>
                    <div className="box-img">
                        <img alt="" src={global.config.url + "/newgrand/picture/img/app/pi/6-2.png"}></img>
                    </div>
                </div>
                <div className="interval-50"></div>
                <Title text={"方案价值"} />
                <Describe text="项目管控中心面向企业中高层管理人员，实现了企业级的项目管控分析功能。满足对项目各方面业务监控的需求，能有效帮助施工企业提升企业综合项目管理能力，提升项目实施的质量。" />
                <div className="interval-154"></div>
            </div>
        );
    }
}


//网页主模块
class ProjectManage extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("title-40-center", "moveup20-initial");
        ani.initclass("button-y", "moveup20-initial");
        ani.initclass("first-page-img", "moveleft100-init");

        ani.initclass("fifth-page-card", "moveup100-init");
        ani.initclass("describe", "moveup100-init");
        ani.initclass("title", "opacity-initial");

        ani.initclass("sixth-page-card", "moveright100-center-init")
        ani.initclass("sixth-page-card-textcard", "moveleft100-center-init")
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("title-40-center", 20, "moveup20");
        ani.getClassHight("button-y", 20, "moveup20-text");
        ani.getClassHight("first-page-img", 20, "moveleft100");
        

        ani.getClassHight("fifth-page-card", 20, "moveup100");
        ani.getClassHight("describe", 20, "moveup100");
        ani.getClassHight("title", 0, "opacity-change");

        ani.getClassHight("sixth-page-card", 20, "moveright100-center-notime");
        ani.getClassHight("sixth-page-card-textcard", 20, "moveleft100-center-notime");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="project-manage">
                    <FirstPage />
                    <SecondPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default ProjectManage;