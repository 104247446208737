/*
 * @Author: your name
 * @Date: 2019-12-11 11:24:51
 * @LastEditTime : 2019-12-23 09:50:17
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\digitalSite\components\ButtonYellow.js
 */
import React from 'react';
//import './index.css';
import './components.less'


/**
 * @description 黄底白字的按钮 
 *              需要提供参数: 按钮文本
 * @author chenyutan
 * @date 2019-12-11
 * @class TitleSize40
 */
class ButtonYellow extends React.Component {
    render() {
        return (
            <div className="digital-site">
                <div className="button-y">
                    <div className="button-yellow">
                        <div className="interval-13"></div>
                        <div className="button-yellow-text">{this.props.text}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ButtonYellow;