import React from "react";
// import Axios from "axios";
// import { Link } from "react-router-dom";
// import $ from "jquery";

// 组件
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import IconItem from "../../../new-components/IconItem";
import IconTitleItem from "../../../new-components/IconTitleItem";
import ContactBottom from '../../../new-components/ContactBottom';

// 高阶组件
import RenderWithTitle from '../../../high-orders/RenderWithTitle';

import './index.less';

// 配置
import config from './config';
import "../../../global.js";

function SessionC(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [];
  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconItem className="item" key={currItemConfig.key} describe={currItemConfig.describe} iconStyle={{
          backgroundImage: config.default.backgroundImage,
          backgroundPosition: `calc(${-10 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-10 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
        }} />))
      !currItemConfig && (currRow.push(
        <IconItem className="item" key={colIndex} describe={""} />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="session-c"> {inner}</div>
}

function SessionE(config) {
  const colCount = config.colCount;
  const rowCount = config.rowCount;

  const inner = [];
  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconTitleItem key={currItemConfig.key} describe={currItemConfig.describe} title={currItemConfig.title}
          iconStyle={{
            backgroundImage: config.default.backgroundImage,
            backgroundPosition: `calc(${-25 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-25 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
          }}
        />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return (<div className="session-e">{inner}</div>)
}

const SessionCWithTitle = RenderWithTitle(SessionC, "产品特点", 5, 2);

const SessionDWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionD.imgSrc} alt="" /></div>, "产品模块图", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

const SessionEWithTitle = RenderWithTitle(SessionE, "系统模块", { backgroundColor: '#F2F5F6' });

export default () => {
  return (
    <div className="new-GE-page">
      <Head />

      <Abstract {...config.sessionA} />

      <SessionCWithTitle {...config.sessionC} />

      <SessionDWithTitle {...config.sessionD} />

      <SessionEWithTitle {...config.sessionE} />

      <ContactBottom />

      <Bottom />
    </div>
  )
};