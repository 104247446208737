import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/1b/`;
export default {
  sessionA: {
    describe: '资金中心实现从资金预算、资金计划、资金结算到资金核算的完整的资金管理循环；全面监控企业现金流量，减少资金在途时间；利用账户管理，实现集团内部资金融通，降低资金沉淀，提升资金使用效率。'
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '业务、税务、财务一体化'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '完整的账户管理'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '企业级资金监控平台'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '全面资金计划管理'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '支持票据池业务'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },
  sessionC: {
    imgSrc:`${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount:2,
    rowCount:3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 5, y: 0 },
        title: '资金管理更集中',
        describe: '集团企业能够充分调配内部需求，高度集中内部闲置资金，将有限的资金资源用到最利于企业发展的生产经营活动中。'
      },
      {
        key: 2,
        iconPosition: { x: 6, y: 0 },
        title: '费用管控更规范',
        describe: '合理安排调配内部资金余缺，降低集团财务费用；开展内部结算，杜绝体外循环，节约部结算费用。'
      },
      {
        key: 3,
        iconPosition: { x: 7, y: 0 },
        title: '加强资金监控',
        describe: '加强资金预算，在资金流动上做到事前预算、事中控制和事后分析；健全资金管理制度，加强资金流出控制，做到集团资金安全阀。'
      },
      {
        key: 4,
        iconPosition: { x: 8, y: 0 },
        title: '提高资金使用效率',
        describe: '充分利用商业银行提供的网上结算功能，加快资金流动；建立企业内部资金网上银行系统，提高企业内部资金信息传递效率。'
      }
    ]
  },
}