/*
 * @Author: your name
 * @Date: 2020-01-13 10:28:18
 * @LastEditTime: 2022-01-13 11:31:31
 * @LastEditors: qyy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\ungrouped\caseDetails\CaseDetails.js
 */
import React from 'react';
//import './index.css';
import './case-details.less';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Axios from 'axios';

class CaseDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cases: {},
        }
    }

    componentDidMount() {
        if (typeof (this.props.match.params) != "undefined") {
            console.log(this.props.match.params.id);
            var id = this.props.match.params.id;
        }
        console.log(id);

        Axios.get(global.config.serverUrl + '/CaseInfo/LoadInfo/' + id)
            .then((cases) => {
                console.log(cases.data);
                this.setState({ cases: cases.data })
            })
            .catch((error) => {
                console.log(error);
            });

    }

    render() {
        return (
            <div>
                <Head />
                <div className="case-details">
                    <div className="bg1"></div>
                    {/**方案介绍模块 */}
                    <div className="title">{this.state.cases.CaseTitle}</div>
                    <div style={{ height: 'calc(50vw/14.4)' }} />
                    <div className="case-component">
                        <div className="case-icon" style={{ visibility: 'hidden', }}></div>
                        <div className="case-main">
                            {/* <div className="case-title">方案介绍</div> */}
                            <div className="case-text" dangerouslySetInnerHTML={{ __html:
                                this.state.cases.CaseContentAppHtml && this.state.cases.CaseContentAppHtml.replaceAll('http://47.96.72.83:8086', global.config.serverUrl)
                                }}>
                            </div>
                        </div>
                    </div>
                    <div className="bg2"></div>
                    {/* <div className="interval-200" /> */}
                </div>
                <Bottom />
            </div>
        );
    }
}

export default CaseDetails;