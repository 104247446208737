export const contentInfo = [
  {
    title: "隐私政策",
    update: "最近更新日期:2022年2月15日	生效日期:2022年2月22日",
  },
  {
    title: "APP隐私政策",
    update: "最近更新日期:2022年04月05日	生效日期:2022年04月12日",
  },
  {
    title: "新中大用户使用协议",
    update: "最近更新日期:2022年2月20日	生效日期:2022年2月20日",
  },
];

export const contentList = [
  `引言
您的信任对新中大及或其关联公司（以下简称“我们”）非常重要，我们深知用户信息安全的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的用户信息安全可控。鉴此，新中大制定《新中大隐私政策》（下称“本隐私政策”）并提醒您：
本隐私政策适用于新中大及或其关联公司的产品及或服务。如新中大关联公司（范围详见定义部分）的产品及或服务中使用了新中大提供的产品及或服务（例如使用新中大账号登录）但未设置独立的隐私政策的，或者新中大关联公司的产品及或服务未设置单独的隐私政策的，则本隐私政策同样适用于该部分产品及或服务。
需要特别说明的是，本隐私政策不适用于其他第三方向您提供的产品及服务，第三方向您提供的产品及服务适用其向您说明的隐私政策。
>在使用新中大及或其关联公司的各项产品及或服务前，请您务必仔细阅读并透彻理解本隐私政策，特别是以粗体下划线标识的条款，您应重点阅读，请在确认充分理解并同意后使用新中大及或其关联公司的产品及或服务。一旦您开始使用新中大及或其关联公司的各项产品及或服务，即表示您已充分理解并同意本隐私政策。如对本隐私政策内容有任何疑问、意见或建议，您可通过本隐私政策“九、如何联系我们”中披露的联系方式与我们联系。

第一部分 定义
用户：指成功注册激活新中大账号的自然人、法人或其他组织，和签署了本协议并使用新中大关联公司账号登录新中大平台的自然人、法人或其他组织。
新中大：指杭州新中大科技股份有限公司。
关联公司：指杭州新中大科技股份有限公司在本隐私政策中披露的关联公司，具体请点击
新中大平台：指新中大及或其关联公司运营的，为您提供各项产品及或服务的网站、客户端、移动应用程序、微信公众号、微信小程序等渠道（包括未来技术发展出现的新的形态）。
产品及或服务：指新中大及或其关联公司通过新中大平台为您提供的各项产品及或服务。
平台规则：指在新中大平台上已经发布的及未来根据需要不定期更新的或新发布的所有服务规则、公告及各项产品及或服务的流程说明、使用方法规则介绍等。
个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们将在本隐私权政策中对所涉及的个人信息以粗体方式进行显著标识。
个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息（我们将在本隐私政策中对所涉及的个人敏感信息以粗体和加*号方式进行显著标识）。
企业信息：指企业从事生产经营活动过程中形成的信息，以及新中大及或其关联公司在为企业提供产品及或服务过程中产生的能够反映企业状况的信息。
除另有约定外，本隐私政策所用定义与《新中大用户使用协议》中的定义具有相同的涵义。

第二部分 隐私政策
本隐私政策部分将帮助您了解以下内容：
一、我们如何收集和使用用户信息
二、我们如何使用Cookie和同类技术
三、我们如何共享、转让、公开披露用户信息四、我们如何保护用户信息
五、您如何管理用户信息
六、我们如何处理儿童的个人信息七、用户信息如何在全球范围转移八、本隐私政策如何更新
九、如何联系我们

一、我们如何收集和使用用户信息
在您使用我们的产品及或服务时，我们需要收集和使用的您的用户信息包括如下两种：
1、为实现向您提供我们产品及或服务的基本功能，您须授权我们收集、使用的必要信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及或服务；
2、为实现向您提供我们产品及或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供相应信息，您将无法正常使用相应附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及或服务的基本功能。
您理解并同意：
1、我们致力于打造多样的产品及或服务以满足您的需求。因我们向您提供的产品及或服务种类众多，且不同用户选择使用的具体产品及或服务内容存在差异，相应的，基本附加功能及收集、使用的用户信息类型、范围等会有所区别，请以具体的产品及或服务功能为准；
2、为给您带来更好的产品及或服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的用户信息或变更用户信息使用目的或方式。对此，我们将通过更新本隐私政策、弹窗、页面提示、邮件通知等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过本隐私政策“九、如何联系我们”中披露的联系方式与我们联系，我们会尽快为您作出解答。
我们会出于本隐私政策所述的以下目的，收集和使用您的用户信息：
（一）基础服务
1、注册激活新中大账号、登录新中大平台并完成产品及或服务的认证
您注册激活新中大账号、登录新中大平台并完成产品及或服务的认证过程中，您可能需要向我们提供相关用户信息，其中个人信息包括个人真实姓名*、身份证件号码*、个人生物识别信息*、电话号码*、电子邮箱地址*；企业信息包括企业名称、企业营业执照信息、企业联系人信息、企业联系方式（电话号码、电子邮箱），这些基本用户信息用于创建新中大账号。您在注册激活账号、登录新中大平台中可以提供更多额外信息补全用户资料，例如您的个人兴趣爱好、教育背景、工作职业等以及企业所属行业、主营业务、主要产品等，这些信息将有助于我们给您提供更个性化的服务，但不予提供并不会影响到您使用我们的基础服务。
在注册激活新中大账号、登录新中大平台并完成产品及或服务的认证过程中，我们还可能需要您主动上传主体资格证明文件照片（包括个人身份证件、企业注册文件），我们可能会根据您提供的上述信息校验您的用户身份，确保我们是在为您提供服务。
2、我们在您使用产品及或服务过程中收集的信息
为向您提供更契合您需求的页面展示和搜索结果、了解产品及或服务适配性、识别账号异常状态、提供软件安装或更新服务并获取设备安装权限、向您提供产品、咨询或技术支持，我们会收集和使用关于您使用的产品及或服务以及使用方式的信息并将这些信息进行关联，这些信息包括：
设备信息：我们会根据您在新中大平台上和产品及或服务使用中授予的具体权限，接收并记录您所使用的设备相关信息
（例如设备型号、操作系统版本、设备设置、唯一设备标识符（如IMEI号、MAC地址）、应用列表信息）、设备所在位置相关信息（例如IP地址、GPS位置以及能够提供相关信息的Wi-Fi接入点、蓝牙和基站）。
日志信息：当您登录新中大平台、使用我们的产品及或服务时，我们会自动收集您对我们产品及或服务的详细使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、电信运营商、使用的语言、访问日期和时间及您访问的网页记录等。
请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息。当您与我们联系时，我们可能会保存您的通信通话记录和内容或您留下的联系方式等信息，以便与您联系或帮助您解决问题，或记录相关问题的处理方案及结果。
（二）附加服务
为了向您提供更优质的产品及或服务，我们可能需要收集下述信息。如果您拒绝提供下述信息，不影响您正常使用本隐私政策第一部分“（一）”项所描述的基础服务，但我们无法向您提供某些特定功能和服务。
1、当您使用定位服务时
当您开启设备定位功能并使用我们提供的相关服务时，我们会收集有关您的位置信息（包括IP地址、GPS位置信息以及能够提供相关信息的WI-FI接入点、蓝牙、基站等传感器信息、您提供的账号信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息），您可以通过关闭定位功能，停止对您的地理位置信息的收集
2、为您展示和推送产品及或服务时
为改善我们的产品及或服务、向您提供个性化的信息搜索及产品及或服务推荐服务，我们会根据您的浏览及搜索记录、位置信息、登录使用信息，提取您的浏览、搜索偏好、行为习惯等特征，基于特征标签进行间接人群企业画像并展示、推送信息。
如果您不想接受我们给您发送的商业广告，您可通过短信或邮件提示回复退订或我们提供的其他方式进行退订。
3、当您使用涉及支付的功能时
我们的一些产品及或服务需要付费才能使用，当您需要使用这些付费产品及或服务时，您需要提供您的银行卡信息及或通过合作的第三方支付机构（微信支付、支付宝支付等）所提供的支付服务完成。费用支付通过合作的第三方支付机构（微信支付、支付宝支付等）所提供的支付服务完成，第三方支付服务有可能使用生物识别信息用于付款验证，您在使用第三方支付服务的时候请阅读该第三方支付机构的隐私政策，如对于该约定不同意，请您及时停止使用该第三方支付服务。
您可以为其他人订购产品及或服务，您需要提供该实际订购人的前述用户信息。向我们提供该实际订购人的前述用户信息之前，您需确保您已经取得其授权同意。为确认交易状态及为您提供交易争议解决服务，我们会通过您基于交易所选择的交易对象、交易方式、第三方支付机构、物流公司等收集与交易进度相关的您的交易、支付、物流信息，或将您的交易信息共享给上述服务提供者。
4、客服及争议处理
当您与我们联系或提出问题处理、争议纠纷处理申请时，为了保障您的账号及系统安全，我们需要您提供必要的用户信息以核验您的用户身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息 （地理位置）、存储、摄像头（相机）、相册
（图片库）、麦克风（语音）、通讯录及或日历的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置或新中大平台上逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关用户信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关用户信息，也无法为您提供该权限所对应的服务。
（三） 为您提供安全保障
为提高您使用我们及我们合作伙伴产品及或服务的安全性，保护您或其他用户或公众的人身、财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本隐私政策、平台规则的情况，我们可能使用或整合您的用户信息、订购信息、登录使用信息、设备信息、有关网络日志以及我们合作伙伴取得您授权或依据法律共享的信息，来综合判断您的账号安全及经营风险、进行实名认证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
（四）其他用途
1、若你提供的信息中含有其他用户的用户信息，在向我们提供这些用户信息之前，您需确保您已经取得合法的授权。
2、若我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的用户信息，均会事先获得您的同意。
若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集您的信息，并向您告知共享的信息内容，且涉及个人敏感信息、企业重要信息的在提供给我们使用前需经过您的明确确认，要求第三方对用户信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求该第三方承担相应法律责任；同时，我们的专业安全团队对用户信息会进行安全加固（包括个人敏感信息、企业重要信息报备、加密存储、访问权限控制等）。我们会使用不低于我们对自身用户信息同等的保护手段与措施对间接获取的用户信息进行保护。
（五）征得授权同意的例外
根据相关法律法规规定，以下情形中收集、处理用户信息无需征得您的授权同意： 1、与国家安全、国防安全有关的；
2、与公共安全、公共卫生、重大公共利益有关的；
3、与犯罪侦查、起诉、审判和判决执行等有关的；
4、出于维护用户或其他个人的生命、财产企业的财产等重大合法权益但又很难得到您亲自同意的；
5、所收集的用户信息是您自行向社会公众公开的；
6、从合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道；
7、根据您的要求签订合同所必需的；
8、用于维护所提供的产品及或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
9、为合法的新闻报道所必需的；
10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的用户信息进行去标识化处理的；
11、法律法规规定的其他情形。
请知悉，您同意我们根据适用的法律，若我们对用户信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定用户且不能复原，或我们可能会对收集的信息（包括IP地址、操作系统版本信息、登录记录、指令信息、环境开通及关闭时间、操作行为信息）进行匿名化地研究、统计分析和预测，用于改善新中大平台的内容和布局，为商业决策提供产品及或服务支撑，以及改进我们的产品及或服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。
如我们停止新中大平台的运营、产品及或服务的提供，我们将及时停止继续收集、处理用户信息的活动，将停止运营、提供的通知以逐一送达或公告的形式通知您，对所持有的用户信息进行删除或匿名化处理。
二、我们如何使用Cookie和同类技术
（一）Cookie（用户终端数据存储）
为确保新中大平台相关的网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储相关信息：这些信息可能是Cookie、Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
请您理解，我们的某些产品及或服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝我们的Cookie，但拒绝我们的Cookie在某些情况下可能会影响您安全访问网站和使用我们提供的产品及或服务。
如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。但如果您这么做，在某些情况下可能会影响您安全访问新中大平台相关的网站，且可能需要在每一次访问新中大平台相关的网站时更改用户设置。
（二）网站信标和像素标签
除Cookie外，我们还会在新中大平台相关的网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至新中大平台相关的网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品及或服务偏好以便于我们主动改善用户体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
（三）Do Not Track（请勿追踪）
很多网络浏览器均设有Do Not Track功能，该功能可向网站发布Do Not Track请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了Do Not Track，那么新中大平台相关的所有网站都会尊重您的选择。您还可以使用您的浏览器设置来删除或阻止您计算机上的Cookie。
三、我们如何委托处理、共享、转让、公开披露用户信息
（一）委托处理
新中大平台、我们产品及或服务中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供技术支持。
对我们委托处理用户信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的要求、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。
（二）共享
我们不会与我们服务提供商以外的公司、组织和个人共享用户信息，但以下情况除外：
1、在获取明确同意的情况下共享：获得您的单独明确同意后，我们会与其他方共享您的信息。
2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的信息。
3、在您主动选择情况下共享：您通过新中大平台订购我们的产品及或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关产品及或服务的提供者，以实现您的交易及售后服务需求。
4、关联公司之间共享：因您可以使用新中大账号登录新中大平台、使用我们的产品及或服务，为便于我们基于统一的账号共同向您提供产品及或服务，推荐您可能感兴趣的信息或保护我们或其他用户、公众的人身财产安全免遭侵害，您的信息可能会在我们之前共享。我们只会共享必要的用户信息（如为便于您使用新中大账号使用新中大关联公司的产品及或服务，新中大会向关联公司共享您必要的账号信息），如果我们共享您的个人敏感信息、企业重要信息或关联公司改变用户信息的使用及处理目的，将再次征求您的授权同意。
了解此情形中目前涉及的公司、组织和个人，请点击此处 。
5、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些产品及或服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些信息，如为了提供更有效率的消息推送服务，我们集成了个推SDK及华为、小米等手机厂商的消息推送服务，以便为您提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的信息，并且只会共享提供产品及或服务所必要的用户信息。我们的合作伙伴无权将共享的用户信息用于与产品及或服务无关的其他用途。
了解此情形中目前我们的授权合作伙伴包括的类型，请点击此处。
对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。
我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。了解此情形中目前我们使用的第三方SDK收集信息的情况，请点击此处
（三）转让
我们不会将您的信息转让给任何公司、组织和个人，但以下情况除外：
1、在您点击同意本隐私政策并使用新中大关联公司账号登录新中大平台时，您同意新中大关联公司将您的信息转让
给新中大，以便我们对用户的账号和其他信息进行统一管理、加强用户信息安全保护并为用户提供更加优质、高效、全面的产品及或服务。
2、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的信息。
3、在我们产品及或服务的提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您信息的公司、组织和个人继续受本隐私政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
（四）公开披露
我们仅会在以下情况下，公开披露用户信息：
1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的信息；
2、如果我们确定您出现违反法律法规或严重违反本隐私政策、平台规则的情况，或为保护我们及其他用户、公众的人身财产安全免遭侵害，我们在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，可能会公开披露您的信息。
（五）委托处理、共享、转让、公开披露用户信息时事先征得授权同意的例外
以下情形中，委托处理、共享、转让、公开披露用户信息无需事先征得用户的授权同意： 1、与国家安全、国防安全有关的；
2、与公共安全、公共卫生、重大公共利益有关的；
3、与犯罪侦查、起诉、审判和判决执行等有关的；
4、出于维护用户或其他个人的生命、财产企业财产等重大合法权益但又很难得到用户亲自同意的；
5、用户自行向社会公众公开的信息；
6、从合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道。
根据法律规定，共享、转让经去标识化处理的用户信息，且确保数据接收方无法复原并重新识别该用户的，不属于用户信息的对外委托处理、共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
四、我们如何保护用户信息安全
（一）我们已采取符合业界标准、合理可行的安全防护措施保护用户信息安全，防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL（Secure Socket Layer）协议加密保护；我们同时对我们提供HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机  制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
（二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升新中大平台及产品及或服务的安全性。
（三）我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本隐私政策所述目的所需的期限内保留用户信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：
1、完成与您相关的产品及服务使用目的、维护相应业务记录、应对您可能的查询或投诉；
2、保证我们为您提供产品及或服务的安全和质量；
3、您是否同意更长的留存期间；
4、是否存在保留期限的其他特别约定。
在用户信息超出保留期间后，我们会根据适用法律的要求删除用户信息，或使其匿名化处理。
（四）互联网并非绝对安全的环境，我们强烈建议您不要使用非我们推荐的通信方式发送用户信息。您可以通过新中大平台建立联系和相互分享。当您通过新中大平台创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您使用的产品及或服务内容、联络方式、交流信息或分享内容等相关信息的第三方。
请注意，您在新中大平台上自愿共享甚至公开分享的信息，可能会涉及您或第三方的信息甚至个人敏感信息、企业重要信息。请您更加谨慎地考虑，是否在新中大平台上共享甚至公开分享相关信息。
请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
（五）我们将不定期更新并公开安全风险、用户信息安全影响评估报告等有关内容，您可通过我们公告方式获得。
（六）在不幸发生用户信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信 函、电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。
同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
五、您如何管理用户信息
您可以通过以下方式访问及管理您的信息：
（一）访问您的信息
您有权访问您的信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的信息：
账号信息——如果您希望访问或编辑您的账号中的基本用户信息、更改您的账号密码、添加安全信息或关闭您的账号等，您可以通过登录新中大云服务平台个人用户中心进行账号更改
如果您无法通过上述路径访问该等用户信息，您可以随时通过本隐私政策中披露的联系方式与我们取得联系。我们将在 15天内回复您的访问请求。
对于您在使用我们的产品及或服务过程中产生的其他信息，我们将根据本条“（八）响应您的上述请求”中的相关安排向您提供。
（二）更正或补充您的信息
当您发现我们处理的关于您的信息有错误时，您有权要求我们做出更正或补充。您可以通过“（一）访问您的信息”中列明的方式提出更正或补充申请。
（三）删除您的信息
您可以通过“（一）访问您的信息”中列明的方式删除您的部分信息。在以下情形中，您可以向我们提出删除信息的请求：
1、如果我们处理信息的行为违反法律法规；
2、如果我们收集、使用您的信息，却未征得您的明确同意；
3、如果我们处理信息的行为严重违反了与您的约定；
4、如果您不再使用我们的产品及或服务，或您主动注销了账号；
5、如果我们永久不再为您提供产品及或服务。
若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的信息的第三方，要求其及时删除，除非法律法规另有规定，或这些第三方获得您的独立授权。
当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
（四）改变您授权同意的范围
我们产品及或服务的每个业务功能可能需要一些基本用户信息才能得以完成（见本隐私政策“第二部分”）。除此之外，对于额外用户信息的收集和使用，您可以通过登录账号、通过与我们联系给予或收回您的授权同意。
当您收回同意后，我们将不再处理相应的用户信息。但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。
（五）用户注销账号
您可以与我们联系提交账号注销申请。
在您主动注销账号之后，我们将停止为您提供产品及或服务，根据适用法律的要求删除您的信息，或使其匿名化处理。
（六）获取用户信息副本
您有权获取您的信息副本，您可以通过以下方式自行操作：我们在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的信息副本传输给您指定的第三方。
（七）约束信息系统自动决策
在我们产品及或服务的某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害我们商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
（八）响应您的上述请求
为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
我们将在15天内做出答复。如您不满意，还可以通过联系我们发起投诉。
对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
在以下情形中，按照法律法规要求，我们将无法响应您的请求： 1、与国家安全、国防安全有关的；
2、与公共安全、公共卫生、重大公共利益有关的；
3、与犯罪侦查、起诉、审判和执行判决等有关的；
4、有充分证据表明用户存在主观恶意或滥用权利的；
5、响应用户的请求将导致用户或其他个人、组织、公司的合法权益受到严重损害的；
6、涉及商业秘密的。
六、我们如何处理儿童的个人信息
如果没有父母或监护人的同意，儿童不得创建自己的新中大账号。如您为儿童的，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的产品及或服务或向我们提供信息。
对于经父母或监护人同意使用我们的产品及或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。
我们将不满 14 周岁的任何人均视为儿童，如果当地法律和习俗对儿童的定义不同，则适用当地法律和习俗的定义。
七、用户信息如何在全球范围转移
我们在中华人民共和国境内收集和产生的用户信息，将存储在中华人民共和国境内。
八、本隐私政策如何更新
我们的隐私政策可能变更。
未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在新中大平台上发布对隐私政策所做的任何变更。
对于重大变更，我们还会提供更为显著的通知（包括我们会通过我们公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）。
本隐私政策所指的重大变更包括但不限于：
1、我们的产品及或服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
3、用户信息共享、转让或公开披露的主要对象发生变化；
4、您参与用户信息处理方面的权利及其行使方式发生重大变化；
5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化；
6、用户信息安全影响评估报告表明存在高风险。
九、如何联系我们
我们设有专门法务合规部门监督用户信息保护事宜，有关本隐私政策或我们的隐私措施相关问题可通过如下方式联系我们，我们将在15天内回复您的请求：
用户信息保护负责人邮箱：CSC@newgrand.cn；
法务联系电话：(0)571-88270588
客服联系电话：(0)95105-820
联系地址：浙江省杭州市拱墅区祥园路88号N座9楼
如果您对我们的回复不满意，特别是您认为我们的用户信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。`,
  `引言
您的信任对杭州新中大科技股份有限公司（以下简称“我们”）非常重要，我们深知用户信息安全的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的用户信息安全可控。
在使用我们的各项产品或服务前，请您务必仔细阅读并透彻理解本隐私政策，特别是以粗体\粗体下划线标识的条款，您应重点阅读，请在确认充分理解并同意后使用我们的产品或服务。一旦您开始使用我们的各项产品或服务，即表示您已充分理解并同意本隐私政策。如对本隐私政策内容有任何疑问、意见或建议，您可通过本隐私政策“十、如何联系我们”中披露的联系方式与我们联系。

第一部分 定义
关联公司：指杭州新中大科技股份有限公司在本隐私政策中披露的关联公司，具体请点击。
企业用户：指注册、登录、使用新中大产品及服务并获得管理权限的个人或组织，包括但不限于法人以及政府机构、合伙企业、个体工商户等非法人组织（下称“企业用户”）；企业用户可以通过新中大产品或服务平台（下称“新中大平台”）创建其工作团队，邀请并授权个人用户加入其工作团队成为其最终用户。
企业管理员：指经企业用户指定，拥有企业用户管理后台系统操作权限的自然人。
个人用户：指被企业用户邀请加入企业工作团队，注册使用新中大产品或服务的自然人用户，也包括自行注册新中大云账号并登陆使用新中大产品或服务的自然人用户。
新中大产品或服务：指我们为您提供的各项产品或服务，包括新中大产品APP（i8、i8c、i8e、i8i、i6P、i6、i6s、A3、GE）、新中大云服务平台（网址为 www.ns820.com）、新云汇（网址为 www.inewgrand.cn）、新中大服务号、以及将前述产品功能或服务作为第三方服务集成在我们关联公司产品中，但不包括其他第三方通过前述网页或客户端向您提供的产品或服务。
个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。个人敏感信息包括身份证件号码、个人生物识别信息、银行账号、通信记录和内容、财产信息、征信信息、行踪轨迹、住宿信息、健康生理信息、交易信息、14岁以下（含）儿童的个人信息。
企业信息：指企业从事生产经营活动过程中形成的信息，以及新中大及或其关联公司在为企业提供产品及或服务过程中产生的能够反映企业状况的信息。
除另有约定外，本隐私政策所用定义与《新中大隐私政策》中的定义具有相同的涵义。

第二部分 隐私政策
本隐私政策部分将帮助您了解以下内容：
一、适用范围
二、我们如何收集和使用用户信息
三、我们如何使用Cookie和同类技术
四、我们如何共享、转让、公开披露用户信息
五、我们如何保护用户信息
六、您如何管理用户信息
七、我们如何处理儿童的个人信息
八、用户信息如何在全球范围转移
九、本隐私政策如何更新
十、如何联系我们

一、适用范围
1、您需要注册新中大企业号才能使用我们产品或服务中的特定功能。您在注册的时候应当同意新中大的用户协议与隐 私政策，如对于本隐私政策或新中大的隐私政策有任何疑问或意见，您可通过本隐私政策“十、如何联系我们”中披露的联系方式与我们联系，我们会尽快为您做出解答。
2、本隐私政策是《新中大隐私政策》 的组成部分，独立适用于上述定义中的新中大产品或服务，但本隐私政策不适用于其他第三方通过新中大产品网页或客户端向您提供的产品或服务，第三方向您提供的产品或服务适用其向您说明的隐私政 策。如我们关联公司的产品或服务中使用了新中大产品或服务但未设独立的隐私政策的，则本政策同样适用于该部分产品或服务。
二、我们如何收集和使用用户信息
我们会出于本隐私政策所述的以下目的，收集和使用您的用户信息：
（一）为您提供用户服务 1、基础用户服务
1)个人用户：为成为新中大个人用户，以个人用户身份激活并使用新中大云产品或服务，您需要向我们提供您的个人手机号码、密码以注册并创建新中大云账号，否则您将不能使用我们的产品或服务，我们将通过发送短信验证码的方式来验证您的身份是否有效。如您仅需浏览、搜索我们网站和产品页面展示的信息，或体验试用我们的产品或服务，您不需要注册新中大云账号和提供上述信息。
2)企业用户：为成为新中大企业用户，以实现企业组织数字化转型，您需要提供您的企业/组织名称创建企业用户账号，否则您不能以企业身份使用我们的产品或服务。
2、附加用户服务
1)企业认证：为遵循国家有关实名认证的规定，以及为了识别产品或服务的许可使用主体，您作为企业/组织管理员，通过企业授权书代表企业/组织提交特定信息和资料完成企业认证（包括营业执照、统一社会信用代码、企业组织名称、地址、法定代表人身份信息*这些企业基本信息）。
2)个人认证：在您尚未取得个人独资企业营业执照或其他企业主体资格证明文件之前，您可以选择使用个人认证，以便遵循国家有关实名认证的规定及用于识别产品或服务的许可使用主体。在您开展个人认证之时，您需要向我们提交您本人的身份证信息*。
3)完善信息：您可以通过新中大云服务平台（www.ns820.com）管理和完善您的个人信息和企业信息，届时我们会收集您填写或修改的您的个人真实姓名*、身份证件号码*、个人头像*、手机号码、电子邮箱地址；企业信息涉及企业名称、企业营业执照信息、企业联系人姓名、企业联系方式（电话号码、电子邮箱）。
（二）实现企业用户控制数据的功能
1、企业用户控制的数据
当您作为最终用户，注册新中大云e账号并加入企业/组织，具备企业用户身份的该企业/组织使用我们的产品或服务实现客户关系管理、企业日常经营功能时，我们会根据企业用户管理员的配置、操作和指令，记录最终用户使用我们产品或服务过程中提交或产生的信息和数据（下称 “企业用户控制数据”），企业用户控制数据可能包括：
1)您所在企业/组织分配给您的职位、隶属部门、办公邮箱、工作电话，为完成企业/组织经营管理而要求而由您或企业用户提供或产生的信息，包括：职员姓名、性别、生日、文化程度、手机号、座机号、所属部门/机构、职务、入职和离职日期、 住址、电子邮件。
2)您作为企业用户管理员/创始人使用我们的产品或服务、选购开通第三方产品或服务、销售产品与服务时的订单交易信息，以便向您展示并便于您对订单进行管理。
3)企业用户提交、使用我们产品或服务过程中产生的企业通讯录信息、客户通讯录、供应商通讯录、交易信息（包括企业用户下属员工的 任职信息、联系方式、个人身份信息；最终客户的联系人姓名、联系电话、联系地址信息。
4)企业用户基于客户关系管理（CRM）、经营管理目的，由企业管理员通过使用我们产品功能与服务，为个人用户自主配置专属账号，以便最终用户登录和使用我们产品具体功能与服务，这些专属账号由企业用户所有，您使用专属账号上传、录入、发布、传输、存储及其他使用行为产生的数据资料，也属于 企业用户控制数据。
5)  其他由个人用户明示授权同意企业组织控制的数据。

上述企业用户控制数据由企业用户管理与控制，企业用户在提供这些涉及个人的信息时，应当确保已经事先获得有关自然人的明确同意，且仅限于企业运营与管理目的收集和处理，并应充分告知自然人相关自然人收集其个人信息的目的、方式及使用方式等。
2、企业用户控制的数据权归属
您同意并理解，企业用户为上述 企业用户控制数据的控制者 并决定处理目的与方式。 我们仅根据企业用户管理员的指令，开通、管理和使用上述产品或服务处理企业用户控制数据或您的个人信息。您作为最终用户，理解并同意企业用户有权为了履行其与您签署的合同所必须，或者通过依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必须，有权作为个人信息处理者，处理您作为最终用户的个人信息，包括委托我们在开通、管理和使用我们产品或服务的目的和范围内，基于合理必要性，处理您的个人信息。
（三）为您提供附加服务
在以下附加功能中，您可选择是否授权我们收集、使用您的个人信息。如果您拒绝提供部分功能或服务所需的信息，您将无法使用相关功能或服务，但不影响您正常使用其他功能或服务。您可在您的设备设置或我们产品页面上逐项查看下述权限的状态，并可自行决定这些权限随时的开启或关闭。 但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
1、基于定位的服务：当您使用我们的蓝牙传输打印功能，您需要开启位置权限后，并授权我们访问获取您周围的可用的蓝牙打印设备；在添加拜访计划和记录拜访情况时，您需要授权我们访问您的位置信息，根据您的位置信息为您快速填充联系人地址信息、展示附近客户位置方便您选择拟拜访的客户、完成拜访客户的进店与离店打卡操作；在您通过我们的产品对接第三方考勤功能时，您需要授权我们访问您的位置信息，为您提供打卡签到功能。如您拒绝授权，我们将不能提供此服务。
2、基于相机/摄像头的附加服务：当您使用我们的扫码识别商品条形码、扫描二维码信息、扫描发票/单据、查询库存、拍摄客户门店、拍摄并上传头像功能时，您需要同意开启相机权限并授权我们使用相机扫码或拍照功能。如您拒绝授权，我们将不能提供此附加服务。
3、基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：当您使用我们的上传单据附件、拜访客户照片、上传记账凭证图片自动识别、扫描发票、云社区创新广场创意图片提交、服务提单上传附件图片、举报功能上传附件图片、上传头像、或通过“徐少春个人号”发送图片消息时，您需要开启相册权限并授权我们使用该相机功能上传您的照片/图片/视频，以实现存储、信息上传的功能。
4、基于设备信息的服务：为了适配您的设备页面、校验设备归属和用户身份、推送审核消息、选择和配置您的打印机完成单据打印功能、推送待办任务与业务消息提醒，您需要在同意隐私协议后授权我们收集设备信息（包含硬件型号，系统版本，安卓用户 ID、手机SN码）、网络信息（如运营商信息）。
5、基于蓝牙通信提供的附加服务：当您开单需打印单据小票信息时，您需要开启蓝牙通讯权限并授权我们使用蓝牙传输功能。如您拒绝授权，将不能使用此功能。
6、基于电话权限提供的附加服务：当您使用的是手持PDA设备（如优博讯、商米、新大陆品牌）时，我们将在您授予电话权限后收集本机序列码，以用于确认PDA许可授权信息并绑定改手持PDA设备；我们也会在您授权电话权限后为您提供扫码出入库功能。
7、为您提供展示、推送服务
为改善我们的产品或服务、向您提供个性化的信息搜索及产品或服务推荐服务，我们会根据您的浏览及搜索记录、位置信息、登录\使用信息、业务数据，提取您的浏览、搜索偏好、行为习惯等特征，基于特征标签进行间接人群\企业画像并展示、推送信息。如果您不想接受我们给您发送的个性化推荐信息，您可通过新中大APP“设置”功能，关闭个性化推荐，或通过本隐私政策第十条列明的其他联系方式，联系我们进行退订。
8、为您提供问答、评论、论坛等信息公开发布功能
您可以通过我们的云社区、公告、评论、直播、课程等信息发布功能公开发布信息，您公开发布时，我们会公开发布人的ID名称、账号、所在企业等信息。 如您公开发布的信息内容涉及您或他人的个人信息或敏感个人信息，您应当事先取得个人明确同意，涉及儿童的，应当事先取得其法定监护人的同意。
9、客服及争议处理
当您与我们联系或提出问题处理、争议纠纷处理申请时，为了保障您的账号及系统安全，我们需要您提供必要的用户信息以核验您的用户身份。
为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们会保存您与我们的通信\通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息）。
为了提供服务及改进服务质量的合理需要，我们还可能使用您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。
（四） 为您提供安全保障
为提高您使用我们及我们合作伙伴产品及或服务的安全性，保护您或其他用户或公众的人身、财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或本隐私政策、平台规则的情况，我们可能使用或整合您的用户信息、订购信息、登录使用信息、设备信息、有关网络日志以及我们合作伙伴取得您授权或依据法律共享的信息，来综合判断您的账号安全及经营风险、进行实名认证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
（五）其他用途
1、若我们将信息用于本隐私政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的用户信息，均会事先获得您的同意。
2、若我们从第三方处间接获取您的信息的，我们会 在收集前明确以书面形式要求该第三方在已依法取得您同意后收集您的信息，并向您告知共享的信息内容，且涉及个人敏感信息、企业重要信息的在提供给我们使用前需经过您的明确确认，要求第三方对用户信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求该第三方承担相应法律责任；同时，我们的专业安全团队对用户信息会进行安全加固（包括个人敏感信息、企业重要信息报备、加密存储、访问权限控制等）。我们会使用不低于我们对自身用户信息同等的保护手段与措施对间接获取的用户信息进行保护。
三、我们如何使用Cookie和同类技术
为使您获得更便捷的访问体验，您使用新中大产品或服务时，我们会通过采用各种技术收集和存储您的相关数据，包括使用小型数据文件识别您的身份，在您再次访问新中大产品或服务时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务，这么做是为了解您的使用习惯，帮您省去重复输入账户信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie、Flash Cookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如果您的浏览器或浏览器附加服务允许，您可以修改对 Cookie的接受程度或者拒绝使用Cookie，但拒绝Cookie在某些情况下您可能无法使用部分依赖于cookie的功能。
四、我们如何委托处理、共享、转让、公开披露用户信息
（一）委托处理
我们产品或服务中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供技术支持。 对我们委托处理用户信息的公司、组织和个人，我们会与其签署严格的保密协议，要求他们按照我们的要求、本隐私政
策以及其他任何相关的保密和安全措施来处理用户信息。目前，我们委托的授权合作伙伴包括以下类型：
1、广告、分析服务类的授权合作伙伴。我们会在采用行业通用的安全技术前提下，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。我们不会将您的个人身份信息委托合作伙伴处理。
2、供应商、服务提供商和其他合作伙伴。我们将信息委托给支持我们业务的供应商、服务提供商和其他合作伙伴处
理，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查。
我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理用户信息。了解此情形中目前我们使用的第三方SDK收集使用信息的情况，请点击此处查看新中大APP的《第三方SDK收集使用信息说明》。
（二）共享
我们不会与我们服务提供商以外的公司、组织和个人共享用户信息，但以下情况除外：
1、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的信息。
2、在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的信息。
3、在您主动选择情况下共享：您通过我们的平台订购产品与服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关产品或服务的提供者，以实现您的交易及售后服务需求。
4、关联公司之间共享：因您可以使用新中大账号登录新中大平台、使用我们的产品及或服务，为便于我们基于统一的账号共同向您提供产品及或服务，推荐您可能感兴趣的信息或保护我们或其他用户、公众的人身财产安全免遭侵害，您的信息可能会在我们之前共享。我们只会共享必要的用户信息（如为便于您使用新中大账号使用新中大关联公司的产品及或服务，新中大会向关联公司共享您必要的账号信息），如果我们共享您的个人敏感信息、企业重要信息或关联公司改变用户信息的使用及处理目的，将再次征求您的授权同意。
了解此情形中目前涉及的公司、组织和个人，请点击此处 。
（三）转让
我们不会将您的信息转让给任何公司、组织和个人，但以下情况除外：          1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的信息。
2、在我们产品及或服务的提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您信息的公司、组织和个人继续受本隐私政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。
（四）公开披露
我们仅会在以下情况下，公开披露用户信息：               1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的信息；
2、如果我们确定您出现违反法律法规或严重违反本隐私政策、平台规则的情况，或为保护我们及其他用户、公众的人身财产安全免遭侵害，我们在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，可能会公开披露您的信息。
（五）委托处理、共享、转让、公开披露用户信息时事先征得授权同意的例外
根据相关法律法规、监管规定，我们可能会共享、转让、公开披露用户信息而无需事先征得您授权同意：
1.与国家安全、国防安全直接相关的；
2.与公共安全、公共卫生、重大公共利益直接相关的；
3.与犯罪侦查、起诉、审判和判决执行等直接相关的；
4.出于维护您或其他个人的生命、财产安全所必需的；
5.所处理的个人信息是您自行公开或属于其他已经合法公开的个人信息的；
6.为履行我行法定职责或法定义务所必需的；                                    7.为订立、履行您作为一方当事人的合同所必需的，或按照依法制定的劳动规章制度和依法签订的集体合同实施人力资
源管理所必需的；                                        8.用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
9.法律法规及监管规定要求的其他情形。
五、我们如何保护用户信息
（一）我们已采取符合业界标准、合理可行的安全防护措施保护用户信息安全，防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受SSL（Secure Socket Layer）协议加密保护；我们同时提供HTTPS（Hyper Text Transfer Protocol over Secure Socket Layer）协议安全浏览方式；我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
（二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升新中大平台及产品及或服务的安全性。
（三）我们会采取合理可行的措施，尽力避免收集无关的用户信息。我们只会在达成本隐私政策所述目的所需的期限内保留用户信息；对于企业用户控制数据，由企业用户决定数据处理的目的和方式，在企业用户或其授权的专属个人用户操作删除后，将自动删除不再保留。除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：
1、完成与您相关的产品及服务使用目的、维护相应业务记录、应对您可能的查询或投诉；
2、保证我们为您提供产品及或服务的安全和质量；
3、您是否同意更长的留存期间；
4、是否存在保留期限的其他特别约定。
在用户信息超出保留期间后，我们会根据适用法律的要求删除用户信息，或使其匿名化处理。
（四）互联网并非绝对安全的环境，我们强烈建议您不要使用非我们推荐的通信方式发送用户信息。您可以通过新中大平台建立联系和相互分享。当您通过新中大平台创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您使用的产品及或服务内容、联络方式、交流信息或分享内容等相关信息的第三方。
在我们平台上进行交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的信息，如联络方式或联系地址。请您妥善保护自己的信息，仅在必要的情形下向第三方提供。如您发现自己的信息尤其是您的账号或密码发生泄露，请您立即联系我们，以便我们根据您的申请采取相应措施。
请注意，您在新中大平台上自愿共享甚至公开分享的信息，可能会涉及您或第三方的信息甚至个人敏感信息、企业重要信息。请您更加谨慎地考虑，是否在新中大平台上共享甚至公开分享相关信息。
请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
（五）我们将不定期更新并公开安全风险、用户信息安全影响评估报告等有关内容，您可通过我们公告方式获得。
（六）在不幸发生用户信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信 函、电话、推送通知等方式告知您，难以逐一告知用户时，我们会采取合理、有效的方式发布公告。
同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
六、您如何管理用户信息
您可以通过以下方式访问及管理您的信息：
（一）访问您的信息
您有权访问您的信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的信息：
对于新中大APP，您可以通过在新中大APP中点击“设置”页面，再点击右上角设置图标，进入设置功能页面，再点 击“账号与安全”进行账号密码的修改，目前新中大APP暂不支持在APP端修改账户名称，您可以通过登录新中大云平台网页（网址为：www.ns820.com）的方式变更，具体访问方式如下：
您可以登录新中大云平台网页（网址为：www.ns820.com） ，移动鼠标至右上角个人图标，选择“个人中心”功能，进入账号设置页面，点击“个人信息”菜单，访问和管理您的个人用户信息。
如您有其他需求，请联系第十条列明的联系方式联系我们处理。
如果您无法通过上述路径访问该等用户信息，您可以随时通过本隐私政策第十条披露的联系方式与我们取得联系。我们将在15天内回复您的访问请求。
对于您在使用我们的产品及或服务过程中产生的其他信息，我们将根据本条“（八）响应您的上述请求”中的相关安排向您提供。
（二）更正或补充您的信息
当您发现我们处理的关于您的信息有错误时，您有权要求我们做出更正或补充。您可以通过“（一）访问您的信息”中列明的方式进行更正或补充。您也可以通过本隐私政策第十条披露的联系方式与我们取得联系，我们将在15天内回复您的请求。
（三）删除您的信息
您可以通过“（一）访问您的信息”中列明的方式删除您的部分信息。在以下情形中，您可以向我们提出删除信息的请求：         1、如果我们处理信息的行为违反法律法规；
2、如果我们收集、使用您的信息，却未征得您的明确同意；
3、如果我们处理信息的行为严重违反了与您的约定；
4、如果您不再使用我们的产品及或服务，或您主动注销了账号；
5、如果我们永久不再为您提供产品及或服务。
您可以通过本隐私政策第十条披露的联系方式联系我们删除。若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的信息的第三方，要求其及时删除，除非法律法规另有规定，或这些第三方获得您的独立授权。
当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。
（四）改变您授权同意的范围
我们产品或服务的每个业务功能可能需要一些基本用户信息才能得以完成（见本隐私政策“第二部分”）。除此之外，对于额外用户信息的收集和使用，您可以在新中大APP“设置”页面，选择撤回对隐私政策的同意，或者通过我们的客服热线电话：95105-820或通过第十条列明的其他方式联系我们撤回您对隐私政策内容的部分或全部同意。
请您注意，您注销您的账户的同时，将视同您撤回了对本隐私政策全部内容的同意。当您撤回同意后，我们将不再处理相应的用户信息。但您收回同意的决定，不会影响此前基于您的授权而开展的用户信息处理。
（五）用户注销账号
当您所在的企业用户注销企业账户时，我们会匿名化或删除您在该企业的相关个人信息，但您的个人信息将被保留，除非您主动按照如下方式申请注销您的个人账户。在您主动向我们提出申请注销账户后，我们将在账号注销完成后停止为您提供服务 ，并根据适用法律法规的要求删除您的个人信息或进行匿名化处理。
我们收到您的账号注销申请后，将在15天内完成核查与处理。
关于账号注销，如您有任何疑问，可以联系我们的客服热线电话：95105-820或通过第十条列明的其他方式与我们取得联系。
（六）获取用户信息副本
您有权获取您的信息副本，您可以通过以下方式自行操作：我们在技术可行的前提下，例如数据接口匹配，我们还可按您的要求，直接将您的信息副本传输给您指定的第三方。
（七）约束信息系统自动决策
在我们产品及或服务的某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害我们商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。
（八）响应您的上述请求
为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
我们将在15天内做出答复。如您不满意，还可以通过联系我们发起投诉。
对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
在以下情形中，按照法律法规要求，我们将无法响应您的请求： 1、与国家安全、国防安全有关的；
2、与公共安全、公共卫生、重大公共利益有关的；
3、与犯罪侦查、起诉、审判和执行判决等有关的；
4、有充分证据表明用户存在主观恶意或滥用权利的；
5、响应用户的请求将导致用户或其他个人、组织、公司的合法权益受到严重损害的；
6、涉及商业秘密的。
七、我们如何处理儿童的个人信息
如果没有父母或监护人的同意，儿童不得创建自己的新中大云账号。如您为儿童，建议您请您的父母或监护人仔细阅读本隐私政策，并在征得您的父母或监护人同意的前提下使用我们的产品及或服务或向我们提供信息。
对于经父母或监护人同意使用我们的产品及或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。
我们将不满 14 周岁的任何人均视为儿童，如果当地法律和习俗对儿童的定义不同，则适用当地法律和习俗的定义。
八、用户信息如何在全球范围转移
原则上，我们在中华人民共和国境内收集和产生的用户信息，将存储在中华人民共和国境内。
由于我们通过遍布全球的资源和服务器提供产品或服务，这意味着，在获得您的授权同意后，您的信息可能会被转移到您使用产品及或服务所在国家地区的境外管辖区，或者受到来自这些管辖区的访问。
此类管辖区可能有不同的数据保护法，甚至未制定相关法律。在此类情况下，我们会确保您的信息得到在中华人民共和国境内足够同等的保护。例如，我们会请求您对跨境转移用户信息的同意，或者在跨境数据转移之前实施数据去标识化等安全举措。
九、本隐私政策如何更新
我们的隐私政策可能变更。
未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在新中大平台上发布对隐私政策所做的任何变更。
对于重大变更，我们还会提供更为显著的通知（包括我们会通过我们公示的方式进行意见征集、公告通知甚至向您提供弹窗提示）并再次取得您的授权同意。
本隐私政策所指的重大变更包括但不限于：
1、我们的产品及或服务模式发生重大变化。如处理用户信息的目的、处理的用户信息类型、用户信息的使用方式等；
2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；
3、用户信息共享、转让或公开披露的主要对象发生变化；
4、您参与用户信息处理方面的权利及其行使方式发生重大变化；
5、我们负责处理用户信息安全的责任部门、联络方式及投诉渠道发生变化；
6、用户信息安全影响评估报告表明存在高风险。
十、如何联系我们
我们设有专门合规团队监督用户信息保护事宜，有关本隐私政策或我们的隐私措施相关问题可通过如下方式联系我们，我们将在15天内回复您的请求：
用户信息保护负责人邮箱：【CSC@newgrand.cn】客服联系电话：95105-820
联系地址：浙江省杭州市拱墅区祥园路88号N座9楼
如果您对我们的回复不满意，特别是您认为我们的用户信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。`,
  `欢迎您（以下或称“用户”）使用新中大及或其关联公司的产品及或服务！

您与新中大及或其关联公司共同签署《新中大用户使用协议》（以下简称“本协议”），并使用您已有的新中大及或其关联公司账号或您即将注册激活的新中大账号登录新中大平台后，您即可按照本协议约定使用新中大及或其关联公司的产品及或服务。
【审慎阅读】您在签署本协议之前，请务必审慎阅读、充分理解本协议各条款内容，特别是以粗体下划线标识的条款。
【签署】当您按照新中大平台登录注册页面提示, 在使用您已有的新中大及或其关联公司账号登录新中大平台时阅读并点击同意本协议后，或者在注册激活新中大账号过程中阅读并点击同意本协议且完成全部账号注册激活程序后，即表示您已审慎阅读、充分理解并完全接受本协议的全部内容，并同意与新中大签署本协议，本协议自您点击同意时生效。在阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止登录并及时联系新中大及或其关联公司，或者立即停止账号注册激活程序。
目录
一、释义
二、账号授权和账号注册激活、使用三、服务费用
四、服务内容及规范
五、用户信息的保护及授权六、责任限制
七、用户的违约及处理
八、所有权及知识产权
九、协议范围与关联协议
十、协议的变更及终止
十一、通知
十二、法律适用、管辖与其他
一、释义
用户：指成功注册激活新中大账号的自然人、法人或其他组织，和签署了本协议并使用新中大关联公司账号登录新中大平台的自然人、法人或其他组织。
新中大：指杭州新中大科技股份有限公司。
关联公司：指杭州新中大科技股份有限公司在本协议中披露的关联公司，具体详见请点击。
新中大平台：指新中大及或其关联公司运营的，为您提供各项产品及或服务的网站、客户端、移动应用程序、微信公众号、微信小程序等渠道（包括未来技术发展出现的新的形态）。
产品及或服务：指新中大及或其关联公司通过新中大平台为您提供的各项产品及或服务。
平台规则：指在新中大平台上已经发布的及未来根据需要不定期更新的或新发布的所有服务规则、公告及各项产品及或服务的流程说明、使用方法规则介绍等。
二、账号授权和账号注册激活、使用
2.1【账号授权】
为了更加便捷地为您提供新中大及或其关联公司的产品及或服务，您同意授权新中大使用您的新中大关联公司账号登录新中大平台，在您签署了本协议并使用新中大关联公司账号登录新中大平台后，您的新中大关联公司账号即成为您的新中大账号。
同时，为了对用户账号和其他信息进行统一管理、加强用户信息安全保护并为用户提供更加优质、高效、全面的产品及或服务，您同意授权新中大按照本协议以及《新中大隐私政策》约定收集、使用、处理、共享新中大关联公司所存储的您的信息。
2.2【注册激活账号前注意事项】
您确认，您在注册激活新中大账号及使用新中大及或其关联公司的产品及或服务时，应当具备您所在国家地区法律规定的从事前述活动所需的相关资格条件。若您不具备前述相关资格条件，则您应当立即停止账号注册激活、产品及或服务使用，否则您须承担因此而导致的一切后果。
2.3【账号注册激活】
在您使用新中大及或其关联公司的产品及或服务前，您应按照新中大平台注册登录页面的提示及平台规则填写信息、阅读并点击同意本协议且完成全部账号注册激活程序，成为新中大的用户。
2.4【账号数量】
根据用户选择的不同产品及或服务，新中大允许每位用户拥有一个或者多个新中大账号。如有证据证明或根据平台规则判断，您存在不当注册或不当使用多个新中大账号的情形，新中大可对您的新中大账号采取限制部分或全部功能、关闭、注销等措施，如给新中大及或其关联公司、其他相关方造成损失的，您还应承担赔偿责任。
2.5【账号实名认证】
您理解并同意，即使您拥有账号亦不代表您可以使用新中大平台上的所有产品及或服务。为保障新中大账号及交易安全，新中大及或其关联公司可能会随时要求您提交更多的信息以完成账号的实名认证，只有完成了账号的实名认证，并按照您与新中大及或其关联公司的有关协议合同约定或平台规则支付了相关产品及服务对应的服务费用（如有），您才可以使用相应的产品及服务。为了完成账号的实名认证，您同意授权新中大及或其关联公司可以通过第三方查询和审核您提交的信息。
新中大及或其关联公司可以为用户提供多种类、不同级别的产品及或服务，您在使用不同种类的产品及或服务时可能被要求提交不同的信息，您在使用更高级别的产品及或服务时可能被要求提交更多的信息。
2.6【账号管理】
您有权使用您的新中大账号登录新中大平台，并可在用户中心完善您的信息，以便新中大及或其关联公司更好地为您提供相关产品及或服务。
2.6.1您的新中大账号密码由您按照平台规则自由设置并由您自行保管，新中大及或其关联公司任何时候均不会主动要求您提供您的账号密码。为了保障您的账号安全，建议您务必保管好您的账号名称、账号密码，并确保您在每次使用产品及或服务结束时退出登录并以正确步骤离开新中大平台。您的账号因您主动泄露、保管不当或因您遭受他人攻击、诈骗等行为导致的损失及后果，新中大及或其关联公司并不承担责任，您应通过您所在国家地区法律规定的救济途径向侵权行为人追偿。
2.6.2除新中大及或其关联公司存在过错外，您应对使用您的新中大账号进行的所有行为（包括但不限于在线签署各类合同协议等法律文件、购买使用各项产品及或服务及披露信息等）结果负责。
2.6.3如您发现任何未经授权使用您的新中大账号登录新中大平台或其他可能导致您的账号遭窃、遗失的情况，建议您立即通知新中大及或其关联公司。您理解，新中大及或其关联公司对您的任何请求采取行动均需要合理时间，且新中大及或其关联公司应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除新中大及或其关联公司存在经司法裁定的过错外，新中大及或其关联公司不承担责任。
2.7【账号转让】
由于新中大账号关联您的信息和权益，仅当有法律明文规定、司法裁定或经新中大及或其关联公司同意，并符合平台规则规定的账号转让流程的情况下，您可进行账号的转让。您的账号一经转让，该账号项下权利义务一并转移。除此之外，您的账号不得以任何方式转让，否则新中大及或其关联公司有权追究您的违约责任，且由此产生的一切后果均由您自行承担。
2.8【账号查询、关闭及注销】
您理解并同意，新中大根据合理判断，有权在下列情形下对您的账号进行查询（包括但不限于查询注册信息、交易信息、资金信息等）、关闭及或注销：
传输或发表煽动抗拒、破坏宪法和法律、行政法规的言论，煽动颠覆国家政权、推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；
从中国大陆向境外传输资料信息时不符合中国有关法律法规；
利用新中大平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；
干扰新中大平台的正常运转，侵入新中大平台及国家计算机信息系统；
发布任何侵犯他人著作权、商标权等知识产权及其他合法权利的内容；
发布任何违反公序良俗或造成恶劣社会影响的内容；
国家司法、行政、安全机关等权力机关要求；
您通过新中大账号的操作和交易存在违反本协议约定、法律法规和或侵犯第三人合法权益的情形；
新中大及或其关联公司基于单方面的合理判断，认为您的账号操作、交易记录等存在异常；
您的账号遭到其他用户投诉，且投诉用户提供一定证据予以证明；
您实施了影响或侵害新中大及或其关联公司合法权益的行为，包括但不限于：
违反新中大平台的平台规则；
破坏或试图破坏新中大平台公平交易环境或正常交易秩序；
任何使用含有新中大及或其关联公司的名称、品牌且有误导性的表述或标识，明示或暗示与新中大及或其关联公司具有某种关系；
新中大及或其关联公司认为可能是与上述行为性质相同或存在上述类似风险的其他情形。
三、服务费用

3.1新中大平台上的产品及或服务种类众多，存在免费及或付费的产品及或服务。若您选择了需要付费的产品及或服务，新中大及或其关联公司会在向您明示相应的收费标准后，并根据您自主选择的产品及或服务内容向您收取相应的费用。
3.2若由于市场行情变化、新中大及或其关联公司经营战略业务调整等原因，新中大及或其关联公司可能会将原有免费的产品及或服务调整为付费产品及或服务，在此之前，新中大及或其关联公司会采取合理途径并以足够合理的期限提前前通过法定程序并以本协议第十一条约定的方式通知您，以确保您有充分选择的权利。
四、服务内容及规范
4.1新中大及或其关联公司提供的产品及或服务种类众多，新中大及或其关联公司将根据您选择的产品及或服务种类，为您提供相应的产品及或服务内容。具体产品及或服务内容及规范详见该产品及或服务的用户服务协议（以产品及或服务的具体协议名称为准）。
4.2.您同意授权新中大及或其关联公司收集您的账号登录行为数据及相关产品及或服务模块的使用行为数据等（以下 简称“用户行为数据”），新中大及或其关联公司有权根据用户行为数据识别风险用户，以便及时改进、优化各项产品及或服务。
4.4新中大及或其关联公司有权根据需要对新中大平台上的产品及或服务形态进行升级或其他调整，并将及时告知您。

4.5使用规范

4.5.1关于产品及或服务的获取与更新：

(1)您可以直接从新中大平台上或者新中大及或其关联公司处获取相关产品及或服务，也可以从得到新中大及或其关联 公司授权的第三方处获取，如果您从未经新中大及或其关联公司授权的第三方处获取产品及或服务，新中大及或其关联公司无法保证该产品及或服务能够正常使用，并对因此给您造成的损失不予负责；
(2)为了改善用户体验、完善产品及或服务内容，新中大及或其关联公司将不断努力开发新的产品及或服务，并为您不时提供产品及或服务更新，新版本发布后，除非明确说明外，旧版本的产品及或服务可能无法使用，新中大及或其关联公司不保证旧版本产品及或服务继续可用，请您随时核对并下载最新版本。
4.5.2除非法律允许或新中大及或其关联公司书面许可，您使用新中大及或其关联公司的产品及或服务过程中不得从事下列行为：
(1)删除新中大及或其关联公司产品及或服务上以及其副本上所载关于著作权的信息；

(2)对新中大及或其关联公司的产品及或服务进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现源代码；

(3)对新中大及或其关联公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
(4)对新中大平台运行过程中释放到任何终端内存中的数据、新中大平台运行过程中客户端与服务器端的交互数据，以及新中大平台运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经新中大及或其关联公司授权的第三方工具服务接入新中大平台和相关系统；
(5)通过修改或伪造新中大平台运行中的指令、数据，增加、删减、变动新中大平台的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
(6)通过非新中大及或其关联公司开发、授权的第三方软件、插件、外挂、系统，登录新中大平台或使用新中大平台上的产品及或服务，或制作、发布、传播上述工具；
(7)自行或者授权他人、第三方软件对新中大平台及其组件、模块、数据进行干扰。
五、用户信息的保护及授权
5.1 【用户信息的保护】
新中大及或其关联公司非常重视用户信息的保护，在您登录新中大平台、使用新中大及或其关联公司的产品及或服务时，您同意新中大及或其关联公司按照在新中大平台上公布的隐私政策收集、存储、使用、披露和保护您的信息。如用户使用的产品及或服务未设置独立的隐私政策，但用户使用了新中大账号登陆新中大平台的，为了保护您的隐私权，我们将适用
《新中大隐私政策》的要求对您的信息进行收集、存储、使用、披露和保护。新中大及或其关联公司希望通过隐私政策向您清楚地介绍新中大及或其关联公司对您信息的处理方式，因此新中大及或其关联公司建议您完整地阅读隐私政策，以帮助您更好地保护您的隐私权。
5.2【非用户信息的保证与授权】
您声明并保证，您对您所上传、发布的信息拥有相应、合法的权利。否则，新中大及或其关联公司可对您发布的信息依法或依本协议进行删除或屏蔽。
您应当确保您所上传、发布的信息不包含以下内容：违反国家法律法规禁止性规定；
政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪；欺诈、虚假、不准确或存在误导性；
侵犯他人知识产权或涉及第三方商业秘密及其他专有权利；侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益；
存在可能破坏、篡改、删除、影响新中大平台正常运行或未经授权秘密获取新中大平台及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码；
其他违背社会公共利益或公共道德或依据相关平台规则的规定不适合在新中大平台上发布的。
【授权使用】对于您提供、发布及在使用新中大及或其关联公司的产品及或服务中形成的除用户信息外的文字、图片、视频、音频等非用户信息，均不会因上传、发布等行为发生知识产权、肖像权等权利的转移。除非新中大及或其关联公司另行说明，在法律允许的范围内，您免费授予新中大及其关联公司、非排他的、无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品，以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。
为方便您使用新中大及或其关联公司的各项产品及或服务，您授权新中大及或其关联公司将您在注册激活新中大账号、登录新中大平台、使用产品及或服务过程中提供、形成的信息无偿共享给相应的产品及或服务提供者，且仅用于提高产品体验感及技术提升。
六、责任限制
6.1您理解并同意，您在使用新中大及或其关联公司的产品及或服务过程中存在的风险将完全由您本人承担，新中大及或其关联公司在本协议约定的责任范围以外不承担任何责任。
6.2在法律允许的情况下，新中大及或其关联公司对于与本协议有关的任何间接的、惩罚性的、特殊的、派生的损失
（包括但不限于业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不承担任何责任，即使事先已被告知此等损失的可能性。
6.3您理解并确认，对于新中大及或其关联公司向您提供的下列产品及或服务本身及其引发的任何损失，新中大及或其关联公司无需承担任何责任：
新中大及或其关联公司向您免费提供的各项产品及或服务；
新中大及或其关联公司向您赠送或附赠的任何产品及或服务。
您理解并确认，新中大及或其关联公司可能因下列情况无法正常为您提供产品及或服务，新中大及或其关联公司就此不承担损害赔偿责任：
因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；
因电力供应故障、通讯网络故障等公共服务因素或第三人因素；
在新中大及或其关联公司已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。
您理解并同意，因您使用新中大及或其关联公司的产品及或服务、违反本协议或使用您的新中大账号采取的任何行动，而导致的任何第三方索赔，应且仅应由您本人承担。如果由此引起新中大及或其关联公司、员工、客户、供应商和合作伙伴被第三方索赔的，您应承担全部责任。
您通过新中大平台访问和使用的新中大及或其关联公司的合作伙伴或入驻新中大平台的第三方服务商的服务质量仅为该第三方的责任，对于该第三方的产品及或服务，新中大及或其关联公司在任何情况下都不会承担责任。
七、用户的违约及处理 7.1违约认定
发生如下情形之一的，视为您违约：
7.1.1使用新中大及或其关联公司的产品及或服务时违反有关法律法规规定的；
7.1.2违反本协议、关联协议或者与新中大及或其关联公司签署的其他协议约定的。
7.2违约处理措施
7.2.1您在新中大平台上发布的信息构成违约的，新中大及或其关联公司可根据相应平台规则立即对相应信息进行删除、屏蔽处理。
7.2.2您在新中大平台上实施的行为，或虽未在新中大平台上实施但对新中大及或其关联公司及其用户产生影响的行为构成违约的，新中大及或其关联公司可依据相应平台规则对您执行中止向您提供部分或全部产品及或服务等处理措施。如您的行为构成根本违约的，新中大及或其关联公司可关闭您的账号，终止向您提供产品及或服务。
7.2.3当您违约的同时存在欺诈、售假、盗用他人账号等特定情形或您存在危及他人账号安全风险时，新中大及或其关联公司会依照您行为的风险程度对您的账号采取关闭、注销等强制措施。
7.2.4新中大及或其关联公司可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在新中大平台上予以公示。
7.3赔偿责任
7.3.1如您的行为使新中大及或其关联公司遭受损失，您应根据本协议履行赔偿责任，您需赔偿新中大及或其关联公司的因此遭受的损失（如有）包括:
支付的合理律师费、诉讼费、消除影响所支出的必要费用；
因行政处罚、司法裁判、法定标准范围内的调解等对外支出的罚款、违约金或赔偿金；
商誉损失、合理预期利益损失（如经营收入减少等），具体金额可以第三方独立合理分析、新中大及或其关联公司根据大数据合理分析及其他计算收益、损失的合理方法所得出的相关估算标准作为计算依据；
因此遭受的其他损失。
7.3.2如您的行为使新中大及或其关联公司遭受第三人主张权利，新中大及或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。
7.3.5 新中大及或其关联公司因故意或重大过失违约导致您利益受损的，新中大及或其关联公司将以您的直接实际损失为计算依据向您履行赔偿责任。
7.4特别约定
7.4.1如您向新中大及或其关联公司的员工等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。发生上述情形的，新中大及或其关联公司可按照相应平台规则进行处理，并经提前通知后终止与您的所有合作并向您收取违约金及或赔偿金，该等金额以新中大及或其关联公司因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。
7.4.2如您因严重违约导致新中大及或其关联公司终止本协议时，出于维护新中大平台秩序及保护其他用户合法权益的目的，新中大及或其关联公司可对与您在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议约定的有效方式通知您。
7.4.3如新中大及或其关联公司与您签署的其他协议及新中大及或其关联公司及或其关联公司与您签署的协议中明确约定了对您在本协议项下合作进行关联处理的情形，则新中大及或其关联公司出于维护新中大平台秩序及保护其他用户合法权益的目的，可在收到指令时中止甚至终止协议，并以本协议约定的有效方式通知您。
八、所有权及知识产权
8.1除法律另有强制性规定外，未经新中大及或关联公司明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用新中大平台的信息内容，包括但不限于著作、图片、档案、资讯、资料、架构、页面设计等，否则，新中大及或其关联公司有权追究其法律责任。
8.2新中大平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是新中大及或关联公司以及其内容提供者的财产，受中国和国际版权法的保护。新中大平台上所有内容的汇编是新中大及或关联公司的排他财产，受中国和国际版权法的保护。新中大平台上所有产品及或服务都是新中大及或其关联公司或其供应商的财产，受中国和国际版权法的保护。
九、协议范围与关联协议 9.1缔约主体
本协议由您与新中大及或其关联公司共同签署，本协议对您与新中大及或其关联公司具有同等合同效力。
本协议项下，新中大及或其关联公司可能根据新中大平台的业务调整而发生变更，变更后的新中大平台经营者与您共同履行本协议并向您提供服务，新中大平台经营者的变更不会影响您本协议项下的权益。新中大平台经营者还有可能因为提供新的产品及或服务而新增，如您使用新增的产品及或服务的，视为您同意新增的新中大平台经营者与您共同履行本协议。发生争议 时，您可根据您具体使用的产品及或服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。
9.2关联协议
9.2.1同时伴随互联网高速发展，您与新中大及或其关联公司签署的本协议列明的条款并不能完整罗列并覆盖您与新中大及或其关联公司所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，新中大及或其关联公司法律声明、隐私政策、在新中大平台以公开方式发布的各项平台规则、新中大及或其关联公司具体产品及或服务的用户服务协议以及隐私政策均为本协议的关联协议，与本协议具有同等法律效力。如您使用新中大及或其关联公司的产品及或服务，视为您同意上述关联协议。
9.2.2您知晓并同意，由于新中大及或其关联公司提供的产品或服务类别众多且领域跨度较大，本协议作为总框架协议，难以穷尽。若与新中大及或其关联公司的具体产品及或服务的用户服务协议存在不一致之处，以具体产品及或服务的用户服务协议约定为准。
十、协议的变更及终止 10.1协议变更
10.1新中大及或其关联公司可根据国家法律法规变化及维护市场秩序、保护用户合法权益需要，不时修改本协议、关联协议，变更后的协议、关联协议（下称“变更事项”）将通过法定程序并以本协议约定的有效通知方式通知您。
10.2如您不同意变更事项，您有权于变更事项确定的生效日前联系新中大及或其关联公司反馈意见。如反馈意见得以采纳，新中大及或其关联公司将酌情调整变更事项。如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用新中大及其关联公司服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用新中大及其关联公司服务，则视为您同意已生效的变更事项。
10.2协议终止
10.2协议终止的情形
10.2.1您有权通过以下任一方式终止本协议：
在满足新中大及或其关联公司要求的账号注销条件时您通过新中大平台自助服务注销您的账号的；
变更事项生效前您停止使用新中大及或其关联公司的产品及或服务并明示不愿接受变更事项的；
您明示不愿继续使用新中大及或其关联公司的产品及或服务，且符合新中大及或其关联公司要求的终止条件的。 10.2.2出现以下情况时，新中大及或其关联公司可按照本协议约定的有效方式通知您终止本协议：
您的实名认证未通过、不再合法存续或无法有效核实；
您违反本协议约定，新中大及或其关联公司依据违约条款终止本协议的；
您盗用他人账号、欺诈、售假、扰乱市场秩序、采取不正当手段牟利等行为，新中大及或其关联公司依据相应平台规则对您的账号予以关闭、注销的；
除上述情形外，因您多次违反平台规则相关规定且情节严重，新中大及或其关联公司依据相应平台规则对您的账户予以关闭、注销的；
新中大及或其关联公司基于合理理由相信您的行为可能会使您、新中大及或其关联公司及其他用户等相关方发生严重损害或产生法律责任；
其他应当终止提供产品及或服务的情况。 10.3协议终止后的处理
10.3.1本协议终止后，除法律有明确规定外，新中大及或其关联公司无义务向您或您指定的第三方披露您的账号及其他任何信息。
10.3.2本协议终止后，新中大及或其关联公司仍享有下列权利：
依据《中华人民共和国网络安全法》以及其他相关法律的要求，在一定期限内继续保存您留存于新中大平台的各类信息，且不再开展除存储和业务辨识之外的信息采集，同时对相关信息做匿名化处理；对于您过往的违约行为，新中大及或其关联公司仍可依据本协议向您追究违约责任。
十一、通知  11.1有效联系方式
您在注册激活新中大账号、登录新中大平台，并接受新中大服务时，您应该向新中大及或其关联公司提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被有效联系的状态。
您用于登陆新中大平台、接收站内信、系统消息等即时信息的账号，也作为您的有效联系方式。
新中大及或其关联公司将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。
11.2通知的送达
11.2.1新中大及或其关联公司通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在新中大平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送  达。
11.2.2对于在新中大及或其关联公司为您提供产品及或服务过程中引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在注册激活新中大账号时提供的、更新的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。
11.2.3您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。
11.2.4您应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。
十二、法律适用、管辖与其他
12.1本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及或行业惯例。
12.2您因使用新中大及或其关联公司的产品及或服务所产生及与新中大及或其关联公司有关的争议，由新中大及或其关联公司与您协商解决。协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。
12.3本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
12.4本协议各条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。
如您对本协议有任何疑问，可向客服咨询（法务联系电话：0571-88270588,客服联系电话：95105820）。
杭州新中大科技股份有限公司`,
];
