import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/17/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%9C%BA%E6%A2%B0%E7%AE%A1%E7%90%86/banner.png`,
    describe: "新中大现场机械管理解决方案旨在解决现场移动机械设备运行成本管理、固定机械设备安全管理问题。方案采用智能物联技术采集并自学习移动机械定位数据、运行数据、油耗数据并上传企业端进行机械成本核算；通过大型机械设备黑匣子监测设备获取安全监测数据从而进行预警提示，帮助项目部现场预防安全事故风险。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "现场机械管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 1,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '成本安全、双重监管'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '软硬结合、数据联动'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '极简安装、即贴即用'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '智能AI、自学习校准'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        title: '可以深度学习的智能终端',
        describe: '设备依靠智能深度学习、能够精准适配市面上几乎所有工程机械重新定义工程机械信息化标准，使用无线物联网技术的智能设备采用了高温热压敏胶的安装方式，在机械工作状态下胶粘性会越来越强，更加牢固持久。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        title: '适应性强，助力建企全面推广',
        describe: '为适应各类工地复杂作业情况，精选高耐受玻纤材料，并经过144个小时零下40℃-100℃宽范围温度冲击测试，可以在任一工地“安家”。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        title: '交叉定位，双重通讯，帮助企业可视化管理',
        describe: '采用GPS、北斗、基站交叉定位方式，准确识别机械的运行轨迹以及当前的所在位置，结合电子围栏技术,有效保证机械在正确位置施工，防止干私活的现象。通过运营商网络连接云端进行数据传输、远程升级。同时机械宝可以在本地形成蓝牙局域网，自由拓展各类传感器。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        title: '数据整合，辅助机械台班结算',
        describe: '设备采集工程机械的各项数据，对数据进行计算、整合、处理。软件平台借助硬件设备云端采集机械运行记录，自动生成机械运行台班帮助企业轻松抓住偷懒、干私活的家伙，从而杜绝工作效率多、管理漏洞多的各类管理问题。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        title: '移动监管，指尖上的管理',
        describe: '方案不仅考虑办公室管理人员的PC端功能，同时将赋能移动终端，使企业或项目机械管理者随时随地轻松管理工程机械，在手机移动端实时管理机械列表、机械状态、运行轨迹、工作时长、机械进退场、机械检查、机械巡检、机械保养等。'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 1 },
        title: '预警提醒，风险管控',
        describe: '软件平台利用平台预警技术，轻松实现项目机械低油量、保养、机操工证件有效期等管理预警，将预警信息实时发送机械管理者管理平台或移动终端，可以有限防止机械运行过程中出现的各类管理风险。'
      }
    ]
  },
  sessionE: {
    title: '广西荣耀集团（南宁市政工程集团有限公司）',
    describe:`广西荣耀集团是全国市政行业领先企业，属于中国市政工程协会副会长单位。集团下属公司南宁市政工程集团有限公司是由成立于1951年的南宁市政工程总公司改制而来，历经60多年的发展，集团已成为一个拥有四个业务板块、八个公司、二十多个独立法人单位的企业集团，业务范围涵盖全国各地及周边国家。公司拥有一个特级总承包资质公司，两个壹级总承包资质公司、一个贰级总承包资质公司，配套资质含市政公用工程施工总承包特级、房屋建筑施工总承包一级等11 项总承包资质、33项专业承包资质。`, 
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}