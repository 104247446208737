import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/mi8/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E4%BA%A7%E5%93%81/mini/banner.png`,
    describe: "新中大mi8工程项目管理云平台是一款轻便简洁，可快捷部署的云端项目管理产品。产品重在解决建筑企业在项目合同履约监管、物资材料超耗、发票税务触礁、资金超结超付、项目成本失控、现场数据管控、办公效率提升等核心业务方面的管理问题。企业项目部、分公司、总部相关职能部门皆可通过平台进行线上管理，同时mi8工程项目管理云平台实现业务与财务的融通管理，系统通过融合税务资金管理系统，构建项目管理九算对比精细化管理分析模型，通过合同流、物流、发票流、资金流四流合一实现了企业运营风险管控。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "mi8工程项目管理云平台",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  sessionB: {
    src: global.config.url + '/NewGrand/media/mini.81d5659c.mp4',
  },

  // 产品特点
  sessionC: {
    colCount: 3,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}mi8.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '纯WEB框架'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '免配置，直接应用'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '允许企业自定义'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '多端入口'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '内置管理体系'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '经济实惠'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}mi8.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '合同云',
        describe: '合同云主要分为收入和支出两类合同，实现了从合同评审、登记、变更、验工计价、终止的全过程管理。通过合同统计和分析，企业可实时掌握项目合同签订情况、收入情况、支出情况、二次经营情况等，全面的对企业合同进行分析，加强了合约管理的同时，也大大降低了企业的合约风险。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '项目资金云',
        describe: '凸显工程企业管理特点，以项目为虚拟资金账户，从收入、划转、支出三条线进行全面管理控制。通过收入与支出合同的控制，形成业务、资金、发票一体化的管控模式。以业务控制资金，保证了企业资金支出的合理化、规范化，从而降低了企业资金超付的风险。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '发票云',
        describe: '发票主要是针对企业的销项发票、进项发票进行管理，集成开票软件、增值税发票平台、中国税务平台实现一键开票、一键同步、一键验真。从收入合同到开票再到销项台账、再从支出合同到进项台账，形成了业务、发票的一体化管控模式，实现业务税务的一体化管理。同时，通过业务、发票的对比分析，可实时掌握企业的应收、应付情况，通过发票税额的管理实时分析企业的税负情况。保证了企业发票的合理化管理，同时帮助企业降低税负、应收、应付等风险。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '项目成本云',
        describe: '通过制定目标成本控制材料采购、合同签订、发票收取等，管理过程中自动归集实际成本，同时集成合同、发票、资金等业务形成九算对比管理模式，全方位对企业经济业务进行分析，实时掌握企业经营情况。实现了成本的事前管控、事中分析、事后稽查的精细化管控，帮助企业管理风险全过程控制。 '
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '物资云',
        describe: '系统以采购计划为源头，采购、入库、出库为过程，实现物资从计划到合同到入库再到出库的全过程管理，层层控制，确保企业物资有序执行，减少物资浪费，从而保证成本得以控制。另外，通过实时分析，可实时掌握项目物资的采购情况、调拨情况、使用情况，帮助企业对物资进行实时分析和控制，实现企业利润的最大化。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '现场管理云',
        describe: '现场管理主要是项目现场的管理。通过施工日志，企业可实时分析项目的进展情况、人材机使用情况等。并且通过检查、整改、回执、复查的闭合模式，实现企业对项目现场质量、安全、卫生等方面的全面管控，确保项目施工正常运行，从而大大降低了项目现场管理的风险。'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 1 },
        title: '办公云',
        describe: '新闻公告，可以让企业员工第一时间掌握企业信息，保证了消息的快速传递。证书管理，帮助企业对证书进行管理，实时掌握企业证书的借用归还情况，保证证书的合理应用。印章管理，帮助企业对印章进行管理，实时掌握印章的使用情况、外带情况，确保印章的使用都是合理合法的，从而降低企业印章使用的风险。签到管理，帮助企业进行员工考勤，减少员工迟到、早退、旷工等情况。审批管理，实现了信息流程化的管理，打破空间概念，随时随地审批。'
      }
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history:'/caseDetails/1033'
      },
      {
        logoSrc: `${baseUrl}b.png`,
        history:'/caseDetails/1051'
      },
      {
        logoSrc: `${baseUrl}c.png`,
        history:'/caseDetails/1052'
      }
    ]
  }
}