/**
 * 全名： 全过程工程咨询服务管理
 * 功能描述： 
 * 创建日期：--
 * 作者：hzw
 */

import React from "react";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import ContactBottom from '../../../new-components/ContactBottom';
import ArchitectureImage from '../privateComponents/ArchitectureImage';
import PointPanel from "../privateComponents/FinancePointPanel";
import ValuePanel from '../privateComponents/FinanceValuePanel';


import config from "./config"
import './index.less';


export default class extends React.Component {
  render() {
    return (
      <div className="process-consulting">
        <Head />
        <Abstract {...config.sessionA} />
        <PointPanel {...config.sessionB} />
        <ArchitectureImage  {...config.sessionC} />
        <ValuePanel  {...config.sessionD} />
        <ContactBottom />
        <Bottom />
      </div>
    )
  }
}