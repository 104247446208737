import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/1a/`;
export default {
  sessionA: {
    describe: '通过多层级集团组织建模、多层级集团基础数据共享管控策略、业务基础数据的配置及业务体系的架构，实现对多层级集团管控、矩阵式多级组织管控，在业务、税务、财务一体化下实现对企业集团内部所有公司财务规则的统一、实现集团企业跨地域、跨行业、多板块经营的集团财务管控目标。'
  },
  sessionB: {
    rowCount: 1,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '业务、税务、财务一体化'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '企业凭证和项目凭证相融合'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '多层级报表设置和报表合并'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },
  sessionC: {
    imgSrc:`${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount:2,
    rowCount:3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 3, y: 0 },
        title: '',
        describe: '精准、及时的掌握内外部客商的应收、应付情况，为企业管理层实时提供经营决策的往来大数据分析。'
      },
      {
        key: 2,
        iconPosition: { x: 4, y: 0 },
        title: '',
        describe: '业务、税务、财务高度一体化，将财务从疲劳的业务核算、业务对账中解放出来。'
      },
      {
        key: 3,
        iconPosition: { x: 5, y: 0 },
        title: '',
        describe: '提升财务管理、轻松实现集团财务管控。'
      },
    ]
  },
}