/*
 * @Author: your name
 * @Date: 2019-12-11 16:31:35
 * @LastEditTime : 2020-01-09 13:56:30
 * @LastEditors  : Please set LastEditors
 * @Description: 首次用于安全教育页面 的长条介绍
 * @FilePath: \my-demo\src\pages\digitalSite\components\LongList.js
 */
import React from 'react';
//import './index.css';
import './components.less'


/**
 * @description 364*913的长条卡片 ，最上方为一张图，中间为两行的粗体标题 下方是多行列表介绍
 *             需要提供参数 : 图片url <>, 标题文本<title>, 介绍文本<text>
 * @author chenyutan
 * @date 2019-12-11
 * @class LongList
 */
class LongList extends React.Component {
    render() {
        return (
            <div className="digital-site">
                <div className="long-list">
                    <div className="long-list-img">
                        <img alt="" src={this.props.img}></img>    
                    </div>
                    <div className="long-list-title" dangerouslySetInnerHTML={{ __html: this.props.title }}></div>
                    <div className="long-list-text" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
                </div>
            </div>
        );
    }
}

export default LongList;