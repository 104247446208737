import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/8/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E5%BB%BA%E9%80%A0/%E5%B7%A5%E7%A8%8B%E6%80%BB%E6%89%BF%E5%8C%85%E4%BC%81%E4%B8%9A%E4%BF%A1%E6%81%AF%E5%8C%96/banner.png`,
    describe: "施工总承包企业信息化管理解决方案针对施工总承包企业在集团化运营管理中，存在的项目部点多面广、流动性强、各项目部、分子公司、总公司之间的沟通成本大、效率低，项目中的种种风险也不能有效的得到控制等问题，方案以项目生命周期为对象，实现项目前期跟踪、项目立项、投标、项目实施、项目竣工的全过程管理。方案旨在为企业和项目正常运行提供人力、资源、资金、知识、信息沟通等方面的信息化支持。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "施工总承包企业管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '信息以资金为抓手的四流合一、业财一体管控共享化'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '以标准化促进成本管理建设'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '智慧建造下的项目现场管理'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '协同办公、人力资源'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '项目看板大数据分析'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 5, y: 0 },
        title: '互联互通，共享共治',
        describe: '通过构建企业统一协同工作平台，提升企业内相关单位之间协同工作能力，提高集团内信息沟通、办公事务的效率，加强企业内部的团队工作意识。'
      },
      {
        key: 2,
        iconPosition: { x: 6, y: 0 },
        title: '制度流程化，流程信息化',
        describe: '工作流引擎从流程的定义、设计、授权、运用、监控，动态地反映了一个完整的业务处理流程。'
      },
      {
        key: 3,
        iconPosition: { x: 7, y: 0 },
        title: '随时随地，移动办公',
        describe: '系统集成手机APP、邮件、即时通讯、企业微信等多种主流移动通讯工具，将企业办公与通讯工具融为一体。'
      },
      {
        key: 4,
        iconPosition: { x: 8, y: 0 },
        title: '知识管理，创造价值',
        describe: '通过构建企业级的文档知识管理平台，将人与知识紧密结合，构建出企业内部完善的知识管理体系。'
      }
    ]
  },
  sessionE: {
    title: '广西荣耀集团（南宁市政工程集团有限公司）',
    describe: '广西荣耀集团是全国市政行业领先企业，中国市政工程协会副会长单位。下属公司南宁市政工程集团有限公司由成立于1951年的南宁市政工程总公司改制而来。历经60多年的发展，广西荣耀集团已成为一个拥有四个业务板块、八个公司、二十多个独立法人单位的企业集团，业务范围涵盖全国各地及周边国家。公司现有员工一千多人，其中各类专业技术人员占80%，拥有国家注册建造师、高中级职称五百多人。资质种类及证件配套齐全，拥有一个特级总承包资质公司，两个壹级总承包资质公司、一个贰级总承包资质公司，配套资质含市政公用工程施工总承包特级、房屋建筑施工总承包一级等11项总承包资质、33项专业承包资质。技术创新成效显著，成功研发多项国家级工法和专利，并主持编制多项行业标准，实现新技术的推广应用。',
    backgroundImage:`url(${baseUrl}anli.png)`
  }
}