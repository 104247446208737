/*
 * @Author: your name
 * @Date: 2019-12-26 15:26:18
 * @LastEditTime : 2020-01-14 11:25:52
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\CompanyIntroduction\corporate-culture\CorporateCulture.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './corporate-culture.less'
import $ from 'jquery';
import ani from '../../../transition/animation/animation'

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'


//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-body">
                    <div className="first-page-bg"></div>
                    <div className="culture-page-logo"></div>
                    <div className="first-theme">
                        <div className="first-theme-text-1">使 命</div>
                    </div>
                    <div className="first-text">让天下没有难管的项目！</div>
                    <div className="line-1"></div>

                    <div className="second-theme">
                        <div className="second-theme-text-1">愿 景</div>
                    </div>
                    <div className="second-text">成为全球领先的项目管理数字化平台服务商</div>
                    <div className="line-2"></div>

                    <div className="third-theme">
                        <div className="first-theme-text-1">口 号</div>
                    </div>
                    <div className="third-text">专注专业　　追求卓越</div>
                    <div className="line-3"></div>

                    <div className="fourth-theme">
                        <div className="second-theme-text-1">价 值 观</div>
                    </div>
                    <div className="fourth-text">
                        创新　 不断学习　超越自我<br />
                        中和　 坦诚沟通　真诚合作 <br />
                        大成 　专注专业　追求卓越 <br />
                        创业 　激情奋斗　坚韧不拔<br />
                        执行 　快速响应　勇挑重担<br />
                        责任 　认真负责　客户至上</div>
                    <div className="line-4"></div>

                    <div className="fifth-theme">
                        <div className="first-theme-text-1">品 牌 核 心 价 值</div>
                    </div>
                    <div className="fifth-text">先进、灵动、适用（先进管理思想、灵动技术、适<br />用解决方案）</div>
                    <div className="line-5"></div>

                    <div className="sixth-theme">
                        <div className="second-theme-text-1">服 务 理 念</div>
                    </div>
                    <div className="sixth-text">解决问题 创造价值</div>
                    <div className="line-6"></div>

                    <div className="seventh-theme">
                        <div className="first-theme-text-2">新 中 大 之 歌</div>
                    </div>

                    <div className="line-7"></div>

                    <div className="last-theme">
                        <div className="second-theme-text-1">品 牌 宣 言</div>
                    </div>
                </div>
            </div>
        );
    }
}


//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <div className="second-page-body">
                    <pre className="second-page-text-left" id="playmusic">
                        让我们开创管理软件新纪元<br />
                        为了心中坚守的使命<br />
                        相聚在西子湖畔<br />
                        我们拥有一个共同的名字<br />
                        NEWGRAND——新中大<br />
                        如同神喻一般的启示：<br />
                        创新、中和、大成——是奔腾在我们心中的血液。<br />
                        先进、灵动、适用——是我们那渴望飞翔的灵魂。<br />
                        新中大<br />
                        我们品格的昭示<br />
                        我们诺守的信条和操守<br />
                        以新中大品牌的名誉，我们承诺：<br />
                        先进管理思想、灵动技术、适用解决方案，<br />
                        吴越春秋风云纵横<br />
                        在这片炽热的大地上<br />
                        胸中涌动着难以割舍的乡恋
                    </pre>
                    <div className="second-page-text-right" >
                        沿着科技先辈们的足迹<br />
                        我们新中大同仁走过了几多风雨<br />
                        超越顾客期待、回报股东的信任，为社会创造财富<br />
                        新中大<br />
                        我们心灵的烙印<br />
                        我们实现自我价值的舞台<br />
                        为了新中大品牌的荣誉<br />
                        我们尽心尽力为身边的员工着想<br />
                        用心创造是我们工作日志上永恒的符号<br />
                        执着于顾客先进管理是我们不渝的天职<br />
                        让先进、灵动和适用的品牌之花<br />
                        如同西湖映日荷花般盛开<br />
                        我们共同创造属于我们的美好未来。<br />
                        今天<br />
                        神州的天空如此明朗空灵<br />
                        那是因为<br />
                        我们必将开创管理软件的新纪元
                </div>
                </div>
            </div>
        );
    }
}

//网页主模块
class CorporateCulture extends React.Component {
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
        this.state = {
            position: 0,//初始化位置信息为0 
        }

    }

    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }


    initAnimation() {
        ani.initclass("first-text", "moveleft100-center-init");
        ani.initclass("second-text", "moveright100-center-init");
        ani.initclass("third-text", "moveleft100-center-init");
        ani.initclass("fourth-text", "moveright100-center-init");
        ani.initclass("fifth-text", "moveleft100-center-init");
        ani.initclass("sixth-text", "moveright100-center-init");

        ani.initclass("second-page-text-left", "opacity-initial");
        ani.initclass("second-page-text-right", "opacity-initial");

    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("first-text", 0, "moveleft100-center-notime");
        ani.getClassHight("second-text", 0, "moveright100-center-notime");
        ani.getClassHight("third-text", 0, "moveleft100-center-notime");
        ani.getClassHight("fourth-text", 0, "moveright100-center-notime");
        ani.getClassHight("fifth-text", 0, "moveleft100-center-notime");
        ani.getClassHight("sixth-text", 0, "moveright100-center-notime");
    
        ani.getClassHight("second-page-text-left", 0, "opacity-change");
        ani.getClassHight("second-page-text-right", 0, "opacity-change");
        this.playAudio("playmusic", -200);
    }

    //当div出现在屏幕上是播放音乐
    playAudio(id, showposition) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            document.getElementById("music").play();
        }
    }




    render() {
        return (
            <div>
                <Head />
                <div className="corporate-culture">
                    <FirstPage />
                    <SecondPage />
                    <audio loop="loop" id="music">
                        <source src={global.config.url + "/newgrand/picture/img/%E5%85%AC%E5%8F%B8%E4%BB%8B%E7%BB%8D/%E4%BC%81%E4%B8%9A%E6%96%87%E5%8C%96/%E6%96%B0%E4%B8%AD%E5%A4%A7%E7%A7%91%E6%8A%80%E8%82%A1%E4%BB%BD%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8.mp3"} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                    <Bottom />
                </div>
            </div>
        );
    }
}

export default CorporateCulture;