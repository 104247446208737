import React from "react";
import RenderWithTitle from "@/high-orders/RenderWithTitle";
import IconItem from "@/new-components/IconItem";
import './index.less';

function PointPanel(config) {
  const colCount = config.colCount
  const rowCount = config.rowCount
  const inner = [];

  [...Array(rowCount)].forEach((el, rowIndex) => {
    let currRow = [];
    [...Array(colCount)].forEach((ele, colIndex) => {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      !!currItemConfig && (currRow.push(
        <IconItem className="item" key={currItemConfig.key} describe={currItemConfig.describe} iconStyle={{
          backgroundImage: config.default.backgroundImage,
          backgroundPosition: `calc(${-10 - currItemConfig.iconPosition.x * 100}vw/14.4) calc(${-10 - currItemConfig.iconPosition.y * 100}vw/14.4)`,
        }} />))
    });

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  });

  return <div className="point-panel"> {inner}</div>
}

export default RenderWithTitle(PointPanel, '方案特点');