import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/5/`;
export default {
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "新中大移动应用管理解决方案本着“让工作找人，轻松处理工作”的理念，以新中大应用系统作为业务数据来源，将传统的PC端业务办理延伸到了移动智能设备端。针对不同行业和管理需求,方案配置了即时通讯、移动办公、移动项目管理、移动CRM管理以及移动报表管理等模块。并且结合移动设备语音、图像、坐标定位等多种输入特性，实现更加智能、方便地数据采集过程。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "移动应用管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '统一的移动应用门户  '
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '专业的跨平台化应用'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '灵动的系统表单定义'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '自由的自定义级表单'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '完善的安全控制体系'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '人性的视觉交互设计'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        title:'随时随地移动办公',
        describe: '突破办公场地的隔阂,随时随地能够在手机上发起业务,审批业务,查询业务。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        title:'即时通讯及时沟通',
        describe: '新中大移动APP能够通过文字、语音、图片等手段加强与同事之间的交流。围绕业务单据、报表的多人讨论极大改善了沟通的效果。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        title:'最新信息主动推送',
        describe: '新消息,新公告,新业务主动推送至用户手机,让业务办理与通知快人一步。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        title:'电脑手机数据联动',
        describe: '电脑端与移动端办理的业务数据联动,实现移动端办理与电脑端办理效果相同。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        title:'各行各业模块丰富',
        describe: '针对不同行业和管理需求,配置了即时通讯,移动办公,移动项目管理,移动CRM管理以及移动报表管理等模块。'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 1 },
        title:'支持企业微信接入',
        describe: '支持将新中大移动模块接入企业微信,让您在一个APP中实现多个APP的功能,在将来还将支持更多的第三方平台。' 
      }
    ]
  },
  sessionE: {
    title: '甘肃第六建设集团股份有限公司',
    describe: '甘肃第六建设集团股份有限公司，隶属于中国500强企业——甘肃省建设投资（控股）集团总公司，是中国建设系统企业信誉AAA单位。拥有房屋建筑工程施工总承包、市政公用工程施工总承包、机电安装工程施工总承包、建筑装修装饰专业承包、消防设施工程专业承包、钢结构工程专业承包、起重设备安装工程专业承包、附着升降脚手架专业承包及项目代建等9个壹级资质。',
    backgroundImage:`url(${baseUrl}anli.png)`
  }
}