/*
 * @Author: your name
 * @Date: 2019-12-16 16:23:45
 * @LastEditTime : 2020-01-09 13:56:42
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\components\cardL\Card.js
 */
import React from 'react';
//import './index.css';
import './card.less'

class Card extends React.Component {
    render() {
        return (
            <div className="join-us">
                <div className="card-l">
                    <div className="card-l-img">
                        <img alt="" src={this.props.img}/>
                    </div>
                    <div className="card-l-title">{this.props.title}</div>
                    <div className="card-l-text" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
                    <div className="interval-50" />
                </div>
            </div>

        );
    }
}

export default Card;