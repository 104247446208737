import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/i8c/i8c/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/i8c/i8c/banner.png`,
    describe: "面对着力推动互联网和实体经济深度融合的机遇，建筑企业数字化转型升级迎来了新机遇，进入新阶段。 如何适应市场环境的改变、如何快速提升企业核心竞争力、如何提高建筑企业管理水平，已经成为建筑企业从做大到做强的过程中必须要思考的问题。建筑企业的数字化主要体现在管理数字化方面，管理数字化即常见的管理信息化，围绕企业管理在标准化基础上实现管理信息化。新中大i8c工程项目管理云平台，以项目管理为核心，强调纵向管控，横向沟通，帮助企业能够快速消除信息孤岛，蕴含的前沿理念和管理思想“倒逼”企业制度流程的完善和管理升级，并通过信息化平台快速提高执行力和加强风险管控，成为企业加强内控和提高精细化管控水平的有力保障。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "i8c工程项目管理云平台",
    imgStyle: {
      maxHeight: '100%',
    },
  },

  sessionB: {
    src: global.config.url + '/NewGrand/media/i8.e71a8980.mp4'
  },

  // 产品特点
  sessionC: {
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 0 },
        describe: '标准化应用',
        img: global.config.url + '/newgrand/picture/img/i8c/i8c/a.svg',
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 0 },
        describe: '云共享中台',
        img: global.config.url + '/newgrand/picture/img/i8c/i8c/b.svg',
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 0 },
        describe: '原生业财一体',
        img: global.config.url + '/newgrand/picture/img/i8c/i8c/c.svg',
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 0 },
        describe: '生态链融合',
        img: global.config.url + '/newgrand/picture/img/i8c/i8c/d.svg',
      },
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${global.config.url}/newgrand/picture/img/i8c/i8c/100.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: baseUrl,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        img: '1.svg',
        title: '经营管理',
        describe: '经营管理主要包括项目投标管理、项目注册、项目组织人员管理等。通过经营管理模块实现投标过程信息全记录、形成投标项目档案库；跟踪项目投标过程，增加过程审批，规避前期风险；汇总项目商机，统筹决策；通过投标总结，积累同类型项目投标经验，并建立中标项目档案及业绩库，便于后续全项目周期的过程管理。',
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '施工合同管理',
        img: '2.svg',
        describe: '施工合同管理主要包括收入BOQ清单导入、承包合同管理、产值管理、其他收入支出合同管理、项目结算单业务、合同报表分析等。系统支持预算工程量清单对接，实现以清单模式的对外业主计量、对内产值填报；全面记录施工合同执行履约数据，形成各级合同信息台帐，随时查询合同执行数据及附件；通过规范在线审批流程，提高效率，提前识别风险；通过预警设置控制审批、结算、付款，动态监控合同风险。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '分包合同管理',
        img: '3.svg',
        describe: '通过分包合同管理实现分包供应商信息共享、供应商履约监管、分包合同模板化管理。通过从分包合同线上评审与签订、付款审批、领料与结算的全过程管控，掌握各单位各项目分包合同签订及结算、付款、收票情况，统筹各合同资金支付，建立分包商问题反馈机制，为分包商评价和黑名单管理提供依据。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '物资管理',
        img: '4.svg',
        describe: '物资管理实现材料的需求采购、采购合同、库存核算、报表查询等功能，物资管理同时还包括对自有周材、租赁周材、大临设施的管理。系统可帮助企业建立标准的物资信息库，支持企业物资的集中采购业务，实现材料价格横向纵向立体化对比分析；加强材料的现场管控，监管现场材料的实际入库和消耗；通过打通采购需求、采购合同、采购要货订单、采购入库与采购结算、采购发票、采购资金付款全过程业务，实现采购合同的“四流合一”。'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '设备管理',
        img: '5.svg',
        describe: '设备管理主要包括对自有设备和租赁设备的管理。对设备进行卡片台账登记，同时管理设备运行过程管理，包括设备的安拆、维修保养、检验检测等业务；对租赁设备，实现租赁设备的合同管理，包括租赁设备的租赁合同的台班单价、过程台班的计量、租赁合同的付款申请等业务；支持租赁设备进退场单以及租赁费用自动计算功能，方便设备台账管理及成本分析。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '成本管理',
        img: '6.svg',
        describe: '成本管理主要应用于项目计划成本、目标成本等预算编制，项目成本预控，项目成本核算。通过系统应用协助企业梳理成本核算维度和核算科目，构建企业标准成本核算体系，实现成本的信息化管理；建立成本管理事前计划，事中过程控制，通过计划成本控制业务发生，实现管控前置；实际成本实时归集，预算实际对比分析等；将成本超支情况聚焦到具体分部分项和费用类型，进而更好得进行调整和控制，实时了解和控制项目成本风险。'
      },
      {
        key: 7,
        iconPosition: { x: 0, y: 1 },
        title: '发票税务管理',
        img: '7.svg',
        describe: '发票税务管理主要针对进项发票和销项发票的发票管理，以及纳税申报的税务管理。通过系统实现全企业发票电子化、网络化、集中管控，提升税票统筹能力；提供发票扫描录入、影像管理、自动化税务抵扣认证，提高增值税发票处理效率，节约企业税务管理成本；实现税务统筹、税款预缴、纳税申报、企业基准税负分析等功能，辅助企业实现计缴申报；通过项目基准税负分析和项目税负看板实现对项目的税负分析。',
      },
      {
        key: 8,
        iconPosition: { x: 1, y: 1 },
        title: '资金管理',
        img: '8.svg',
        describe: '通过项目内部虚拟资金账户的管理，实现资金计划、资金收入、资金支出、报表查询等功能。通过资金管理加强资金的集中管控力度；提高资金计划的精确度，提升资金筹措能力，并强化资金利用率；通过与合同管理的无缝集成，大大加强资金计划的制作效率；实时掌握供应商、分包商等资金往来情况；提高资金预警控制，通过资金计划进行收支平衡，提高企业资金风险把控能力，同时一旦资金支付有风险则能提前预警。'
      },
      {
        key: 9,
        iconPosition: { x: 2, y: 1 },
        title: '生产管理',
        img: '9.svg',
        describe: '生产管理模块主要包括进度管理、质量管理、安全管理、竣工管理等功能应用。进度管理通过横道图、网络图管理，实现项目计划进度编制以及实际进度对比分析；质量管理通过质量计划管理、质量检査与评定、质量整改、质量知识库等方面的管理，实现工程质量全过程管理；安全管理提供安全管理知识体系支撑，并对安全关注点进行预警，防患于未然，减少安全事故发生；竣工管理对项目实施及竣工阶段资料可以进行存档，对于竣工资料的快速归集与査询非常有益。'
      },
      {
        key: 10,
        iconPosition: { x: 3, y: 1 },
        title: '财务管理',
        img: '10.svg',
        describe: '财务管理通过总账管理、出纳管理和票据管理帮助会计、出纳从繁杂的财务核算、凭证分录处理、账簿登记、报表核算等业务中解放出来，让他们有时间进行财务管理、数据分析等更高层次的业务；通过报表中心简化集团内部关联业务的处理，快速准确提供集团合并报表。'
      },
      {
        key: 11,
        iconPosition: { x: 4, y: 1 },
        title: '协同办公',
        img: '11.svg',
        describe: '协同办公主要实现日常的印章管理、证书管理、文档管理及新闻公告及工作表单管理等业务的处理。通过印章管理，建立印章台账，实现盖章审批、借章审批、刻章申请及印章回收预警等管理功能；通过证书管理，建立证书台账，并实现证书有效期及归还时间预警；实现证书借用归还；通过项目证书备案管理，应对《建设工程质量两年治理行动方案》；通过文档管理，建立文档、技术、质量、安全等相关知识库，实现知识库共享。'
      },
      {
        key: 12,
        iconPosition: { x: 5, y: 1 },
        title: '人事管理',
        img: '12.svg',
        describe: '人事管理主要包括组织管理、人事档案、薪资管理等功能。通过组织管理，实现组织机构的建立、组织关系设置等功能，包括项目班子成员组建、职位岗位体系等，同时要求在组织机构调整时不能影响系统的正常运行；通过人事档案，实现对员工档案信息建立、完善、调整功能。通过薪资管理，建立薪资管理体系，实现员工薪资管理，能够实现员工薪资计算及统计分析。'
      },
      {
        key: 13,
        iconPosition: { x: 5, y: 1 },
        title: '项目管控中心',
        img: '13.svg',
        describe: '项目管控中心实现项目整体管控和分析，为公司领导层提供决策分析平台。'
      }
    ],
    
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}case/a.png`,
        history:'/caseDetails/1129',
      },
      {
        history:'/caseDetails/1128',
        logoSrc: `${baseUrl}case/b.png`,
      },
      {
        logoSrc: `${baseUrl}case/c.png`,
        history:'/caseDetails/1127',
      },
    ]
  }
}