import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/3/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%9B%86%E5%9B%A2%E7%AE%A1%E6%8E%A7/%E5%8D%8F%E5%90%8C%E5%8A%9E%E5%85%AC/banner.png`,
    describe: "协同办公管理解决方案以统一平台、便捷办公为基本特点，以灵动工作流为核心技术，涵盖了信息门户、公文管理、流程审批、个人办公、文档知识、档案管理、证书管理、印章管理、流程监控等企业办公所需的各项功能。方案具有非常灵活的扩展能力，可根据企业用户自身业务需要，灵活添加办公功能。同时还集成了包括电子邮件、即时通讯等工具，使之与软件功能融为一体，为企业用户构建一个随时随地、移动办公平台。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "协同办公管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '信息共享化'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '流程规范化'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '审批移动化'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '文档电子化'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '资源一体化'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '决策科学化'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '互联互通，共享共治',
        describe: '通过构建企业统一协同工作平台，提升企业内相关单位之间协同工作能力，提高集团内信息沟通、办公事务的效率，加强企业内部的团队工作意识。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '制度流程化，流程信息化',
        describe: '工作流引擎从流程的定义、设计、授权、运用、监控，动态地反映了一个完整的业务处理流程。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '随时随地，移动办公',
        describe: '系统集成手机APP、邮件、即时通讯、企业微信等多种主流移动通讯工具，将企业办公与通讯工具融为一体。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '知识管理，创造价值',
        describe: '通过构建企业级的文档知识管理平台，将人与知识紧密结合，构建出企业内部完善的知识管理体系。'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '合理调配资源，降低管理成本',
        describe: '有效管理各种行政资源，例如会议、车辆、办公用品、企业证书、企业印章等。'
      }
    ]
  },
  sessionE: {
    title: '济南城建集团有限公司',
    describe: '济南城建集团有限公司是具有90年发展历史的国有大型企业，注册资本金26亿元。拥有市政公用工程施工总承包特级资质和工程设计市政行业甲级资质，同时还拥有公路、园林绿化、房建、水利、城市轨道交通、工程监理、招标代理、政府采购代理等全产业链资质。具有对外经济技术合作及工程承包、劳务合作经营权，通过了质量管理、环境管理、职业健康安全管理三大体系认证。',
    backgroundImage:`url(${baseUrl}anli.png)`
  }
}