import './index.less';
import React from 'react';

export default (props) => {
  let state = props;

  function onVideoClick(){
    var src = state.src;
    state.onVideoClick && state.onVideoClick(src);
  }

  return (
    <div className="video-v-2">
      <video autoPlay="autoplay" loop="loop" onClick={onVideoClick}>
        <source src="movie.ogg" type="video/ogg" />
        <source src={state.src} type="video/mp4" />
        <source src="movie.webm" type="video/webm" />
        <object data="movie.mp4" width="100%" height="100%">
          <embed width="100%" height="100%" src="movie.swf" />>
        </object>
      </video>
    </div>
  )
}