import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/4/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%9B%86%E5%9B%A2%E7%AE%A1%E6%8E%A7/%E7%A8%8E%E5%8A%A1%E7%AE%A1%E7%90%86/banner.png`,
    describe: "税务管理解决方案是在建筑业“营改增”推行的背景下，新中大科技依托多年的财税管理信息化建设经验，针对企业发票管理“量大、点多、线长、面广”特点，重点打造的基于企业全发票税务的电子化管理、网络化管控系统。系统支持单项目发票核算及企业级发票统筹，结合企业业务系统、财务系统，实现合同流、物流、资金流、票流四流合一，实现全方位的涉税风险管控。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "税务管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '四流合一，业财税一体'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '发票OCR识别，全影像采集，发票自动查验'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: `全类型票据采集，旅客运输票据一键打印归档`
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: `一键开具增值税电子发票、增值税普通发票和增值税专用发票`
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: `增值税专用发票一键抵扣`
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: `内置申报纳税申报表，满足对外快速申报`
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        describe: `内部税局，实时掌控涉税数据`
      },
      {
        key: 7,
        iconPosition: { x: 7, y: 0 },
        describe: `大数据预警，防范涉税风险`
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '提升企业发票管理效率',
        describe: '低成本解决建企“营改增”后涉税信息管理“量大、点多、线长、面广”的需求，实现发票税务电子化管理。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '提高企业税务管理能力',
        describe: '解决增值税涉税信息跨区域集中管理，通过异地预缴管理、对外自动纳税申报表自动生成、对内统筹清算等管理提升税务管理能力。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '提高企业税务筹划能力',
        describe: '系统提供管控几十亿级进项发票信息的能力，为实现增值税发票统筹提供信息化依据，提升企业税务统筹能力，通过统筹能力的提升降低企业税负和税务资金流出，产生直接税收资金统筹收益。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '风险预警，主动推送',
        describe: '系统提供发票禁报提醒、发票验证查验预警、发票360天有效期提醒、四流不合一预警、发票超预算预警、发票超合同预警等预警功能，随时发现发票税务管理风险，主动预警提示，快速识别处理。'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '提高企业对供应商和发票全方面风险预测能力',
        describe: '系统提供供应商税务风险分析和发票风险分析功能，从供应商全方面分析供应商的风险项，从发票的状态以及发票的收票抵扣情况进行分析预测发票的风险。'
      }
    ]
  },
  sessionE: {
    title: '浙江省建设投资集团',
    describe: '浙江省建设投资集团是成立最早的浙江国有企业。前身是浙江建筑公司，成立于1949年7月11日，历经68年的发展，集团已发展成为产业链完整、专业门类齐全、市场准入条件好的大型企业集团。现拥有各类企业资质35类94项，其中建筑工程施工总承包特级资质4项，甲级设计资质8项，其中获得资质为行业内最高资质的共计19类45项。同时拥有对外经营权、外派劳务权和进出口权，是浙江省建筑业走向世界参与国际建筑和贸易市场竞争的重要窗口，生产经营业务遍布国内31个省市自治区和阿尔及利亚、尼日利亚、日本、新加坡、香港等全球10多个国家和地区。',
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}