import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/se/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "</span>新中大银色快车Se</span><sup>+</sup><span> (Sliver Express)企业管理软件是以管理会计理论和集团财务管理实务为基础，以功能完善、操作方便、快速见效为指导原则，面向广大小企业的财务应用系统，帮助企业实现从基本核算到集团财务的信息化管理。Se</span><sup>+</sup><span>产品是在新中大财务管理软件NGPower产品的基础之上发展而成的一个升级替代产品，是在近十万各行各业用户的成功应用基础上，继承了新中大财务管理软件NGPower的优秀品质，充分吸取了新中大在管理软件领域的成功经验而逐渐形成的成熟产品，用心为广大小型企业信息化提供适用、高性价比的财务解决方案。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    dangerouslySetInnerHTML:'Se</span><sup>+</sup><span>企业管理软件',
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 4,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}se.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '简单、实用、高效'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '高性价比'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '物流、生产、资金流信息流全面协同'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '全方位开放体系'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '千锤百炼的成熟方案'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '高效便捷的材料需求计划'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        describe: '高扩展性，平滑过渡A3、i6系统'
      },
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}编组 15备份.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}se.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '账务处理模块',
        describe: '账务处理系统主要包括初始设置、日常账务、部门核算、往来账管理、项目核算、出纳及对账、系统间转账、系统管理功能；账务处理系统融合贯穿了财务预算、图形分析、数量核算、多币种核算、自动转账、多途径查询方式和多账套处理等强大功能。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '报表处理模块：',
        describe: '新中大公司自行开发的，类似EXCEL操作模式的财务报表制作工具，提供大量各类财务数据的取数公式，自动根据财务实际发生数获取实时报表，并提供了表内表间自动平衡校验、审计追踪、集中计算、汇入传出等功能，大大加快企业报表的制作过程。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '工资管理模块',
        describe: '适用于各个行业各个企业对工资管理的要求，实现工资核算、发放、员工档案管理、个人所得税和公积金等管理需求。工资管理系统既能结合整套se+财务管理系统一起运行，支持相关凭证的自动生成，也可以独立运行，进行独立的工资核算管理。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '固定资产模块',
        describe: '用于企业固定资产日常业务的核算和管理，对固定资产建立卡片和账册，设定折旧计算方法，按月自动计提折旧，及时反映固定资产购建、报废、清理、原值变动及其他变动情况，提供完整的与资产设备管理相关的报表及账簿，帮助企业管好自己的资产，使资产能够创造更大，更多的价值。 '
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '财务分析模块',
        describe: '帮助用户轻松、准确地实现企业、单位内部的财务决策、控制、预测、计划、审计等一系列的财务分析工作。系统提供常规的比率分析、结构分析等多种分析方式，配以二维、三维的各类图形，直观、形象的展示相关指标之间的关系，为客户提供更为全面的财务信息。'
      }
    ]
  },
}