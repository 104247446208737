/*
 * @Author: your name
 * @Date: 2020-01-06 16:02:35
 * @LastEditTime : 2020-01-07 13:26:10
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\overview\components\show\Show.js
 */
import React from 'react';
//import './index.css';
import './show.less'

class Show extends React.Component {
    render() {
        return (
            <div className="overview">
                <div className="show-style up-18 shadow" onClick={this.props.link}>
                    <div className="interval-30" />
                    <div className="show-img">
                        <img src={this.props.img}></img>
                    </div>
                    <div className="show-title">{this.props.title}</div>
                </div>
            </div>
        );
    }
}

export default Show;