/*
 * @Author: your name
 * @Date: 2019-12-16 09:49:05
 * @LastEditTime : 2019-12-23 09:44:36
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\common-components\list-text\ListText.js
 */
import React from 'react';
//import './index.css';
import './list-text.less'

class ListText extends React.Component {
    render() {
        return (
            <div className="common">
                <div className="text-moudule">
                    <div className="text-moudule-title">
                        <div className="text-moudule-title-icon"></div>
                        <div className="text-moudule-title-text">{this.props.title}</div>
                    </div>
                    <div className="text-moudule-text" dangerouslySetInnerHTML={{ __html: this.props.describe }}>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListText;