/*
 * @Author: your name
 * @Date: 2019-12-16 14:10:34
 * @LastEditTime : 2019-12-24 14:37:11
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\partner\components\card\Card.js
 */
import React from 'react';
//import './index.css';
import './card.less'

class Card extends React.Component {
    render() {
        return (
            <div className="partner">
                <div className="card">
                    <div className="interval-30" />
                    <div className="card-icon">
                        <img alt="" src={this.props.img}></img>
                    </div>
                    <div className="card-text">{this.props.text}</div>
                </div>
            </div>

        );
    }
}

export default Card;