/*
 * @Author: your name
 * @Date: 2020-01-06 16:59:22
 * @LastEditTime : 2020-01-06 17:10:10
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\overview\projectInvestment\ProjectInvestment.js
 */
import '../../../global.js';
import React from 'react';
import './special-solutions.less';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Show from '../components/show/Show'

class SpecialSolutions extends React.Component {

    handleClick(address) {
        this.props.history.push('/' + address);
    }


    render() {
        return (
            <div>
                <Head />
                <div className="special-solutions">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"安质环管理解决方案"} link={this.handleClick.bind(this, "safetyRing")}
                                        img={global.config.url + "/newgrand/picture/img/banner/1.png"} />
                                </td>
                                <td>
                                    <Show title={"成本管理解决方案"} link={this.handleClick.bind(this, "costControl")}
                                        img={global.config.url + "/newgrand/picture/img/banner/2.png"} />
                                </td>
                                <td>
                                    <Show title={"企业管控中心解决方案"} link={this.handleClick.bind(this, "enterpriseManagement")}
                                        img={global.config.url + "/newgrand/picture/img/banner/3.png"} />
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"人力资源解决方案"} link={this.handleClick.bind(this, "hrmanage")}
                                        img={global.config.url + "/newgrand/picture/img/banner/4.png"} />
                                </td>
                                <td>
                                    <Show title={"网络直报解决方案"} link={this.handleClick.bind(this, "live")}
                                        img={global.config.url + "/newgrand/picture/img/banner/5.png"} />
                                </td>
                                <td>
                                    <Show title={"项目管控中心解决方案"} link={this.handleClick.bind(this, "projectmanage")}
                                        img={global.config.url + "/newgrand/picture/img/banner/6.png"} />
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"协同办公解决方案"} link={this.handleClick.bind(this, "cOffice")}
                                        img={global.config.url + "/newgrand/picture/img/banner/7.png"} />
                                </td>
                                <td>
                                   
                                </td>
                                <td>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default SpecialSolutions;