/*
 * @Author: your name
 * @Date: 2020-01-13 10:13:54
 * @LastEditTime : 2020-01-13 14:54:13
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\ungrouped\newsDetails\NewsDetails.js
 */
import React from 'react';
//import './index.css';
import './more.less';
import '../../../global.js';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'

class More extends React.Component {

    render() {
        return (
            <div>
                <Head />
                <div className="more">
                    <div className="bg-body">
                        <div className="bg"></div>
                    </div>
                    <div className="more-body">
                        <div className="interval-50"></div>
                        <div className="title" style={{ color: '#000' }}>战略合作</div>
                        <div className="black">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/xq6p.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">美国微软（中国）有限公司</div>
                                <div className="text">1999年3月 新中大推出基于MS SQL Server7.0平台的财务及企业管理软件,并与微软举行了合作签字仪式，正式确立了与微软公司的战略性合作伙伴关系。成为新中大与国际接轨的重要标志。</div>
                            </div>
                        </div>
                        <div className="white">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/bim.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">国际商业机器公司(IBM)</div>
                                <div className="text">2003年8月20日，新中大与IBM公司正式结成战略合作伙伴。双方在中小企业管理软件市场领域展开合作,加强双方商业合作的优势互补。  </div>
                            </div>
                        </div>
                        <div className="black">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/26z7.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">赛贝斯软件（中国）有限公司</div>
                                <div className="text">2000年1月25日 新中大与国际知名数据库厂商Sybase公司签署战略合作协议，正式确定了双方的战略联盟关系。 同时推出了内含Sybase SQL Anywhere数据库的工作组产品，为中小型企业应用提供优秀的解决方案。</div>
                            </div>
                        </div>
                        <div className="white">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/浙大.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">浙大中控</div>
                                <div className="text">2006年10月16日，新中大公司与浙大中控再次联手，双方签订正式的联盟缔约。双方将在产品、市场、宣传解决方案等方面进行紧密联合，加强技术层面的交流和合作，力求在较短时间内整合提高由此两个产品组成的解决方案的易部属性、易实施性，提升合作建的相互支撑能力。通过双方合作提供的基于ESP-iSYS和URP的整合产品，能够为用户提供从生产现场实时数据采集(DLC/PCS)到MES、ERP解决方案，帮助用户解决最为关心的精细化管理和实时数据决策问题。</div>
                            </div>
                        </div>
                        <div className="black">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/卡迪夫大学.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">卡迪夫大学</div>
                                <div className="text">卡迪夫大学（Cardiff University ），是一所位于英国威尔士首府卡迪夫的世界优秀大学。该校于1883年取得皇家许可状，并为英国威尔士地区入选英国罗素大学集团（The Russell Group）的大学，该集团有英国的常春藤盟校之称。在英国大学排名中，卡迪夫大学列全英第5，仅次于帝国理工大学、伦敦政治经济学院、牛津大学和剑桥大学；在商学院综合排名中，卡迪夫在全英所有商学院中列第6位。此外，卡迪夫商学院的物流专业排名全英前列，世界前五，Lean Production和Supply Chain的研究处于业内优势地位。</div>
                            </div>
                        </div>
                        <div className="white">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/鲁班.png"}></img>
                            </div>
                            <div>
                                <div className="name">鲁班软件</div>
                                <div className="text">鲁班软件致力于BIM技术的研发和推广，为建筑产业相关企业提供基于BIM的数字化解决方案，业务涵盖房建、基建等多专业领域，提供专业的工程级BIM解决方案、基于BIM的城市规建管全生命周期解决方案、精装家装BIM整体解决方案等，服务贯穿住户级、工程级、城市级三大层级，为建筑企业转型升级、城市精细化管理、数字中国建设提供数据与技术支撑。</div>
                            </div>
                        </div>
                        <div className="black">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/攀成德管理顾问.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">攀成德管理顾问</div>
                                <div className="text">上海攀成德企业管理顾问有限公司（简称攀成德）聚焦于工程建设领域的专业咨询公司，服务领域涵盖勘察设计、建筑施工和房地产三大行业，为客户提供包括战略规划、集团管控、组织变革、流程管理、人力资源管理、项目管理和企业文化建设等方面的管理咨询、管理培训和研究服务。</div>
                            </div>
                        </div>
                        <div className="white">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/深信服科技.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">深信服科技</div>
                                <div className="text">深信服科技成立于2000年底，通过提供各种基于应用层的网络安全与网络优化产品，及网络基础架构产品，帮助组织维护网络稳定，促进业务发展。</div>
                            </div>
                        </div>
                        <div className="black">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/海康威视.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">海康威视</div>
                                <div className="text">海康威视是中国优秀的监控产品供应商，致力于不断提升视频处理技术和视频分析技术，面向全球提供先进的监控产品、技术解决方案与专业优质服务，为客户持续创造最大价值。目前在中国大陆33个城市已设立分公司，在香港、美国洛杉矶和印度也已设立了全资和合资子公司，并正在全球筹建更多的分支机构，为客户提供快捷、优质的服务。</div>
                            </div>
                        </div>
                        <div className="white">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/浙大管理学院.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">浙大管理学院</div>
                                <div className="text">近日，新中大与浙江大学管理学院正式签定战略合作协议，签约仪式在南京国际会议中心“2014建筑业创新与信息化案例体验大会”上举行，新中大董事长石钟韶和浙大管理学院院长吴晓波分别代表双方单位进行了协议的签署。</div>
                            </div>
                        </div>
                        <div className="black">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/浙商.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">《浙商》</div>
                                <div className="text">2005年7月，新中大公司与浙江浙商传媒有限公司正式结盟，双方将利用资源优势，在市场活动与市场宣传方面建立长期紧密的战略合作伙伴关系，加强双方商业合作机会、致力于推进浙商乃至中国企业管理的创新发展。</div>
                            </div>
                        </div>
                        <div className="white">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/苏商.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">《苏商》</div>
                                <div className="text">2006年，新中大公司与江苏苏商传媒有限公司达成正式合作，双方将利用资源优势，在市场活动与市场宣传方面建立长期紧密的战略合作伙伴关系，加强双方商业合作机会、致力于推进苏商乃至中国企业管理的创新发展。</div>
                            </div>
                        </div>
                        <div className="black">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/timg.jpg"}></img>
                            </div>
                            <div>
                                <div className="name">百望股份有限公司</div>
                                <div className="text">2017年11月，新中大与百望股份有限公司正式签署战略合作协议。未来双方将携手在税务管理信息化领域开展合作，推出针对建筑行业营改增后时代的“财税票一体化管理解决方案”，减轻建筑企业的税务管理负担，助力全行业快速完成营改增的落地与调整。</div>
                            </div>
                        </div>
                        <div className="white">
                            <div className="black-img">
                                <img src={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E5%90%88%E4%BD%9C%E5%9B%BE%E7%89%87/timg (1).jpg"}></img>
                            </div>
                            <div>
                                <div className="name">阿里巴巴</div>
                                <div className="text">2017年9月，新中大与阿里巴巴大企业采购平台在杭州阿里巴巴总部，正式签订战略合作协议。基于良好的业务合作基础及各自领域内的丰富经验及资源，双方决定在施工行业采购信息化领域建立战略合作伙伴关系。</div>
                            </div>
                        </div>

                    </div>
                    <div className="interval-100" />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default More;