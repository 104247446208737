import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/14/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E5%8F%AF%E8%A7%86%E5%8C%96%E5%B7%A5%E5%9C%B0%E7%AE%A1%E7%90%86/banner.png`,
    describe: "新中大数字标化工地管理解决方案依据各省市监管部门出台的标化工地建设的指导意见，以项目指挥中心为呈现载体，结合现场管理“人、机、料、法、环、测”六要素以及BIM应用，全面融合管理、整合数据，帮助建筑企业打造软硬一体、智能联动的数字化、标准化样板工地。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "数字标化工地管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 1,
    colCount: 4,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '详细政策解读依法依规'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '整合管理要素 综合全面'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '项企融合联动 提升管理'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '大屏指挥中心 科技酷炫'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        // title: '互联互通，共享共治',
        describe: '紧扣各地政策，帮助企业顺利通过认证验收。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        // title: '制度流程化，流程信息化',
        describe: '企业级融合，突出亮点。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        // title: '随时随地，移动办公',
        describe: '“人、机、料、法、环、测”六要素整合，全面无死角监管。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        // title: '知识管理，创造价值',
        describe: 'BIM融合，提升管理。'
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        // title: '合理调配资源，降低管理成本',
        describe: '工地观摩策划，辅助指导。'
      }
    ]
  },
  sessionE: {
    title: '华新建工集团有限公司',
    describe: '华新建工集团有限公司创建于1969年10月，2006年成为国家房屋建筑工程施工总承包特级资质企业，具有对外承包工程签约权、同时还具有建筑工程甲级设计院、房屋建筑工程施工总承包一级资质、市政公用工程施工总承包一级资质、起重设备安装工程、机电设备安装工程、建筑装修装饰工程、地基与基础工程、钢结构工程专业承包一级资质，是集工程总承包、设计施工一体化、建设投资运营、建筑工业化于一体的综合性集团。集团现拥有11个工程公司、24个项目管理公司。',
    backgroundImage:`url(${baseUrl}anli.png)`
  }
}