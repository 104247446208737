/*
 * @Author: your name
 * @Date: 2020-01-06 16:59:22
 * @LastEditTime : 2020-01-06 17:10:10
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\overview\projectInvestment\ProjectInvestment.js
 */
import '../../../global.js';
import React from 'react';
import './project-investment.less';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Show from '../components/show/Show'

class ProjectInvestment extends React.Component {

    handleClick(address) {
        this.props.history.push('/' + address);
    }


    render() {
        return (
            <div>
                <Head />
                <div className="project-investment">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"业主投资"} link={this.handleClick.bind(this, "projectInvestment")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%8A%95%E8%B5%84/%E5%B7%A5%E7%A8%8B%E6%8A%95%E8%B5%84%E4%BC%81%E4%B8%9A%E4%BF%A1%E6%81%AF%E5%8C%96/banner.png"} />
                                </td>
                                {/* <td>
                                    <Show title={"大型工程投资项目"} link={this.handleClick.bind(this, "largeProject")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E6%8A%95%E8%B5%84/%E5%A4%A7%E5%9E%8B%E5%B7%A5%E7%A8%8B%E6%8A%95%E8%B5%84%E9%A1%B9%E7%9B%AE%E4%BF%A1%E6%81%AF%E5%8C%96/banner.png"} />
                                </td> */}
                                <td>
                                    <Show title={"厂房新建(搬迁)"} link={this.handleClick.bind(this, "workShop")}
                                        img={global.config.url + "/newgrand/picture/img/%E6%95%B0%E5%AD%97%E5%B7%A5%E5%9C%B0/%E6%9C%BA%E6%A2%B0%E7%AE%A1%E7%90%86/banner.png"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default ProjectInvestment;