/*
 * @Author: your name
 * @Date: 2019-12-16 10:49:23
 * @LastEditTime : 2020-01-09 09:50:34
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\partner\qualification-enquiry\QualificationEnquiry.js
 */
import React from 'react';
//import './index.css';
import './qualification-enquiry.less';
import ani from '../../../transition/animation/animation'

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import ListText from '../../../common-components/list-text/ListText'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-img"> </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title1: "为何要通过正规渠道购买新中大软件",
            text1: "为保障每一位新中大用户的合法权益，新中大在中国大陆各地设立了分子公司及联盟体机构，并对每一家联盟体机构进行严格认证与培训，根据其实施、服务能力，在其颁发的授权证书上列明其被允许销售的产品类别及经营区域。如您预购的产品未在该授权类别之列，则表明该联盟体机构未能通过新中大的考核与认证，实施交付及服务保障能力存在重大隐患，您应慎重选择，以避免影响您日后正常使用和后续服务升级。<br/>" +
                "&nbsp&nbsp&nbsp&nbsp&nbsp新中大公司郑重告知各位用户，在非新中大授权的经销商购买新中大软件或服务，所产生的一切后果由用户自行承担，新中大公司不承担任何相关责任。",
            title2: "未获新中大授权的机构或销售新中大产品、服务行为包括但不限于：",
            text2: "1、任何从事解密、破解、修改新中大软件以及销售非授权范围内的新中大产品和服务行为；<br/>" +
                "2、未经新中大授权的机构或个人，冒用新中大或新中大授权机构名义销售新中大软件和服务；<br/>" +
                "3、新中大公司个别在职员工以个人名义销售新中大产品或承接服务，该情况往往是贵单位未与新中大签署合作合同，将不能获得新中大公司开具的发票。",
            title3: "如何查询联盟体伙伴资质",
            text3: "如果您希望了解所购买的新中大软件是否经过官方授权，请您仔细核对对方的新中大合作协议及授权证书，并通过我们官方网站的联盟体伙伴资质查询系统，输入对方公司全称，即可查询是否为新中大正规授权单位。",
        };
    }
    render() {
        return (
            <div className="second-page">
                <div className="interval-90"></div>
                <ListText title={this.state.title1} describe={this.state.text1} />
                <div className="interval-72"></div>
                <ListText title={this.state.title2} describe={this.state.text2} />
                <div className="interval-101"></div>
                <ListText title={this.state.title3} describe={this.state.text3} />
                <div className="interval-72"></div>
                <a href="http://114.215.170.33/NgrandServer/consultsearch/agentsearch" target="_blank" rel="noopener noreferrer">
                    <div className="second-page-search-img"></div>
                </a>
                <div className="second-page-bottom">
                    如果您发现所购买的新中大产品非授权经销商销售，请立即联系我们或者查询各地分公司。<br />
                    联系电话：0571-88270588-883<br />
                    传真：0571-88271232
                </div>
            </div>
        );
    }
}
//网页主模块
class QualificationEnquiry extends React.Component {
    //----从此处开始
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this);
    }
    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }

    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }

    

    initAnimation() {
        ani.initclass("text-moudule", "moveup100-init");
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        ani.getClassHight("text-moudule", 0, "moveup100");
    }



    render() {
        return (
            <div>
                <Head />
                <div className="qualification-enquiry">
                    <FirstPage />
                    <SecondPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default QualificationEnquiry;