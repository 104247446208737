/*
 * @Author: your name
 * @Date: 2019-12-16 14:38:37
 * @LastEditTime : 2020-01-08 17:33:30
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\join-us\career-development\CareerDevelopment.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './career-development.less';
import $ from 'jquery';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Card from '../components/cardL/Card'


//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page" id="s">
                <div className="first-page-body">
                    <div className="interval-col-146"></div>
                    <div className="left">
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/a.png"}
                            title={"人才理念"} text={"公司以人为本，人才是企业发展的源泉。<br/>　　人才是新中大的重要资源。新中大海纳百川，汇萃精华，始终把人才作为企业发展的创业之本、竞争之本、发展之本。经过二十多年的不断发展，已经形成了敬人敬业、公平竞争、尊重知识和人才的良好企业风气。公司以其独特的人才招聘战略、开阔的事业发展空间、优良的人才成长环境、有效的激励机制和人本的企业文化理念，使企业成为人才集聚的高地。<br/>　　我们希望有更多志同道合的人才加入新中大事业的行列，更多的人关注新中大的发展！"} />
                        <div className="interval-60" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/c.png"}
                            title={"职业发展通道"} text={"　双阶梯多通道的职业发展为员工在公司的职业发展提供了广阔的空间和平台：<br/>" +
                                "1.员工可以根据自己的能力特质和职业规划，有管理潜质和能力的员工走管理线职业通道；<br/>" +
                                "2.愿意沉淀在某一专业（包含产品研发、技术实施、市场销售、管理支持四大领域），潜心研究，成为该专业领域专家的员工走专业线职业通道；在某一专业领域内，亦有平行通道的职业调整和发展机会，如在产品研发领域，可以在产品设计管理、底层技术研发、产品应用开发、产品评测等方向平行调整发展。"} />
                        <div className="interval-60" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/d.png"}
                            title={"沟通渠道"} text={"公司倡导“勇于沟通、乐于沟通”的沟通文化，并提供畅通的沟通反馈渠道，员工可以通过各级的管理层沟通会议、电子邮件等方式向管理层提供各方面的建议和意见，也可以直接向自己的直接主管提出建议和意见，并可以按照公司提供的其他途径，向更上一级的领导反馈自己的问题。"} />
                    </div>
                    <div className="interval-col-70"></div>
                    <div className="right">
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/e.png"}
                            title={"薪酬福利"} text={"公司提供同行业中具有竞争力的薪酬，并建立了职务职级体系对应的薪酬体系，通过薪酬管理保持薪酬状况的对外、对内和对个人的公平和激励性。员工薪酬的主要依据是员工职位的价值、个人能力与业绩三大因素，我们期望员工在各自的岗位上发挥良好的工作能力，创造优异的工作业绩，展现个人价值。<br/>" +
                                "1.根据国家规定为员工缴纳社会保险及住房公积金。<br/>" +
                                "2.根据国家规定并结合公司自身规定，提供优于国家法定的员工带薪年假。<br/>" +
                                "3.提供员工午餐补贴、住房补贴、结婚礼金、生育礼金、生日礼品、重大节日礼品等。<br/>" +
                                "4.以部门为单位，每位员工年度享有一定数额的活动经费，用于部门活动及员工交流。<br/>" +
                                "5.安排员工年度身体健康检查及其它个性化福利。"} />
                        <div className="interval-60" />
                        <Card img={global.config.url + "/newgrand/picture/img/%E5%8A%A0%E5%85%A5%E6%88%91%E4%BB%AC/b.png"}
                            title={"职业培训"} text={"我们一直认为员工培训是公司重要经营工作之一，公司的发展离不开员工能力发展和职业发展。<br/>" +
                                "新员工导师制度：公司为每一名新同事配备导师进行辅导，为期3-6个月，帮助新同事明确工作职责，掌握工作专业技能，提高工作效率，尽快融入新的工作和生活环境。<br/>" +
                                "新员工入司集中培训：新员工的集中培训课程包括公司发展历程、企业文化、公司管理制度体系、公司产品体系、公司业务体系、行业知识、职业素养技能等。<br/>" +
                                "新员工轮岗学习：产品研发、市场销售、技术实施等职类的新同事入司后，将安排进入不同部门轮岗学习，帮助新同事熟悉公司的产品和业务流程，更快的进入工作角色。<br/>" +
                                "员工在职培训：根据员工岗位和任职要求，提供相应的专业技能和管理培训，帮助员工有效工作，并为员工在公司平台上的职业发展提供更有利的支持。"} />
                    </div>
                </div>
                <div className="interval-353" />
            </div>
        );
    }
}

//网页主模块
class CareerDevelopment extends React.Component {
    //----从此处开始
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this)
    }
    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initclass("join-us", "moveup-initial");
    }
    //获取相关id的高度
    getHight(id, showposition, classname) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }
    //初始化添加动效的类名
    initclass(classname, addclassname) {
        let classlist = $("." + classname);
        for (let i = 0; i < classlist.length; i++) {
            $(classlist[i]).addClass(addclassname);
        }
    }
    //监控所有指定class的目标
    getClassHight(myclass, showposition, classname) {
        let classlist = $("." + myclass);
        for (let i = 0; i < classlist.length; i++) {
            let height = $(classlist[i]).offset().top - $(window).scrollTop() - $(window).height();
            if (height < showposition) {
                $(classlist[i]).addClass(classname);
            }
        }
    }
    //触发本组件的动画
    getOtherHight(otherid, id, showposition, classname) {
        let height = $("#" + otherid).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }

    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        this.getClassHight("join-us", 200, "moveup-notime")
    }
    render() {
        return (
            <div>
                <Head />
                <div className="career-development">
                    <FirstPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default CareerDevelopment;