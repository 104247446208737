/*
 * @Author: your name
 * @Date: 2019-12-16 09:35:06
 * @LastEditTime : 2020-01-09 09:23:11
 * @LastEditors  : Please set LastEditors
 * @Description: 官网伙伴的伙伴支持页面
 * @FilePath: \my-demo\src\pages\partner\partner-support\PartnerSupport.js
 */
import React from 'react';
//import './index.css';
import './partner-support.less';
import $ from 'jquery';


//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import ListText from '../../../common-components/list-text/ListText'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-img"> </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title1: "1.产品支持",
            text1: "优秀的管理思想及产品架构优势：新中大软件产品将管理思想融入软件应用的方方面面，使其真正具备灵魂和思想，实现信息化软件价值的最大化，并开创URP理论思想。<br/>"+
            "产品持续性创新，巨大的市场潜力：产品以“融通管理”为理念，坚持“高度集成的平台+模块拆分销售+可分步实施的应用”。产品设计从客户实际需求出发，支持稳定、安全、成熟的长期可持续性发展，提供实时动态信息交互，产品满足当今企业对于信息化管理的需求趋势，巨大的市场需求显而已见。<br/>"+
            "坚持“易学易用易实施”理念，降低投资风险：成熟的产品架构及人性的操作界面设计，简易的操作性能，与行业内其它软件相比，新中大软件产品稳定性更高，集成性、可靠性更强、实施更便捷，投资风险大大降低。",
            title2: "2.专家顾问支持——专业化培训服务，助力伙伴快速收益",
            text2: "整合优势管理资源，深度把握市场需求：新中大整合了来自国际知名IT企业厂商、知名高校管理学院、产业联盟、政府等优势资源，形成了强大的专家顾问团队。<br/>"+
            "强大的技术实力，产品实力保障：新中大拥有近500人的专业队伍，20%硕士、博士，具备管理与计算机专业知识。同时与IBM、Intel、HP、Microsoft、ORACLE、Sybase等公司建立良好的技术合作关系。为伙伴提供了强大的技术支持以及成熟的解决方案，支持伙伴的健康发展。",
            title3: "3.市场支持—助力伙伴快速成长",
            text3: "新中大20年品牌影响力，合作伙伴的信心保障：20年来，新中大一直以来把品牌建设和企业文化作为企业发展重点。新中大倡导知识、技术的共享，坚持“深耕行业、深度聚焦”企业发展战略。<br/>"+
            "巨大的市场投入，合作伙伴的优质服务：新中大在渠道建设上投入了大量的资源与支持，从渠道市场启动策划、当地市场分析、产品宣传资料、营销推广支持，以及媒体支持、活动支持等多样的支持保障，与伙伴紧密接触，帮助伙伴快速成长。",
        };
    }
    render() {
        return (
            <div className="second-page">
                <div className="interval-90"></div>
                <ListText title={this.state.title1} describe={this.state.text1} />
                <div className="interval-72"></div>
                <ListText title={this.state.title2} describe={this.state.text2} />
                <div className="interval-101"></div>
                <ListText title={this.state.title3} describe={this.state.text3} />
                <div className="interval-72"></div>  
            </div>
        );
    }
}
//网页主模块
class PartnerSupport extends React.Component {
    //----从此处开始
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this)
    }
    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }
    //获取相关id的高度
    getHight(id, showposition, classname) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }
    //初始化添加动效的类名
    initclass(classname, addclassname) {
        let classlist = $("." + classname);
        for (let i = 0; i < classlist.length; i++) {
            $(classlist[i]).addClass(addclassname);
        }
    }
    //监控所有指定class的目标
    getClassHight(myclass, showposition, classname) {
        let classlist = $("." + myclass);
        for (let i = 0; i < classlist.length; i++) {
            let height = $(classlist[i]).offset().top - $(window).scrollTop() - $(window).height();
            if (height < showposition) {
                $(classlist[i]).addClass(classname);
            }
        }
    }
    //触发本组件的动画
    getOtherHight(otherid, id, showposition, classname) {
        let height = $("#" + otherid).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }

    initAnimation() {
        this.initclass("text-moudule", "moveup100-init");

    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        this.getClassHight("text-moudule", 0, "moveup100");
    
    }

    render() {
        return (
            <div>
                <Head />
                <div className="partner-support">
                    <FirstPage />
                    <SecondPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default PartnerSupport;