import './index.less';
import React from 'react';

export default (contentComponent, moreBtnLabel, style, onClick) => {
  const Content = contentComponent;

  class ContentWithMoreBtn extends React.Component {
    constructor(props) {
      super(props)
      this.handleBtnClick = this.handleBtnClick.bind(this)
    }

    handleBtnClick(e) {
      console.log('被点击'); onClick && onClick({ ...e })
      console.log('onClick :', onClick);
    }
    render() {
      const { forwardedRef, ...rest } = this.props;

      return (
        <div className="moreBtn-container" style={{ ...style }}>
          <Content {...rest} />
          <div className="moreBtn" onClick={this.handleBtnClick}>
            <span>{moreBtnLabel}</span>
          </div>
        </div>
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <ContentWithMoreBtn {...props} forwardedRef={ref} />;
  });
}