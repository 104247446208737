/*
 * @Author: your name
 * @Date: 2019-12-17 10:14:25
 * @LastEditTime : 2019-12-23 10:07:06
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\projectInvestment\components\card-b\CardB.js
 */

import React from 'react';
//import './index.css';
import './card-b.less'

class CardB extends React.Component {
    render() {
        return (
            <div className="project-investment">
                <div className="card-b">
                    <div className="interval-32"></div>
                    <div className="card-b-title">{this.props.title}</div>
                    <div className="card-b-text">{this.props.text}</div>
                </div>
            </div>

        );
    }
}

export default CardB;