import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/10/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E5%88%B6%E9%80%A0/banner.png`,
    describe: "项目制造管理解决方案致力解决以价值链为驱动的联盟体内外各种资源配置优化，实现一体化企业管理应用，方案应用涉及品牌企业供应链、生产计划、制造执行、客户关系、财务管理、人力资源、协同工作、知识管理、商务智能、企业门户等。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "项目制造管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 2,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '以销定购，以销定产来满足项目制造行业按订单设计或生产的特殊行业要求。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '全程项目管控，精确核算料、工、费到每笔销售订单，控制成本及效益最大化。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '多项目穿插，多项目进度、安全、质量的风险管控。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '从客户关系管理，合同管理以及销售管理三大维度来构建项目制造业营销管理平台。'
      },
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 4,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 1 },
        // title: '',
        describe: '为企业搭建信息化平台，实现对非标产品项目管理企业的全过程管理。提供从接单、报价、到生产、交货、结算的动态订单跟踪功能。'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 1 },
        // title: '制度流程化，流程信息化',
        describe: '通过产品族配置、柔性制造和快速响应来实现产品的多样化和定制化。'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 1 },
        // title: '随时随地，移动办公',
        describe: '提供完整的成本控制体系，解决订单洽谈过程中订单估价困难的问题，并支持企业生产过程的成本监控，让企业实时了解到订单的实际成本。'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 1 },
        // title: '知识管理，创造价值',
        describe: '优化业务流程，建立系统的信息流、物流、资金流管理模式，减少因采购、技术等因素引起的停工滞料产品交期延误的问题。'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 1 },
        // title: '合理调配资源，降低管理成本',
        describe: '通过齐套缺件管理，制定基于能力平衡的切实可行的计划。对项目的产品完工和库存齐套进行跟踪和测算。'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 1 },
        // title: '随时随地，移动办公',
        describe: '针对装备制造企业订单的资金占用较大的特点，在接单时通过资金计划做到执行过程中的资金平衡，提高资金周转率。'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 1 },
        // title: '知识管理，创造价值',
        describe: '整个项目在合同签订前就确定了项目编码（合同号、制令号），该号码将贯穿整个项目的始终，方便企业对项目情况进行跟踪。'
      }
    ]
  },
  sessionE: {
    title: '申龙电梯股份有限公司',
    describe: '申龙电梯股份有限公司创建于1992年，是一家集设计、制造、销售、安装、服务于一体的现代化专业电梯生产企业。 2019年初，公司与中车城市交通有限公司产业重组，成为中车城市交通有限公司控股子公司。申龙电梯在国内主要省市建立了系统的营销服务网络。目前，产品不仅成功销往全国31个省、市、自治区，更是远销俄罗斯、马来西亚、波兰、澳大利亚、法国、约旦、孟加拉、印度等五十多个国家和地区。',
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}