/*
 * @Autor: qxy
 * @Date: 2022-01-24 10:58:29
 * @LastEditors: qxy
 * @LastEditTime: 2022-01-24 15:28:01
 * @description: i8c
 */
import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Abstract from "../../../new-components/Abstract";
import RenderWithTitle from '../../../high-orders/RenderWithTitle';
import ContactBottom from '../../../new-components/ContactBottom';
import RenderWithMoreBtn from '../../../high-orders/RenderWithMoreBtn';
import IconTitleItem from "./component/IconTitleItem";
import config from './config';
import './i8c.less';

const SessionC = () => {
  return (
    <div className="c">
      {config.sessionC.items.map((item) => <div key={item.key} className='item'>
        <img className='img' src={item.img} alt='img' />
        <div className='text'>{item.describe}</div>
      </div>)}
    </div>
  );
}
const Td = RenderWithTitle(SessionC, "产品特点");

function SessionF(props) {
  const history = useHistory()
  return <div className="session-f">{props.items.map((el, index) => <img
    key={index}
    src={el.logoSrc}
    alt=""
    className="up-18"
    // onClick={() => {
    //   history.push(el.history)
    // }}
  />)}</div>
}

const SessionDWithTitle = RenderWithTitle(() => <div className="session-d"><img src={config.sessionD.imgSrc} alt="" /></div>, "产品架构", {
  background: `linear-gradient(360deg,rgba(241,255,255,0) 0%,rgba(242,245,246,1) 100%)`,
});

const SessionE = (config) => {
  const colCount = config.colCount;
  const rowCount = config.rowCount;
  const onClick = config.toggleModules;
  const inner = [];

  for (let rowIndex = 0; rowIndex < rowCount; rowIndex += 1) {
    let currRow = [];

    for (let colIndex = 0; colIndex < colCount; colIndex += 1) {
      let currItemConfig = config.items[rowIndex * colCount + colIndex];
      if (!currItemConfig) {
        break;
      }

      currRow.push(
        <IconTitleItem
          key={currItemConfig.key}
          describe={currItemConfig.describe}
          img={config.default.backgroundImage + currItemConfig.img}
          title={currItemConfig.title}
        />
      );
    }

    inner.push(<div className="row" key={rowIndex}>{currRow}</div>);
  }

  return (<div className="session-e">
    {inner}
    <div className="btn">
      {
        (() => {
          if (rowCount === 3) {
            return (
              <div onClick={() => { onClick && onClick("open") }}>
                <div className="down"></div>
                <div className="text">展开</div>
              </div>
            )
          } else {
            return (
              <div onClick={() => { onClick && onClick("close") }}>
                <div className="up" ></div>
                <div className="text">收起</div>
              </div>
            )
          }
        })()
      }
    </div>
  </div>)
}

const SessionEWithTitle = RenderWithTitle(SessionE, "系统模块", {
  backgroundColor: '#F2F5F6'
});

const I8c = () => {
  const [rowCount, setRowCount] = useState(4);
  const colCount = 2;
  const history = useHistory();

  const SessionFWithTitleAndMoreBtn = RenderWithTitle(RenderWithMoreBtn(SessionF, '更多案例 >', {}, () => {
    history.push('/caseof11/i8工程项目管理平台/产品')
  }), '用户案例');

  const handleToggleModules = (action) => {

    if (action === 'open') {
      setRowCount(7);
    } else {
      setRowCount(3);
    }
  }

  return (
    <div className="new-i8c-page">
      <Head />
      <Abstract {...config.sessionA} />
      <Td />
      <SessionDWithTitle />
      <SessionEWithTitle {...config.sessionE} colCount={colCount} rowCount={rowCount} toggleModules={handleToggleModules} />
      {/* <SessionFWithTitleAndMoreBtn {...config.sessionF} /> */}
      <ContactBottom />
      <Bottom />
    </div>
  );
}

export default I8c;