/*
 * @Author: your name
 * @Date: 2020-01-13 10:13:54
 * @LastEditTime: 2022-01-13 11:25:52
 * @LastEditors: qyy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\ungrouped\newsDetails\NewsDetails.js
 */
import React from 'react';
//import './index.css';
import './news-details.less';

//引入prefeb模块 
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Axios from 'axios';

class NewsDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: {},
        }
    }

    componentDidMount() {
        console.log(this.props)
        if (typeof (this.props.match) != "undefined") {
            console.log(this.props.match.params.id);
            var id = this.props.match.params.id;
        }
        console.log(id);

        Axios.get(global.config.serverUrl+'/NewsInfo/LoadInfo/' + id)
        .then( (news) => {
            console.log(news.data);
            this.setState({ news: news.data })
        })
        .catch( (error) => {
            console.log(error);
        });
    
    }

    render() {
        var time = this.state.news.PublishDate;
        if (time !== undefined && time !== null){
            time = time.substring(0, 10);
        }

        return (
            <div>
                <Head />
                <div className="news-details">
                    <div className="title">{this.state.news.NewsName}</div>
                    <div className="introduce">来源: | 作者:newgrand |  发布时间: {time}</div>
                    <div className="main-text" dangerouslySetInnerHTML={{__html:
                        this.state.news.NewsContentHtml && this.state.news.NewsContentHtml.replaceAll('http://47.96.72.83:8086', global.config.serverUrl)
                    }}>
                    </div>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default NewsDetails;