/*
 * @Author: your name
 * @Date: 2020-01-06 13:52:08
 * @LastEditTime : 2020-01-06 18:10:08
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\overview\groupControl\GroupControl.js
 */
import '../../../global.js';
import React from 'react';
import './group-control.less';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Show from '../components/show/Show'

class GroupControl extends React.Component {

    handleClick(address) {
        this.props.history.push('/' + address);
    }


    render() {
        return (
            <div>
                <Head />
                <div className="group-control">

                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"集团财务"} link={this.handleClick.bind(this, "groupFinance")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%9B%86%E5%9B%A2%E7%AE%A1%E6%8E%A7/%E9%9B%86%E5%9B%A2%E8%B4%A2%E5%8A%A1/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"税务管理"} link={this.handleClick.bind(this, "taxAdministration")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%9B%86%E5%9B%A2%E7%AE%A1%E6%8E%A7/%E7%A8%8E%E5%8A%A1%E7%AE%A1%E7%90%86/banner.png"} />
                                </td>
                                <td>
                                    <Show title={"协同办公"} link={this.handleClick.bind(this, "cooperativeOffice")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%9B%86%E5%9B%A2%E7%AE%A1%E6%8E%A7/%E5%8D%8F%E5%90%8C%E5%8A%9E%E5%85%AC/banner.png"} />
                                </td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td>
                                    <Show title={"集团人力资源"} link={this.handleClick.bind(this, "humanResources")}
                                        img={global.config.url + "/newgrand/picture/img/%E9%9B%86%E5%9B%A2%E7%AE%A1%E6%8E%A7/%E9%9B%86%E5%9B%A2%E4%BA%BA%E5%8A%9B/banner.png"} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Bottom />
            </div>
        );
    }
}

export default GroupControl;