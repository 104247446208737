import React from "react";
import RenderWithTitle from "@/high-orders/RenderWithTitle";
import './index.less';

function ArchitectureImage(props) {
  return (
    <div className="architecture-img-container">
      <img src={props.imgSrc} alt="" />
    </div>
  )
}

export default RenderWithTitle(ArchitectureImage, '方案架构图', {
  background: '#F2F5F6'
});