/*
 * @Author: your name
 * @Date: 2019-12-16 11:09:19
 * @LastEditTime : 2020-01-09 09:21:27
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\partner\cooperation-guide\CooperationGuide.js
 */
import '../../../global.js';
import React from 'react';
//import './index.css';
import './cooperation-guide.less';
import $ from 'jquery';

//引入prefeb模块
import Bottom from '../../../common-components/BottomBar'
import Head from '../../../common-components/TopBar/TopBar'
import Title from '../../../common-components/title/Title'
import Describe from '../components/describe/Describe'
import Logo from '../components/logo/Logo'
import Card from '../components/card/Card'

//网页第一个模块
class FirstPage extends React.Component {
    render() {
        return (
            <div className="first-page">
                <div className="first-page-img"> </div>
            </div>
        );
    }
}

//网页第二个模块
class SecondPage extends React.Component {
    render() {
        return (
            <div className="second-page">
                <Title text={"伙伴类型"} />
                <Describe text={"合作伙伴类型：新中大的合作伙伴类型分2个大类、5个小类。"} />
                <div className="second-page-img">
                </div>
                <div className="second-page-describe">
                    <div className="interval-col-165"></div>
                    <div className="second-page-describe-left">
                        <div className="second-page-title">标准伙伴定义</div>
                        <div className="second-page-text">
                            标准渠道是新中大公司授权的在指定的区域和行业从事新中大授权产品的销售、服务、项目合作的渠道代理商。包括：红旗代理、金牌代理、授权代理3个渠道类型。新中大渠道管理中心根据其业绩、指标完成率情况、公司规模、市场合作情况等实行为期每季度一次的渠道升降级考核。符合条件者予以升级，指标不达标者予以降级。
                        </div>
                    </div>
                    <div className="interval-col-111"></div>
                    <div className="second-page-describe-right">
                        <div className="second-page-title">合作伙伴的定义：</div>
                        <div className="second-page-text">
                            1、 战略合作：战略合作是指由新中大公司授权的在特定领域或行业与新中大公司进行紧密合作共同开拓市场的渠道类型。主要为大型独立软件开发商（ISV）、系统集成商（SI）、电子产品生产与销售商、管理咨询公司、会计师事务所等，要求与新中大公司能优势互补、互相提高产品的市场竞争力，可免予年度业绩考核。<br />
                            2、 项目合作：项目合作是指合作伙伴与新中大公司在尚未签订正式渠道授权许可经营的情况下针对个案项目进行的商务合作形式，根据项目具体贡献值签订项目分成方案，可免予年度业绩考核。
                        </div>
                    </div>
                </div>
                <div className="interval-80"></div>
            </div>
        );
    }
}

//第三部分
class ThirdPage extends React.Component {
    render() {
        return (
            <div className="third-page">
                <Title text={"渠道伙伴申请条件"} />
                <div className="third-page-body">
                    <div className="third-page-div1">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">1</div>
                            </div>
                            <div className="text-1">独立的法人资格;</div>
                        </div>
                    </div>
                    <div className="third-page-div2">
                        <div className="third-page-icon">
                            <div className="third-page-pic">
                                <div className="pic-num">2</div>
                            </div>
                            <div className="third-page-text">所在地工商行政管理局登记注册的“经营范围”中包括“销售计算机软件”（或同等同义）内容;</div>
                        </div>
                    </div>
                    <div className="third-page-div3">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">3</div>
                            </div>
                            <div className="text-1">从事新中大产品销售人员2名以上，服务人员1人以上;</div>
                        </div>
                    </div>
                    <div className="third-page-div4">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">4</div>
                            </div>
                            <div className="text-1">在当地有良好的商业信誉口碑和良好的客户资源;</div>
                        </div>
                    </div>
                    <div className="third-page-div5">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">5</div>
                            </div>
                            <div className="text-1">深入理解并执行新中大的品牌战略;</div>
                        </div>
                    </div>
                    <div className="third-page-div6">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">6</div>
                            </div>
                            <div className="text-1">　办公面积至少30平米以上;</div>
                        </div>
                    </div>
                    <div className="third-page-div7">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">7</div>
                            </div>
                            <div className="text-1"> 统一使用新中大的VI形象，接受并认同新中大的企业文化;</div>
                        </div>
                    </div>
                    <div className="third-page-div8">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">8</div>
                            </div>
                            <div className="text-1">能够独立完成当地所销售的新中大产品用户的售后服务与维护工作;</div>
                        </div>
                    </div>
                    <div className="third-page-div9">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">9</div>
                            </div>
                            <div className="text-1">积极参加新中大的各类培训活动和公司进行的各种营销、服务活动;</div>
                        </div>
                    </div>
                    <div className="third-page-div10">
                        <div className="icon-1">
                            <div className="pic-1">
                                <div className="pic-num">10</div>
                            </div>
                            <div className="text-1">不以任何方式经营与销售与新中大产品产生竞争的产品和服务.</div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

//第四部分
class FourthPage extends React.Component {
    render() {
        return (
            <div className="fourth-page">
                <Title text={"渠道伙伴的申请流程"} />
                <div className="interval-105" />
                <div className="fourth-page-text">1、线下申请：选择加盟类型—>填写合作申请表—>渠道管理中心审核—>签署合作协议。</div>
                <div className="interval-50" />
                <div className="fourth-page-col">
                    <div className="interval-col-145" />
                    <Logo text={"下载填写合作申请表"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/fill.png"} />
                    <div className="interval-col-50" />
                    <Logo text={"发送合作申请表到<br/>指定邮箱"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/email.png"} />
                    <div className="interval-col-50" />
                    <Logo text={"总部渠道管理中心审核"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/channel.png"} />
                    <div className="interval-col-50" />
                    <Logo text={"事业部/子公司审核"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/unit.png"} />
                    <div className="interval-col-50" />
                    <Logo text={"签署合作协议"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/Agreement.png"} />
                </div>
                <div className="interval-80" />
                <div className="fourth-page-text">2、在线申请：在线填写合作申请表—>渠道管理中心审核—>签署合作协议。</div>
                <div className="interval-50" />
                <div className="fourth-page-col">
                    <div className="interval-col-145" />
                    <Logo text={"选择加盟类型"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/type.png"} />
                    <div className="interval-col-10" />
                    <Logo text={"填写合作申请表"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/fill.png"} />
                    <div className="interval-col-10" />
                    <Logo text={"分公司审核"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/examine.png"} />
                    <div className="interval-col-10" />
                    <Logo text={"总部渠道管理中心审核"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/channel.png"} />
                    <div className="interval-col-10" />
                    <Logo text={"事业部/子公司审核"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/unit.png"} />
                    <div className="interval-col-10" />
                    <Logo text={"签署合作协议"} img={global.config.url + "/newgrand/picture/img/%E4%BC%99%E4%BC%B4/%E6%8C%87%E5%8D%97/Agreement.png"} />
                </div>
            </div>
        );
    }
}

//第五部分
class FifthPage extends React.Component {
    render() {
        return (
            <div className="fifth-page">
                <Title text={"渠道伙伴权益"} />
                <Describe text={"渠道伙伴权益包括基本分销权益、商务优惠的权益、市场支持权益、所能享受的资源和工具等,以下仅列举<br/>合作伙伴的主要权益："} />
                <div className="interval-90" />
                <div className="fifth-page-col">
                    <div className="interval-col-130" />
                    <Card img={global.config.url + "/newgrand/picture/img/%E4%BA%A7%E5%93%81/uic/erp.png"} text={"1、以《新中大代理商务条款协议》中规定的价格从新中大产品采购相关产品，根据业绩享有销售返点奖励（详见新中大合作伙伴管理规范）。"} />
                    <div className="interval-col-115" />
                    <Card img={global.config.url + "/newgrand/picture/img/%E4%BA%A7%E5%93%81/uic/oa.png"} text={"2、在指定区域开展销售，遵守相关销售政策、流程、管理办法。"} />
                    <div className="interval-col-115" />
                    <Card img={global.config.url + "/newgrand/picture/img/%E4%BA%A7%E5%93%81/uic/uic.png"} text={"3、新中大公司允许范围内，可使用“新中大合作伙伴”或“新中大授权代理商”文字进行推广，优秀合作伙伴可授权使用新中大公司LOGO。"} />
                </div>
            </div>
        );
    }
}

//第六个模块
class SixthPage extends React.Component {
    render() {
        return (
            <div className="sixth-page">
                <Title text={"渠道伙伴结算体系"} />
                <div className="sixth-page-card">
                    <div className="sixth-page-card-img"></div>
                    <div className="sixth-page-card-textcard">
                        <div className="sixth-page-card-textcard-text">
                            为保障伙伴的利益，新中大公司按伙伴的不同类型、不同等级制定相应的产品价格折扣，给予伙伴最大的优惠。<br />
                            新中大从产品支持、技术支持到市场启动支持，以及后期培训支持等为合作伙伴提供系列的支持与跟踪服务，保障合作伙伴的利益最大化。同时，新中大从合作伙伴的实际利益出发，制定丰厚的伙伴激励政策，提供销售返利、伙伴连续成长奖励等方式，激励合作伙伴最大程度的发挥自身资源优势，实现更广阔的发展空间。
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
//网页主模块
class CooperationGuide extends React.Component {
    //----从此处开始
    constructor(props) {
        super(props);
        this.showAnimation = this.showAnimation.bind(this)
    }
    //离开解绑滚动条事件
    componentWillUnmount() {
        window.removeEventListener('scroll', this.showAnimation);
    }
    //绑定滚动条事件
    componentDidMount() {
        window.addEventListener('scroll', this.showAnimation);
        this.showAnimation();
        this.initAnimation();
    }
    //获取相关id的高度
    getHight(id, showposition, classname) {
        let height = $("#" + id).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }
    //初始化添加动效的类名
    initclass(classname, addclassname) {
        let classlist = $("." + classname);
        for (let i = 0; i < classlist.length; i++) {
            $(classlist[i]).addClass(addclassname);
        }
    }
    //监控所有指定class的目标
    getClassHight(myclass, showposition, classname) {
        let classlist = $("." + myclass);
        for (let i = 0; i < classlist.length; i++) {
            let height = $(classlist[i]).offset().top - $(window).scrollTop() - $(window).height();
            if (height < showposition) {
                $(classlist[i]).addClass(classname);
            }
        }
    }
    //触发本组件的动画
    getOtherHight(otherid, id, showposition, classname) {
        let height = $("#" + otherid).offset().top - $(window).scrollTop() - $(window).height();
        if (height < showposition) {
            $("#" + id).addClass(classname);
        }
    }

    initAnimation() {
        this.initclass("title", "opacity-initial");
        this.initclass("second-page-img", "moveup-initial");
        this.initclass("second-page-describe-left", "moveup-initial");
        this.initclass("second-page-describe-right", "moveup-initial");
        /*申请条件动画 */
        this.initclass("third-page-div1", "moveup100-init");
        this.initclass("third-page-div2", "moveup100-init");
        this.initclass("third-page-div3", "moveup100-init");
        this.initclass("third-page-div4", "moveup100-init");
        this.initclass("third-page-div5", "moveup100-init");
        this.initclass("third-page-div6", "moveup100-init");
        this.initclass("third-page-div7", "moveup100-init");
        this.initclass("third-page-div8", "moveup100-init");
        this.initclass("third-page-div9", "moveup100-init");
        this.initclass("third-page-div10", "moveup100-init");

        this.initclass("logo", "moveup20-initial");
        this.initclass("card", "moveup100-init");

        this.initclass("sixth-page-card-img", "moveright100-center-init");
        this.initclass("sixth-page-card-textcard", "moveleft100-center-init");
    }
    //在这里绑定滚动播放动画事件 （具体绑定每个id）
    showAnimation() {
        this.getClassHight("title", 0, "opacity-change");
        this.getClassHight("second-page-img", 200, "moveup");
        this.getClassHight("second-page-describe-left", 200, "moveup");
        this.getClassHight("second-page-describe-right", 200, "moveup");

        this.getClassHight("third-page-div1", 100, "moveup100");
        this.getClassHight("third-page-div2", 100, "moveup100");
        this.getClassHight("third-page-div3", 100, "moveup100");
        this.getClassHight("third-page-div4", 100, "moveup100");
        this.getClassHight("third-page-div5", 100, "moveup100");
        this.getClassHight("third-page-div6", 100, "moveup100");
        this.getClassHight("third-page-div7", 100, "moveup100");
        this.getClassHight("third-page-div8", 100, "moveup100");
        this.getClassHight("third-page-div9", 100, "moveup100");
        this.getClassHight("third-page-div10", 100, "moveup100");

        this.getClassHight("logo", 20, "moveup20");
        this.getClassHight("card", 100, "moveup100");

        this.getClassHight("sixth-page-card-img", 100, "moveright100-center");
        this.getClassHight("sixth-page-card-textcard", 100, "moveleft100-center");
    }
    render() {
        return (
            <div>
                <Head />
                <div className="cooperation-guide">
                    <FirstPage />
                    <SecondPage />
                    <ThirdPage />
                    <FourthPage />
                    <FifthPage />
                    <SixthPage />
                </div>
                <Bottom />
            </div>
        );
    }
}

export default CooperationGuide;