/*
 * @Author: your name
 * @Date: 2019-12-11 17:10:01
 * @LastEditTime : 2020-01-09 13:53:04
 * @LastEditors  : Please set LastEditors
 * @Description: 首次用于安全教育页面
 * @FilePath: \my-demo\src\pages\digitalSite\components\CardInEducation.js
 */
import React from 'react';
//import './index.css';
import './components.less'



/**
 * @description 介绍卡片组件，规格为365*308 白底  首行为一张 居中 的图片 下方有一段介绍文字
 *              需提供参数: 图片url <>, 介绍文本<text> 
 * @author chenyutan
 * @date 2019-12-11
 * @class Card
 */
class Card extends React.Component {
    render() {
        return (
            <div className="digital-site">
                <div className="card-in-education">
                    <div className="interval-30" />
                    <div className="card-in-education-img">
                        <img alt="" src={this.props.img}></img>
                    </div>
                    <div className="card-in-education-text">{this.props.text}</div>
                </div>
            </div>

        );
    }
}

export default Card;