import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/sup/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E4%BA%A7%E5%93%81/up/banner-3.png`,
    describe: "新中大柔性统一平台SUP，提供了完整的Service管理功能——单入口的Web Service部署和调用机制。在平台中部署了大量跨产品、跨模块的公共服务，可变流程节点服务，实现了系统在应用层面的有效集成，而不仅仅是数据层面的集成，并且可以任意集成组合。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "柔性化统一平台SUP",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 3,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}sup.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: 'B/S构架网页登录'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: 'IMP互动管理平台，单据交互随心所欲'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '多集团，多业态，多模式'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: 'WEB化业务建模、业务个性扩展'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '全新工作流引擎'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '可构建企业即时通讯环境'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}sup.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '业务表单自定义',
        describe: '针对系统自身提供的功能，在交付给客户后，用户可以进行按需自定义，并且不需要通过实施、产品、研发的一套开发流程。用户可对软件进行二次的改造和自定义，包括但不限于软件界面按钮，界面判断逻辑，显示，隐藏等功能的实现。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '互动管理平台',
        describe: '互动管理平台（IMP），是一个将业务单据通过一定的数据传输规则和回填机制使各不相同的业务单据建立起可配置的交互关联关系的业务配置平台。平台能够企业管理中解决关联性业务的灵活配置和自动勾稽关系，使业务管理系统能够更好地适应每个企业特有的管理方式和业务流程模型。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: 'JS语言二开',
        describe: '系统提供了语言级别的二开能力，具有研发实力的用户可以通过对系统的固有或者自定义出来的表单进行二次的开发。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '自定义表单',
        describe: '系统交付客户后，用户可以在系统上搭建出系统自身并不包含的模块和功能。 '
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '全新工作流引擎',
        describe: '新中大BPM流程引擎实现了流程的自由设计及其流转功能。流程办理全面支持移动化，随时随地完成任务的审批。流程设计支持各种网关节点、流程变量，实现流程的全自动流转和人员指派。系统支持父子流程嵌套应用，实现了集团与分子公司流程的单独设计，集成使用。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '多集团，多业态，多模式',
        describe: '实现多集团模式下的基础数据的共享、分发、上下级可见，平级转移以及可见范围的约束。实现软件业务的多业态、多模式、多集团管理。软件功能实现多单元下的多界面开发。达到数据的上通下达，汇总报表集中分析。'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 1 },
        title: '自定义报表',
        describe: '新中大报表仓库系统主要有自定义报表的设计、注册、授权、应用导航等功能，实现了自定义报表的统一集成管理，还可以设计填报模板，下发填报报表，填报完成汇总生成汇总报表。'
      }
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history:'/caseDetails/1145'
      },
      // {
      //   logoSrc: `${baseUrl}b.png`,
      //   history:'/caseDetails/1134'
      // },
      {
        logoSrc: `${baseUrl}c.png`,
        history:'/caseDetails/1098'
      }
    ]
  }
}