import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/GE/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E4%BA%A7%E5%93%81/ge/banner.png`,
    describe: "新中大金色快车GE（Gold Express）企业管理软件是为中小型生产制造企业量身打造的企业管理软件。产品针对以项目管理为主线的订单加工、配套生产、商贸服务等类型成长型企业的管理需求而研发，集财务、供应链、生产计划、物料计划、客户关系、协同办公等功能于一体，实现企业物流、资金流、信息流的有效协同管控。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "GE企业管理软件",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    colCount: 5,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}GE.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '全新的框架,组织结构'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '智能的远程接入系统'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '自定义导航界面'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '财务功能精细操作更简单'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '易用性更强，界面更直观'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '报表系统更实用，更强大'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        describe: '集成了通讯功能（Net call）'
      },
      {
        key: 7,
        iconPosition: { x: 7, y: 0 },
        describe: '审批流功能更强大'
      },
      {
        key: 8,
        iconPosition: { x: 8, y: 0 },
        describe: '消息的机制更简捷'
      },
      {
        key: 9,
        iconPosition: { x: 9, y: 0 },
        describe: '软件界面集成万能查询'
      },
      {
        key: 10,
        iconPosition: { x: 0, y: 1 },
        describe: '关键数据更直观'
      },
      {
        key: 11,
        iconPosition: { x: 1, y: 1 },
        describe: '常用功能订制'
      },
      {
        key: 12,
        iconPosition: { x: 2, y: 1 },
        describe: '简洁的生产系统'
      },
      {
        key: 13,
        iconPosition: { x: 3, y: 1 },
        describe: '支持全程项目管理'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    colCount: 2,
    rowCount: 3,
    default: {
      backgroundImage: `url(${baseUrl}GE.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 4, y: 1 },
        title: '供应链管理',
        describe: '采购管理、供应商管理、采购入库通知单管理、退货单管理、业务付款处理；销售管理、客户管理、销售发货单管理、销售退货管理、业务收款处理；库存管理、出入库管理、仓库调拨管理、仓库盘点管理、库存调价。'
      },
      {
        key: 1,
        iconPosition: { x: 5, y: 1 },
        title: '财务管理',
        describe: '总账管理、出纳管理、应收应付管理、合同管理、固定资产管理、灵动项目成本。'
      },
      {
        key: 2,
        iconPosition: { x: 6, y: 1 },
        title: '生产管理',
        describe: '生产计划、项目物料需求计划、材料需求编制、参数化产品配置、制造定单管理、工单管理、加工单管理、外协加工单、受托加工单、生产统计、完工报告、生产过程追踪。'
      },
      {
        key: 3,
        iconPosition: { x: 7, y: 1 },
        title: '人事管理',
        describe: '薪资管理、劳动合同管理。 '
      },
      {
        key: 4,
        iconPosition: { x: 8, y: 1 },
        title: '客户管理',
        describe: '商机管理、客户信息管理。'
      },
      {
        key: 5,
        iconPosition: { x: 9, y: 1 },
        title: '办公管理',
        describe: '文档库管理、文档管理、文档检索、文档报表、工作流管理、审批管理。'
      }
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
      },
      {
        logoSrc: `${baseUrl}b.png`,
      },
      {
        logoSrc: `${baseUrl}c.png`,
      }
    ]
  }
}