import "../../../global.js";
import React from "react";
import "./BannerFooter.less";
import Axios from 'axios';

let Item = function (props) {
  return <div className="ad-item" style={{
    backgroundImage: "url(" + props.src + ")",
    backgroundSize: '100% 100%',
  }}
    onClick={props.click}
  >
    {/* <img src={props.src} alt="请升级浏览器" /> */}
    {
      props.type === 'point' && <div className="tip point">热点关注</div>
    }
    {
      props.type === 'live' && <div className="tip live">网络直播</div>
    }
    <div className="msg">{props.msg}</div>
  </div>
}

class BannerFooter extends React.Component {
  constructor(props) {
    super();
    this.state = {
      ...props,
      items: [
      ]
    };
  }

  componentWillMount() {
    const self = this;
    Axios.get('http://gateway.ns820.com/rss/rss/third/aggregation/hot.do', {

    })
      .then((res) => {
        self.setState({
          ...self.state,
          items: res.data.data.map((el, i) => {
            return {
              // src: localImg[i],
              src: el.image,
              type: el.hotType === 0 ? 'point' : 'live',
              msg: el.title,
              click() {
                window.location.href = el.link
              }
            }
          })
        })
      })
      .catch((error) => {
        console.log("68", error);
      });
  }

  render() {
    return <div className="ad-container">
      {
        this.state.items.map((el, index) => <Item {...el} key={index} />)
      }
    </div>;
  }
}

export default BannerFooter;