import React from "react";
import './index.less';
import { useEffect, useRef, useState } from 'react';
import Animation from '@/transition/animation/animation';

export default function (props) {
  const ref = useRef(null);
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    let animationFinash = false;
    let animationFn = () => {
      if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
        setStartAnimation(true);
        animationFinash = true;
      };
    }
    animationFn();
    window.$(window).scroll(animationFn);
    return () => {
      window.$(window).unbind('scroll', animationFn);
    }
  },[]);

  return (
    <div className='real-case' ref={ref} style={{ backgroundImage: props.backgroundImage }}>
      <div className="text-container">
        <div className="center-inner">
          <div className={`title moveup20-initial opacity-initial ${startAnimation ? `moveup20` : ``}`}>{props.title}</div>
          <div className={`describe moveup20-initial opacity-initial ${startAnimation ? `moveup20` : ``}`}>{props.describe}</div>
        </div>
      </div>
    </div>
  )
}