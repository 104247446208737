import "../../../global.js";

const baseUrl = `${global.config.url}/newgrand/picture/img/new-product/a3/`;

export default {
  // 简介
  sessionA: {
    imgSrc: `${baseUrl}banner.png`,
    describe: "A3企业管理软件是结合互联网时代企业的管理特性而开发，并吸收了数以万计国内外的业务财务一体化ERP成功应用经验，打造的企业ERP管理与电子商务综合应用系统，旨在实现规模化快速成长的单体制造业的物流、计划流、资金流、信息流的协同管控。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "A3企业管理软件",
    imgStyle: {
      maxHeight: '100%',
    }
  },

  // 产品特点
  sessionC: {
    rowCount: 2,
    colCount: 5,
    default: {
      backgroundImage: `url(${baseUrl}a3.png)`,
    },
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: '先进的生产制造管理'
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '实时生产计划模拟分析'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '敏捷询单'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '支持全程项目管理'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '适用的成本预测及实时动态成本管理'
      },
      {
        key: 5,
        iconPosition: { x: 5, y: 0 },
        describe: '提供报帐中心、内部银行和财务公司多种集团资金监控模式'
      },
      {
        key: 6,
        iconPosition: { x: 6, y: 0 },
        describe: '支持动态的订单追踪管理'
      },
      {
        key: 7,
        iconPosition: { x: 7, y: 0 },
        describe: '支持齐套缺件穿透式分析'
      },
      {
        key: 8,
        iconPosition: { x: 8, y: 0 },
        describe: '主动预防性设备维护'
      },
      {
        key: 9,
        iconPosition: { x: 9, y: 0 },
        describe: '磅单管理'
      }
    ]
  },

  // 产品模块图
  sessionD: {
    imgSrc: `${baseUrl}mokuai.png`,
  },

  // 模块介绍
  sessionE: {
    default: {
      backgroundImage: `url(${baseUrl}a3.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 0, y: 1 },
        title: '供应链管理',
        describe: '1、库存管理：库存管理提供库存数量资金的变化情况，帮助企业仓库管理人员对库存物品的入库、出库、移动、调拨、盘点、库存资金核算等业务进行控制和管理。　2、采购管理：采购管理模块主要完成企业采购部门及采购人员完成采购业务的处理及管理。　　3、销售管理：销售管理模块为企业销售管理提供了管理工具，它可以完成供应商报价、客户信用额度管理、客户单位结算系数管理、部门结算系数管理等一系列销售业务管理工作。'
      },
      {
        key: 2,
        iconPosition: { x: 1, y: 1 },
        title: '财务管理',
        describe: '1、账务中心：账务中心可实现凭证录入、修改、加工、审核，自动转账凭证的生成、月末结账和反结账、账簿的设计和查询、预算的设置、审批、账务凭证的转入和转出等功能。2、报表中心：提供了报表管理、报表制作、报表函数定义、自动计算、表间取数、以通用数据库格式转换输出、报表传出与汇入（适用上下级单位）、同类报表汇总计算、报表数据的审计追踪等功能以及企业合并会计报表的编制等功能。3、结算中心：主要功能包括资金申请、费用支付、一般收款、供应商结算、客户结算、银行转账、资金拆借、坏账收回、银行对账、票据管理、贷款管理及报表查询。'
      },
      {
        key: 3,
        iconPosition: { x: 2, y: 1 },
        title: '资产管理',
        describe: '1、资产平台实现企业资产统一采购、出入库、查询、盘点及暂估管理等一系列功能。2、设备管理的核心功能就是设备维护管理，实现与财务、采购、库存的备品备件的管理集成，以及维修人员管理与人力资源管理的集成。'
      },
      {
        key: 4,
        iconPosition: { x: 3, y: 1 },
        title: '合同管理',
        describe: '1、采购合同：采购合同、采购协议和服务采购等多种采购合同分类，合同变更管理与追溯，合同进度、结算等报表查询功能。2、销售合同：销售合同、直销协议、经销协议和服务销售等多种合同分类，合同变更管理与追溯，合同进度、结算等报表查询功能。 '
      },
      {
        key: 5,
        iconPosition: { x: 4, y: 1 },
        title: '生产管理',
        describe: '生产管理包括主生产计划、物料需求计划、物料需求计划、车间作业控制、参数化产品配置、重复式生产管理、敏捷询单、协同生产等功能。'
      },
      {
        key: 6,
        iconPosition: { x: 5, y: 1 },
        title: '质量管理',
        describe: '通过质量管理模块，实现质量管理和控制、质量追踪和追溯、计量器具管理和检验、质量综合统计分析等功能。　　'
      },
      {
        key: 7,
        iconPosition: { x: 6, y: 1 },
        title: '成本管理',
        describe: '标准制造成本：在维护成本结构的同时，可维护标准成本资料，系统在对实际成本计算后与标准成本进行对比，得到相应的价差和量差。产品成本核算：包括财务管理、固定资产管理、库存管理等模块，可以方便地运用传统的分步法、品种法、分批法在月末快速计算出各种产品成本和中间产品成本，出具分析报表。动态成本管理：按当前市场的材料实际价格与当前的工费成本核定的，随着市场环境的变化而动态变化的。'
      }
    ]
  },

  sessionF: {
    items: [
      {
        logoSrc: `${baseUrl}a.png`,
        history:'/caseDetails/1143'
      },
      {
        logoSrc: `${baseUrl}b.png`,
        history:'/caseDetails/1142'
      },
      {
        logoSrc: `${baseUrl}c.png`,
        history:'/caseDetails/1134'
      }
    ]
  }
}