/**
 * 全名： 资金管理页面
 * 功能描述：资金管理
 * 创建日期：--
 * 作者：hzw
 */

import React from "react";
import Head from "../../../common-components/TopBar/TopBar";
import Bottom from "../../../common-components/BottomBar";
import Describe from "../privateComponents/FinanceDesc";
import PointPanel from "../privateComponents/FinancePointPanel";
import ContactBottom from '../../../new-components/ContactBottom';
import ArchitectureImage from '../privateComponents/ArchitectureImage';
import ValuePanel from '../privateComponents/FinanceValuePanel';
import RenderWithTitle from "@/high-orders/RenderWithTitle";

import config from "./config"
import './index.less';


const DescribeWithTitle= RenderWithTitle(Describe, '财务核算', {
  background: 'linear-gradient(360deg,rgba(250,250,250,0) 0%,rgba(243,250,254,1) 100%)'
});


export default class extends React.Component {
  render() {
    return (
      <div className="financial-accounting">
        <Head />
        <DescribeWithTitle {...config.sessionA} />
        <PointPanel {...config.sessionB} />
        <ArchitectureImage  {...config.sessionC} />
        <ValuePanel {...config.sessionD} />
        <ContactBottom />
        <Bottom />
      </div>
    )
  }
}