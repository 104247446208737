import '../../../global';

const baseUrl = `${global.config.url}/newgrand/picture/img/new-cases/9/`;
export default {
  sessionA: {
    imgSrc: `${global.config.url}/newgrand/picture/img/%E9%A1%B9%E7%9B%AE%E5%BB%BA%E9%80%A0/%E4%B8%93%E4%B8%9A%E6%89%BF%E5%8C%85%E4%BC%81%E4%B8%9A%E4%BF%A1%E6%81%AF%E5%8C%96/banner.png`,
    describe: "新中大专业承包企业管理解决方案服务于装饰、弱电、消防、机电设备、通信、数字视频、安防监控等细分行业的专项承包工程企业，系统以合同管理为主线，物资管理为重点，通过投标管理、合同管理、进度管理、物资管理、成本管理、质量管理、安全管理、风险管理、竣工管理、资金管理等模块应用实现对专业承包项目的进度、设备、成本费用、收入、分包/采购合同、资金收支和质量等各个方面的管理，建设贯穿项目管理全过程的一体化管理平台。",
    background: "linear-gradient(360deg, #fafafa 0%, #f3fafe 100%)",
    title: "专业承包企业管理",
    hiddenBtn: true,
    imgStyle: {
      maxHeight: '100%',
    }
  },
  sessionB: {
    rowCount: 2,
    colCount: 3,
    items: [
      {
        key: 0,
        iconPosition: { x: 0, y: 0 },
        describe: `资源集约化管理，由“繁”至“简`
      },
      {
        key: 1,
        iconPosition: { x: 1, y: 0 },
        describe: '从项目管理延伸到设计管理'
      },
      {
        key: 2,
        iconPosition: { x: 2, y: 0 },
        describe: '以进度为主线的业务驱动'
      },
      {
        key: 3,
        iconPosition: { x: 3, y: 0 },
        describe: '多级成本管控，有效的事前事中事后管理'
      },
      {
        key: 4,
        iconPosition: { x: 4, y: 0 },
        describe: '协同作业、信息共享'
      }
    ],
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    }
  },

  sessionC: {
    imgSrc: `${baseUrl}jiagou.png`
  },
  // 方案价值
  sessionD: {
    colCount: 2,
    rowCount: 2,
    default: {
      backgroundImage: `url(${baseUrl}icon.png)`,
    },
    items: [
      {
        key: 1,
        iconPosition: { x: 5, y: 0 },
        title: '提升企业标准化管理能力',
        describe: '标准化流程、标准化分工、标准化表格、标准化管控等，促进企业执行管理标准化。'
      },
      {
        key: 2,
        iconPosition: { x: 6, y: 0 },
        title: '构建企业多项目管控平台',
        describe: '利用信息系统的信息计算、共享等能力，构建企业多项目管控平台，实时了解企业各项目信息，加强对项目全过程的监控和跟踪能力，为决策提供准确实时的数据。'
      },
      {
        key: 3,
        iconPosition: { x: 7, y: 0 },
        title: '知识库积累，规范项目文档管理',
        describe: '利用信息系统的积累能力，积累专业知识、工作技能、管理知识、业务数据等，实现知识的积累和转移，规范项目的文档管理。'
      },
      {
        key: 4,
        iconPosition: { x: 8, y: 0 },
        title: '企业内外信息共享、高效协同',
        describe: '利用信息系统共享与协同的能力，达到与项目各参建方、企业各职能部门间的信息共享，提高沟通能力，降低由于沟通导致的项目管理风险。'
      }
    ]
  },
  sessionE: {
    title: '苏州苏明装饰股份有限公司',
    describe:`苏州苏明装饰股份有限公司始建于1992年，注册资本1.2亿元。苏明装饰具有建筑装修装饰、消防设施工程、建筑幕墙工程施工壹级资质；建筑装饰、建筑幕墙工程设计甲级资质以及其他相关配套资质。苏明装饰在上海、天津、沈阳、乌鲁木齐、青岛、临沂、西安、海口、无锡、贵阳、洛阳、成都等多个大中城市开设分支机构，业务遍布全国。为拓展上下游产业链，公司先后建成幕墙构配件、木饰面、家具、石材、不锈钢加工基地。`, 
    backgroundImage: `url(${baseUrl}anli.png)`
  }
}