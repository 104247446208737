import './index.less';
import React from 'react';
import Animation from '../../transition/animation/animation';
import { useRef } from 'react';
import { useEffect, useState } from 'react';

// export default (contentComponent, title, style) => {
//   const Content = contentComponent;

//   class ContentWithTitle extends React.Component {
//     constructor(props) {
//       super(props);
//       this.ref = React.createRef();
//       this.animationFn = this.animationFn.bind(this);

//       this.state = {
//         animationFinash: false
//       }
//     }

//     animationFn() {
//       if (!this.state.animationFinash && Animation.reachTriggerHeight(this.ref.current, -20)) {
//         this.setState({ ...this.state, animationFinash: true })
//       };
//     }

//     componentDidMount() {
//       this.animationFn();
//       window.$(window).scroll(this.animationFn);
//     }

//     render() {
//       const { forwardedRef, ...rest } = this.props;

//       return (
//         <div className="title-container" style={{ ...style }} ref={this.ref}>
//           <div className={this.state.animationFinash ? "title moveup20-initial opacity-initial moveup20" : "title moveup20-initial opacity-initial"}>
//             <span>{title}</span>
//           </div>
//           <Content {...rest} ref={forwardedRef} />
//         </div>
//       )
//     }
//   }

//   return React.forwardRef((props, ref) => {
//     return <ContentWithTitle {...props} forwardedRef={ref} />;
//   });
// }

export default (contentComponent, title, style) => {
  const Content = contentComponent;

  function ContentWithTitle(props) {

    const ref = useRef(null);
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
      let animationFinash = false;
      function animationFn() {
        if (!animationFinash && Animation.reachTriggerHeight(ref.current, 80)) {
          animationFinash = true;
          setStartAnimation(true);
        };

      }
      animationFn();
      window.$(window).scroll(animationFn);
    },[])

    const { forwardedRef, ...rest } = props;

    return (
      <div className="title-container" style={{ ...style }} ref={ref}>
        <div className={startAnimation ? "title moveup20-initial opacity-initial moveup20" : "title moveup20-initial opacity-initial"}>
          <span>{title}</span>
        </div>
        <Content {...rest} ref={forwardedRef} />
      </div>
    )
  }

  return React.forwardRef((props, ref) => {
    return <ContentWithTitle {...props} forwardedRef={ref} />;
  });
}