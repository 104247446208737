/*
 * @Author: your name
 * @Date: 2019-12-18 14:46:18
 * @LastEditTime : 2020-01-06 14:41:25
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\common-components\card-b\CardB.js
 */
import React from 'react';
//import './index.css';
import './card-A.less'
import '../../transition/size/size.less'
import '../../transition/change-color/color.less'

class CardA extends React.Component {
    render() {
        return (
            <div className="common-a">
                <div className="card-a height-390" style={this.props.img}>
                    <div className="card-a-back" ></div>
                    <div className="card-a-front yellow">
                        <div className="card-a-front-box">
                            <div className="interval-89"></div>
                            <div className="card-a-title">{this.props.title}</div>
                            <div className="card-a-text">{this.props.text}</div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}

export default CardA;