/*
 * @Author: your name
 * @Date: 2019-12-26 17:10:54
 * @LastEditTime: 2022-01-12 17:14:56
 * @LastEditors: qyy
 * @Description: In User Settings Edit
 * @FilePath: \my-demo\src\pages\ungrouped\news\New.js
 */
import "../../../global.js";
import React from "react";
//import './index.css';
import "./news.less";
import "../../../global.js";

//引入prefeb模块
import Bottom from "../../../common-components/BottomBar";
import Head from "../../../common-components/TopBar/TopBar";
import Card from "../../join-us/components/card/Card";
import Axios from "axios";


//网页主模块
class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: { code: 0, msg: "操作成功", count: 1, data: [] },
      arr: [],
      id: ""
    };
  }

  findByType(news, keytype) {
    if (typeof keytype !== "undefined") {
      news.data = news.data.filter(el => {
        return el.NewsTypeDesc === keytype;
      })
    }
    return news;
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.size !== nextProps.match.params.size) {
      if (typeof this.props.match != "undefined") {
        var myid = nextProps.match.params.size;
      }
      const me = this;
      this.setState({
        id: myid,
        news: { code: 0, msg: "操作成功", count: 1, data: [] },
      }, () => {
        Axios.get(global.config.serverUrl + "/NewsInfo/LoadData/?page=1&limit=50")
          .then(news => {
            news.data = me.findByType(news.data, myid);
            this.setState({ news: news.data });
          })
          .catch(error => {
            console.log(error);
          });
      });
    }
  }

  componentDidMount() {
    if (typeof this.props.match != "undefined") {
      var myid = this.props.match.params.size;
    }
    console.log(myid)
    this.setState({
      id: myid
    });

    Axios.get(global.config.serverUrl + "/NewsInfo/LoadData/?page=1&limit=50")
      .then(news => {
        news.data = this.findByType(news.data, myid);
        this.setState({ news: news.data });
      })
      .catch(error => {
        console.log(error);
      });

    //3s后追加所有内容
    setTimeout(() => {
      Axios.get(global.config.serverUrl + "/NewsInfo/LoadData/?page=1&limit=500")
        .then(news => {
          news.data = this.findByType(news.data, myid);
          this.setState({ news: news.data });
        })
        .catch(error => {
          console.log(error);
        });
    }, 3000);
  }

  //跳转传参
  handleClick(address, myid) {
    this.props.history.push(address + "/" + myid);
  }
  render() {
    var newsListLeft = [];
    var newsListRight = [];
    let newsNum = 0;
    for (let i = 0; i < this.state.news.data.length; i++) {
      if (this.state.news.data[i].IsUseful === 1) {
        if (newsNum % 2 === 0) {
          newsListLeft.push(
            <Card
              key={i + "card"}
              img={
                global.config.serverUrl +
                this.state.news.data[i].NewsContentFilePath
              }
              title={this.state.news.data[i].NewsName}
              text={
                this.state.news.data[i].NewsContent === null
                  ? this.state.news.data[i].NewsContent
                  : this.state.news.data[i].NewsContent.substring(0, 100)
              }
              time={this.state.news.data[i].NewsTypeDesc}
              click={this.handleClick.bind(
                this,
                "/newsDetails",
                this.state.news.data[i].Id
              )}
            />
          );
          newsListLeft.push(<div key={i} className="interval-70" />);
          newsNum = newsNum + 1;
        } else {
          newsListRight.push(
            <Card
              key={i + "card"}
              img={
                global.config.serverUrl +
                this.state.news.data[i].NewsContentFilePath
              }
              title={this.state.news.data[i].NewsName}
              text={
                this.state.news.data[i].NewsContent === null
                  ? this.state.news.data[i].NewsContent
                  : this.state.news.data[i].NewsContent.substring(0, 100)
              }
              time={this.state.news.data[i].NewsTypeDesc}
              click={this.handleClick.bind(
                this,
                "/newsDetails",
                this.state.news.data[i].Id
              )}
            />
          );
          newsListRight.push(<div key={i} className="interval-70" />);
          newsNum = newsNum + 1;
        }
      }
    }

    return (
      <div>
        <Head />
        <h1 style={{ textIndent: '10.13888889vw', fontSize: '2.5vw' }}>新闻动态</h1>
        <div className="news">
          <div className="first-page">
            <div className="first-page-body">
              <div className="interval-col-146"></div>
              <div className="left">{newsListLeft}</div>
              <div className="interval-col-70"></div>
              <div className="right">{newsListRight}</div>
            </div>
            <div className="interval-353" />
          </div>
        </div>
        <Bottom />
      </div>
    );
  }
}

export default News;
